
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatInputModule, MatIconModule, MatCardModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatDialogModule, MatProgressSpinnerModule, MatButtonToggleModule, MatTooltipModule, MatProgressBarModule, MatAutocompleteModule, MatTableModule, MatSortModule, MatTabsModule, MatToolbarModule, MatPaginatorModule, MatListModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMaskModule } from '@fagnerlima/ng-mask';
import { ListagemLancamentoComponent } from './listagem-lancamento.component';
import { DetalhesLancamentoModule } from '../detalhes-lancamento/detalhes-lancamento.module';
import { ComponentsModule } from '../../components.module';




@NgModule({
  declarations: [ListagemLancamentoComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgMaskModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatPaginatorModule,
    DetalhesLancamentoModule,
    ComponentsModule,
    MatListModule

  ],
  exports: [ListagemLancamentoComponent]
})
export class ListagemLancamentoModule { }
