import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ConsultaCepService {
    constructor(http) {
        this.http = http;
    }
    consultaCEP(cep) {
        // Nova variável "cep" somente com dígitos.
        cep = cep.replace(/\D/g, '');
        // Verifica se campo cep possui valor informado.
        if (cep !== '') {
            // Expressão regular para validar o CEP.
            const validacep = /^[0-9]{8}$/;
            // Valida o formato do CEP.
            if (validacep.test(cep)) {
                const url = `https://cors-anywhere.herokuapp.com/https://viacep.com.br/ws/${cep}/json`;
                return this.http.get(url);
                // return this.http.get(`//viacep.com.br/ws/${cep}/json`);
            }
        }
        return of({});
    }
}
ConsultaCepService.ngInjectableDef = i0.defineInjectable({ factory: function ConsultaCepService_Factory() { return new ConsultaCepService(i0.inject(i1.HttpClient)); }, token: ConsultaCepService, providedIn: "root" });
