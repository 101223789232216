export class User {
    constructor(
        public id: string,
        public email: string,
        public password: string,
        public profile: string,
        public nome: string,
        public telefone: string,
        public imagem: File | string,
        public status: boolean,
        public turmaId: string
    ) { }
}
