import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-image',
  templateUrl: './input-image.component.html',
  styleUrls: ['./input-image.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputImageComponent),
    multi: true,
  }],
})
export class InputImageComponent implements OnInit, ControlValueAccessor {

  preview: string
  value: File | string;

  @Input()
  label: string;

  constructor() { }

  ngOnInit(): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  getModel() {
    return this.value instanceof File ? this.value.name : null
  }

  writeValue(value: File | string): void {
    this.value = value;
    if (this.value && this.value instanceof File) {
      this.fileToSrc(this.value)
        .then(preview => {
          this.preview = preview;
          this.value = value;
        })
        .catch(() => {
          this.preview = null
          this.value = null;
        })
    } else if (this.value && this.value != null) {
      this.preview = this.value as string
    } else {
      this.preview = null
      this.value = null;
    }
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

  onChangeFn = (_: any) => {
    console.log(_)
  };

  removerImagem() {
    this.writeValue(null)
    this.preview = null
    this.onChangeFn(null)
  }

  onChangeImage(event) {
    const files: FileList | null = event.target.files
    if (files && files.length > 0) {
      const file = files.item(0)
      if (file) {
        this.onChangeFn(file)
        this.writeValue(file)
      }
    }
  }

  openInput(element) {
    console.log(element)
    if (element) {
      element.click()
    }
  }
  fileToSrc(file: File): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const binaryData = e.target.result;
        if (binaryData) {
          resolve(binaryData);
        } else {
          reject('no data')
        }
      };
      reader.onerror = error => reject(error);
    });
  }

}
