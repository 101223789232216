import { CursoNivelService } from './../../services/curso-nivel.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { ResponseApi } from '../../model/response-api';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-curso-nivel-new',
  templateUrl: './curso-nivel-new.component.html',
  styleUrls: ['./curso-nivel-new.component.css']
})
export class CursoNivelNewComponent implements OnInit {

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  shared: SharedService;


  constructor(
    private cursoNivelService: CursoNivelService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required]
    });
    this.route.params.subscribe(params => {
      if (params['id']) {
        this.cursoNivelService.findById(params['id']).subscribe(cursoNivel => {
        if (cursoNivel && cursoNivel.data) {
          this.form.setValue(cursoNivel.data);
          }
        });
      }
    });
  }

  register() {
    if (this.form.valid) {
      this.cursoNivelService.createOrUpdate(this.form.value).subscribe(
        (responseApi: ResponseApi) => {
          this.resetaForm();
          this.abrirConfirmacao();
        },
        err => {
          console.log('Deu erro');
        }
      );
    } else {
      console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
    }
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(Confirmacao);

    setTimeout(() => {
      this.dialog.closeAll();
    }, 3000);
  }

  resetaForm() {
    this.f.resetForm();
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confimacao',
  templateUrl: './Confirmacao.html'
})
// tslint:disable-next-line:component-class-suffix
export class Confirmacao {}
