<div class="container-fluid" #print>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Relatório de Frequência por Turma</mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <form [formGroup]="form" class="form horizontal" #f="ngForm">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                    <input matInput placeholder="Data Inicial" id="data" formControlName="data" [matDatepicker]="data"
                      readonly="true" required>
                    <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #data></mat-datepicker>
                    <mat-error>Data é obrigatória</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                    <input matInput placeholder="Data Final" id="data2" formControlName="data2" [matDatepicker]="data2"
                      readonly="true" required>
                    <mat-datepicker-toggle matSuffix [for]="data2"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #data2></mat-datepicker>
                    <mat-error>Data é obrigatória</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="turma">Turma</mat-label>
                    <mat-select placeholder="Selecione a Turma" id="turma" formControlName="turma" required>
                      <mat-option *ngFor="let turma of turmas | async" [value]="turma"
                        (click)="turmaSelecionada(turma.id)">
                        {{turma?.nome}} - {{turma?.professor?.nome}}</mat-option>
                    </mat-select>
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Selecionar turma é obrigatório</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-3">
                  <button mat-raised-button color="primary" type="button" (click)="resumo()"
                    [disabled]="!form.valid">Pesquisar</button>
                  <!--button mat-raised-button color="primary" type="button" (click)="gerarPdf()"
                    [disabled]="!form.valid">Imprimir</button-->
                </div>
              </div>
            </div>
            <div class="table-responsive" class="container-fluid" id="tabela">
              <mat-tab-group>
                <!-- TAB 1 -->
                <mat-tab label="Analítico">
                  <mat-form-field class="filter">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
                  </mat-form-field>
                  <mat-table #table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                    <ng-container *ngFor="let col of displayedColumns; let colIndex = index" matColumnDef="{{col}}">
                      <mat-header-cell *matHeaderCellDef> {{col}}</mat-header-cell>
                      <mat-cell *matCellDef="let element ">{{element[col]}}</mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                  </mat-table>
               
                </mat-tab>
                <!-- TAB 2 -->
                <mat-tab label="Sintético" class="red">
                  <mat-form-field class="filter">
                    <input matInput (keyup)="applyFilter2($event.target.value)" placeholder="Filtro">
                  </mat-form-field>
                  <table mat-table [dataSource]="dataSource2" matSort class="mat-elevation-z8">

                    <ng-container matColumnDef="aluno">
                      <th mat-header-cell *matHeaderCellDef> Aluno </th>
                      <td mat-cell *matCellDef="let element"> {{element?.turma}} </td>
                    </ng-container>

                    <ng-container matColumnDef="totalp">
                      <th mat-header-cell *matHeaderCellDef> Total Presente </th>
                      <td mat-cell *matCellDef="let element"> {{element?.totalMatriculados}} </td>
                    </ng-container>

                    <ng-container matColumnDef="totala">
                      <th mat-header-cell *matHeaderCellDef> Total Ausente </th>
                      <td mat-cell *matCellDef="let element"> {{element.faltas}} </td>
                    </ng-container>

                    <ng-container matColumnDef="percentual">
                      <th mat-header-cell *matHeaderCellDef> % </th>
                      <td mat-cell *matCellDef="let element"> {{element?.total | percent:'1.2'}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="tab2"></tr>
                    <tr mat-row *matRowDef="let row; columns: tab2;"></tr>
                  </table>
                  <div *ngIf="isNothing === true">
                    <p>Nenhum registro encontrado.</p>
                  </div>
                </mat-tab>
              </mat-tab-group>


              <div class="container-fluid">
                <div class="col-lg-6 col-md-6 col-sm-6" style="margin-left: 0%">
                  <mat-card class="resumo">
                    <mat-card-title style="color: green;">Total presentes:</mat-card-title>
                    <mat-card-content class="content">{{ totalPresentes }}</mat-card-content>
                  </mat-card>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" style="margin-left: 0%">
                  <mat-card class="resumo">
                    <mat-card-title style="color: orange;">Total ausentes:</mat-card-title>
                    <mat-card-content class="content">{{ totalFaltosos }}</mat-card-content>
                  </mat-card>
                </div>
              </div>


              <div class="container-fluid">
                <div class="row">

                </div>
              </div>
              <!-- OUTRA TABELA -->
              <!--table class="table table-bordered">

                  <tr>
                    <th></th>
                    <th>Matriculados</th>
                    <th>Faltosos</th>
                    <th>Total</th>
                    <th style="width: 10px"></th>
                  </tr>
                  <tr *ngFor="let turma of resumoList">
                    <td>{{ turma.turma }}</td>
                    <td>{{ turma.totalMatriculados }}</td>
                    <td>{{ turma.faltas }}</td>
                    <td>{{ turma.total }} </td>
                    <td>
                      <mat-button-toggle value="chamada" matTooltip="Realizar chamada">
                        <mat-icon style="color: #0D47A1">query_builder</mat-icon>
                      </mat-button-toggle>
                    </td>
                  </tr>
                </table-->

              <!--div class="container-fluid" *ngIf="!isLoading">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
                    <mat-card class="resumo">
                      <mat-card-title>Total/Geral:</mat-card-title>
                      <mat-card-content class="content">{{ totalPresentes }} </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </div-->
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>