import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FrequenciaDetalhes } from '../model/frequenciaDetalhes.model';
import { environment } from '../../environments/environment';
import { Response } from '../model/respose.model';

@Injectable({
  providedIn: 'root'
})
export class FrequenciaDetalhesService {
  constructor(private http: HttpClient) { }

  createOrUpdate(detalhes: FrequenciaDetalhes) {
    if (detalhes.id != null && detalhes.id !== '') {
      console.log(detalhes.id);
      return this.http.put(`${environment.apiUrl}/api/frequenciaDetalhes`, detalhes);
    } else {
      detalhes.id = null;
      console.log(detalhes.id);
      return this.http.post(`${environment.apiUrl}/api/frequenciaDetalhes`, detalhes);
    }
  }
  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/frequenciaDetalhes/${id}`);
  }
  findByFrequencia(frequencia: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/frequenciaDetalhes/byFrequencia/${frequencia}`);
  }
}
