import { Response } from './../../model/respose.model';
import { MatriculaService } from './../../services/matricula.service';
import { FrequenciaService } from './../../services/frequencia.service';
import { OnInit, Component } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { DialogService } from '../../../dialog.service';
import { TurmaService } from '../../services/turma.service';
import { Router } from '@angular/router';
import { ResponseApi } from '../../model/response-api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseArray } from '../../model/responseArray.model';
import { Turma } from '../../model/turma.model';

@Component({
  selector: 'app-frequencia-new',
  templateUrl: './frequencia-new.component.html',
  styleUrls: ['./frequencia-new.component.css']
})
export class FrequenciaNewComponent implements OnInit {
  page = 0;
  count = 20;
  pages: Array<number>;
  shared: SharedService;
  message: {};
  classCss: {};
  listTurma = [];
  turmas: Observable<Response>;
  isLoading = false;

  constructor(
    private dialogService: DialogService,
    private turmaService: TurmaService,
    private router: Router,
    private frequenciaService: FrequenciaService,
    private matriculaService: MatriculaService
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.findAll(this.page, this.count);
  }
  findAll(page: number, count: number) {
    this.isLoading = true;
    this.turmaService.findAllTurmasAtivas(page, count).pipe(
      map(turmas =>
        turmas.filter(turma => turma.status === false)),
    ).subscribe(
      (turmasFiltradas) => {
        this.listTurma = turmasFiltradas;
        let userId = this.shared.user.id;
        if (this.shared.user.profile === 'PROFESSOR') {
          let i;
          for (i = 0; i < turmasFiltradas.length; i++) {
            if (turmasFiltradas[i].professoresId.includes(userId)) {
              this.edit(turmasFiltradas[i].id);
            }
          }
        }
        this.isLoading = false;
      },
      err => {
        this.showMessage({
          type: 'error',
          text: err['error']['errors'][0]
        });
      }
    );
  }

  edit(id: string) {
    this.router.navigate(['/frequencia-list', id]);
  }

  private showMessage(message: { type: string; text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      alert: true
    };
    this.classCss['alert-' + type] = true;
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }

}
