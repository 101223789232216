import { Curso } from './../../model/curso.model';
import { CursoNivelService } from './../../services/curso-nivel.service';
import { Observable } from 'rxjs';
import { CursoNivel } from './../../model/curso-nivel.model';
import { SharedService } from './../../services/shared.service';
import { CursoService } from './../../services/curso.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm
} from '@angular/forms';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { MatDialog } from '../../../../node_modules/@angular/material';
import { ResponseApi } from '../../model/response-api';

@Component({
  selector: 'app-curso-new',
  templateUrl: './curso-new.component.html',
  styleUrls: ['./curso-new.component.css']
})
export class CursoNewComponent implements OnInit {



  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  shared: SharedService;
  message: {};
  classCss: {};
  niveis: Observable<CursoNivel[]>;
  readonly maskTime: string = 'time';

  constructor(
    private cursoService: CursoService,
    private cursoNivelService: CursoNivelService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.niveis = this.cursoNivelService.findAllNivel();

    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      duracao: [null, Validators.required],
      nivel: [null, Validators.required]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.cursoService.findById(params['id']).subscribe(curso => {
          if (curso && curso.data) {
            console.log(this.form.setValue(curso.data));
            this.form.setValue(curso.data);
          }
        });
      }
    });
  }

  register() {
    if (this.form.valid) {
      console.log(this.form.value);
      // const curso: Curso = this.form.value;
      // this.cursoNivelService.findById(curso =>  );
      this.cursoService.createOrUpdate(this.form.value).subscribe(
        (responseApi: ResponseApi) => {
          this.resetaForm();
          this.abrirConfirmacao();
        },
        err => {
          console.log('Deu erro');
        }
      );
    } else {
      console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
    }
  }

  findAll(page: number, count: number) {
    this.cursoNivelService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.niveis = responseApi['data']['content'];
        // this.pages = new Array(responseApi['data']['totalPages']);
      },
      err => {
        this.showMessage({
          type: 'error',
          text: err['error']['errors'][0]
        });
      }
    );
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(CursoNewDialog);

    setTimeout(() => {
      this.dialog.closeAll();
    }, 3000);

    /*dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });*/
  }

  private showMessage(message: { type: string; text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      alert: true
    };
    this.classCss['alert-' + type] = true;
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'curso-new-dialog',
  templateUrl: './curso-new-dialog.html'
})
// tslint:disable-next-line:component-class-suffix
export class CursoNewDialog {}
