import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CursoNivelService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(cursoNivel) {
        if (cursoNivel.id != null && cursoNivel.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/cursoNivel`, cursoNivel);
        }
        else {
            cursoNivel.id = null;
            return this.http.post(`${environment.apiUrl}/api/cursoNivel`, cursoNivel);
        }
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/cursoNivel/${page}/${count}`);
    }
    findAllNivel() {
        return this.http.get(`${environment.apiUrl}/api/cursoNivel/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/cursoNivel/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/cursoNivel/${id}`);
    }
    findByNome(n) {
        n.nome = n.nome === '' ? 'uninformed' : n.nome;
        return this.http.get(`${environment.apiUrl}/api/cursoNivel/${n.nome}/`);
    }
}
CursoNivelService.ngInjectableDef = i0.defineInjectable({ factory: function CursoNivelService_Factory() { return new CursoNivelService(i0.inject(i1.HttpClient)); }, token: CursoNivelService, providedIn: "root" });
