import { Response } from './../model/respose.model';
import { Curso } from './../model/curso.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CursoService {

  constructor(private http: HttpClient) { }

  createOrUpdate(curso: Curso) {
    if (curso.id != null && curso.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/curso`, curso);
    } else {
      curso.id = null;
      return this.http.post(`${environment.apiUrl}/api/curso`, curso);
    }
  }
  findAll(page: number, count: number) {
    return this.http.get(`${environment.apiUrl}/api/curso/${page}/${count}`);
  }

  findAllCurso() {
    return this.http.get<Curso[]>(`${environment.apiUrl}/api/curso/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/curso/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/curso/${id}`);
  }

}
