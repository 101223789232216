<h1 mat-dialog-title>Pesquisando... </h1>
<div mat-dialog-content>
  <p>Por favor aguarde.</p>

</div>
<div >
  <mat-spinner color="warn"></mat-spinner>
</div>




