<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%" id="print-section">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Resumo de Frequências</mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
              <form [formGroup]="form" class="form horizontal" #f="ngForm">
                <div class="row">
                  <div class="form-group">
                    <div class="col-sm-3">
                      <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                        <input matInput placeholder="Data da chamada" id="data" formControlName="data"
                          [matDatepicker]="data" readonly="true" required>
                        <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #data></mat-datepicker>
                        <mat-error>Data é obrigatória</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="col-sm-3">
                      <button mat-raised-button color="primary" type="button" (click)="resumo()">Pesquisar</button>
                    </div>

                  </div>
                </div>
                <div class="table-responsive" class="container-fluid">
                  <mat-form-field class="filter">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
                  </mat-form-field>
                  <mat-toolbar>
                    <span>Escola Bíblica Domincal  {{form.get('data').value | date: 'dd/MM' }}</span>
                  </mat-toolbar>
                  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                    <ng-container matColumnDef="turma">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header> Turma </th>
                      <td mat-cell *matCellDef="let element"> {{element.turma}} <mat-icon *ngIf="element.faltas === 0"
                          matTooltip="Em aberto" style="color:#FFEB3B">star</mat-icon>
                      </td>
                      <td mat-footer-cell *matFooterCellDef>Total</td>
                    </ng-container>

                    <ng-container matColumnDef="matriculados">
                      <th mat-header-cell *matHeaderCellDef> Total Matriculados </th>
                      <td mat-cell *matCellDef="let element"> {{element.totalMatriculados}} </td>

                      <td mat-footer-cell *matFooterCellDef> {{ getTotalMatriculados() }}</td>
                    </ng-container>

                    <ng-container matColumnDef="faltas">
                      <th mat-header-cell *matHeaderCellDef> Faltosos </th>
                      <td mat-cell *matCellDef="let element"> {{element.faltas}}
                      </td>

                      <td mat-footer-cell *matFooterCellDef> {{ getTotalFaltas() }}</td>
                    </ng-container>

                    <ng-container matColumnDef="visitantes">
                      <th mat-header-cell *matHeaderCellDef> Visitantes </th>
                      <td mat-cell *matCellDef="let element"> {{element.visitantes}} </td>

                      <td mat-footer-cell *matFooterCellDef> {{ getTotalVisitantes() }}</td>
                    </ng-container>

                    <ng-container matColumnDef="total">
                      <th mat-header-cell *matHeaderCellDef> Total </th>
                      <td mat-cell *matCellDef="let element"> {{element.total}}</td>
                      <td mat-footer-cell *matFooterCellDef> {{ getTotalCost() }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                  </table>
                  <div *ngIf="isNothing === true">
                    <p>Nenhum registro encontrado.</p>
                  </div>
                </div>

              </form>
            </div>
            <div class="table-responsive" class="container-fluid">
              <mat-toolbar>
                  <span>Aniversariantes da semana</span>
              </mat-toolbar>
              <section class="table-container" class="mat-elevation-z8" tabindex="0">
              <table mat-table [dataSource]="dataSource3" matSort>
                <caption>Titulo da table</caption>
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> 📷 </th>
                  <td mat-cell *matCellDef="let element"> <img [src]="element.imagem" /> </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Nome </th>
                  <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
                </ng-container>

                <ng-container matColumnDef="birthdate">
                  <th mat-header-cell *matHeaderCellDef> Data de Aniversário </th>
                  <td mat-cell *matCellDef="let element"> {{element.data | date: 'dd/MM'}}</td>
                </ng-container>

                <ng-container matColumnDef="loading">
                  <mat-footer-cell *matFooterCellDef colspan="6">
                    Carregando dados...
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                  <mat-footer-cell *matFooterCellDef colspan="6">
                   Nenhum aniversariante essa semana.
                  </mat-footer-cell>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsBirth; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsBirth;"></tr>
                <mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':isLoadingBirthDate==false}"></mat-footer-row>
                <mat-footer-row *matFooterRowDef="['noData']" [ngClass]="{'hide':!(dataSource3!=null && dataSource3.data.length==0)}"></mat-footer-row>
              </table>
            </section>
            </div>
      </div>
    </div>
  </div>
</div>
