<div>
  <label *ngIf="label" for="input" class="label">{{ label }}</label>
  <div class="input-image">
    <div *ngIf="preview" class="gradient-background"></div>
    <button type="button" class="add-button" (click)="openInput(input)" *ngIf="!preview">
      <mat-icon>image</mat-icon>
    </button>
    <button type="button" class="remove-button" (click)="removerImagem()" *ngIf="preview">
      <mat-icon>delete</mat-icon>
    </button>
    <div *ngIf="preview" class="image-container">
      <img [src]="preview" alt="image" />
    </div>
    <input
      #input
      id="input"
      name="input"
      [ngModel]="getModel()"
      type="file"
      accept="image/*"
      (change)="onChangeImage($event)"
    />
  </div>
</div>
