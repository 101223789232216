import { Component, OnInit, ViewChild } from '@angular/core';
import { BirthDay } from '../resumo-frequencia/resumo-frequencia.component';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { AlunoService } from '../../services/aluno.service';
import { ResponseApi } from '../../model/response-api';
import { Aluno } from '../../model/aluno.model';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-aniversariantes',
  templateUrl: './aniversariantes.component.html',
  styleUrls: ['./aniversariantes.component.scss']
})
export class AniversariantesComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  isLoadingBirthDate = false;
  dataSource3: MatTableDataSource<BirthDay>;

  aniversariantesList: BirthDay[] = [];
  alunosList: Aluno[] = [];

  displayedColumnsBirth: string[] = ['image', 'name', 'birthdate', 'tel'];

  constructor(
    private formBuilder: FormBuilder,
    private alunoService: AlunoService,
  ) {
    this.dataSource3 = new MatTableDataSource<BirthDay>();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      dataInicial: [new Date(), Validators.required],
      dataFinal: [new Date(), Validators.required],
    });
  }

  fetchAniversariantes() {
    this.isLoadingBirthDate = true;
    this.aniversariantesList = [];
    this.dataSource3.data = [];
    this.alunoService.aniversariantes(
      this.form.get('dataInicial').value,
      this.form.get('dataFinal').value,
    )
      .subscribe((responnse: ResponseApi) => {
        const imagemPadrao = "../../../assets/image/ebd20.png";
        this.alunosList = responnse['data'];
        this.alunosList.forEach((aluno) => {
          const aniversariante = new BirthDay(
            aluno.nome, aluno.data, aluno.imagem ? aluno.imagem : imagemPadrao, aluno.telefone,
          );
          this.aniversariantesList.push(aniversariante);
        });
        this.dataSource3.data = this.aniversariantesList;
        this.isLoadingBirthDate = false;
      });
  }

  gerarPdf() {

    const documentDefinition = {
      content: [
        {
          columns: [
            {
              text: 'Igreja Batista Nacional Ebenézer',
              style: 'header',
              margin: [10, 0, 0, 0]
            }
          ],
          marginBottom: 20
        },
        { text: 'Aniversariantes', style: 'selectionHeader' },
        {
          table: {
            widths: ['*', '20%', '20%', '20%'],
            body: [
              ['Nome', 'Data de Aniversário', 'Telefone'],
              ...this.aniversariantesList.map(aniversariante => ([aniversariante.nome, this.formatDateForPDF(aniversariante.data), aniversariante.telefone]))
            ],
            headerRows: 1,
            style: 'tableHeader'
          }
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        tableHeader: {
          fillColor: '#d3d3d3',
          bold: true,
          fontSize: 14,
          color: 'black'
        },
        sectionHeader: {
          bold: true,
          fontSize: 16,
          color: '#000'
        }
      }
    };
    pdfMake.createPdf(documentDefinition).download('Aniversariantes.pdf');
  }


  formatDateForPDF(dateInput: Date | string): string {
    const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // +1 porque os meses começam de 0
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }


  getImageDataURL(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result as string);
        }
        reader.onerror = function (error) {
          reject(error);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.onerror = function (error) {
        reject(error);
      }
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

}
