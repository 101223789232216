import { CursoNivel } from './../model/curso-nivel.model';
import { Response } from './../model/respose.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CursoNivelService {

  constructor(private http: HttpClient) { }

  createOrUpdate(cursoNivel: CursoNivel) {
    if (cursoNivel.id != null && cursoNivel.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/cursoNivel`, cursoNivel);
    } else {
      cursoNivel.id = null;
      return this.http.post(`${environment.apiUrl}/api/cursoNivel`, cursoNivel);
    }
  }
  findAll(page: number, count: number) {
    return this.http.get(`${environment.apiUrl}/api/cursoNivel/${page}/${count}`);
  }

  findAllNivel() {
    return this.http.get<CursoNivel[]>(`${environment.apiUrl}/api/cursoNivel/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/cursoNivel/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/cursoNivel/${id}`);
  }

  findByNome(n: CursoNivel) {
    n.nome = n.nome === '' ? 'uninformed' : n.nome;
    return this.http.get<Response>(`${environment.apiUrl}/api/cursoNivel/${n.nome}/`);
  }

}
