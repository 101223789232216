import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CalendarioService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(calendario) {
        if (calendario.id != null && calendario.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/calendario`, calendario);
        }
        else {
            calendario.id = null;
            return this.http.post(`${environment.apiUrl}/api/calendario`, calendario);
        }
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/calendario/${page}/${count}`);
    }
    findAllContas() {
        return this.http.get(`${environment.apiUrl}/api/calendario/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/calendario/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/calendario/${id}`);
    }
}
CalendarioService.ngInjectableDef = i0.defineInjectable({ factory: function CalendarioService_Factory() { return new CalendarioService(i0.inject(i1.HttpClient)); }, token: CalendarioService, providedIn: "root" });
