import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ddMmYyyyDate",
})
export class DdMmYyyyDatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return value;
    const dateParts = value.split("T")[0].split("-");
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  }
}
