
<div class="mb-3">
  <label *ngIf="label" for="input" class="label">{{ label }}</label>
  <div class="input-image">
    <mat-grid-list class="item" cols="4" rowHeight="1:1">
      <mat-grid-tile *ngFor="let image of images">
        <div class="gradient-background"></div>
          <button class="remove-button" (click)="removeImage(image)" mat-mini-fab color="primary">
            <mat-icon class="mat-18">delete</mat-icon>
           </button>
        <div class="image-container">
          <img *ngIf="image.preview" [src]="image.preview" alt="image">
        </div>

      </mat-grid-tile>
    </mat-grid-list>
    <div class="item">
      <button class="add-button" (click)="openInput(input)" mat-fab color="warn">
       <mat-icon>add_a_photo</mat-icon>
      </button>
    </div>
    <input
      #input
      id="input"
      name="input"
      type="file"
      multiple
      accept="image/*"
      (change)="onChangeImage($event)" />
    </div>
</div>
