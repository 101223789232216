import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LicaoV1Service {
    constructor(http) {
        this.http = http;
        this._onSave = new EventEmitter();
        this.base_url = environment.apiUrl;
    }
    onSave() {
        return this._onSave;
    }
    findById(id, professorId) {
        return this.findPage('', id, professorId).pipe(map(values => values && values.length > 0 ? values[0] : null));
    }
    findPage(text, id, professorId, turmaId, page = 0, pageSize = 20) {
        const params = {};
        if (text) {
            params['text'] = text;
        }
        if (id) {
            params['id'] = id;
        }
        if (professorId) {
            params['professorId'] = professorId;
        }
        if (turmaId) {
            params['turmaId'] = turmaId;
        }
        if (page) {
            params['page'] = page;
        }
        if (pageSize) {
            params['page-size'] = pageSize;
        }
        return this.http.get(`${this.base_url}/api/licao/v1/page`, { params });
    }
    findPageCount(text, id, professorId, turmaId, pageSize = 20) {
        const params = {};
        if (text) {
            params['text'] = text;
        }
        if (id) {
            params['id'] = id;
        }
        if (professorId) {
            params['professorId'] = professorId;
        }
        if (turmaId) {
            params['turmaId'] = turmaId;
        }
        if (pageSize) {
            params['page-size'] = pageSize;
        }
        return this.http.get(`${this.base_url}/api/licao/v1/page/count`, { params });
    }
    save(licaoEntity) {
        const formData = new FormData();
        formData.append('data', licaoEntity.data.toISOString());
        formData.append('turma', JSON.stringify(licaoEntity.turma));
        formData.append('professor', JSON.stringify(licaoEntity.professor));
        formData.append('tema', licaoEntity.tema);
        formData.append('objetivo', licaoEntity.objetivo);
        formData.append('conteudo', licaoEntity.conteudo);
        formData.append('metodologia', licaoEntity.metodologia);
        if (licaoEntity.experienciaPedagogica) {
            formData.append('experienciaPedagogica', licaoEntity.experienciaPedagogica);
        }
        if (licaoEntity.imagens && licaoEntity.imagens.length > 0) {
            const imagensUrl = [];
            for (const imagem of licaoEntity.imagens) {
                if (typeof imagem.data === 'string') {
                    imagensUrl.push(imagem.data);
                }
                if (imagem.data instanceof File) {
                    formData.append('imagens', imagem.data);
                }
            }
            if (imagensUrl.length > 0) {
                formData.append('imagensUrl', JSON.stringify(imagensUrl));
            }
        }
        formData.append('status', licaoEntity.status.toString());
        if (licaoEntity.id) {
            return this.http.put(`${this.base_url}/api/licao/v1/${licaoEntity.id}`, formData).pipe(tap(objetoSavo => this._onSave.emit(objetoSavo)));
        }
        else {
            return this.http.post(`${this.base_url}/api/licao/v1`, formData)
                .pipe(tap(objetoSavo => {
                this._onSave.emit(objetoSavo);
            }));
        }
    }
    delete(id) {
        return this.http.delete(`${this.base_url}/api/licao/v1/${id}`);
    }
    removerImagem(licaoId, link) {
        return this.http.delete(`${this.base_url}/api/licao/galeria/${licaoId}`, { params: { link } });
    }
    ativarLicao(id) {
        return this.http.post(`${environment.apiUrl}/api/licao/v1/activate/${id}`, {});
    }
    desativarLicao(id) {
        return this.http.post(`${environment.apiUrl}/api/licao/v1/deactivate/${id}`, {});
    }
}
LicaoV1Service.ngInjectableDef = i0.defineInjectable({ factory: function LicaoV1Service_Factory() { return new LicaoV1Service(i0.inject(i1.HttpClient)); }, token: LicaoV1Service, providedIn: "root" });
