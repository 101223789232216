<div class="container-fluid" #print>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Relatório de Frequência por Aluno</mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <form [formGroup]="form" class="form horizontal" #f="ngForm" (keydown)="mudanca($event.target.value)">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                    <input matInput placeholder="Data Inicial" id="data" formControlName="data" [matDatepicker]="data"
                      readonly="true" required>
                    <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #data></mat-datepicker>
                    <mat-error>Data é obrigatória</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                    <input matInput placeholder="Data Final" id="data2" formControlName="data2" [matDatepicker]="data2"
                      readonly="true" required>
                    <mat-datepicker-toggle matSuffix [for]="data2"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #data2></mat-datepicker>
                    <mat-error>Data é obrigatória</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="aluno">Aluno</mat-label>
                    <input placeholder="Selecione o Aluno" matInput [matAutocomplete]="auto" #target id="aluno"
                      formControlName="aluno" required>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let aluno of alunos | async" [value]="aluno"
                      (click)="alunoSelecionada(aluno.id)" >
                       {{aluno?.nome}}</mat-option>
                    </mat-autocomplete>

                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Aluno é obrigatório</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-3">
                  <button mat-raised-button color="primary" type="button" (click)="frequenciaPorAluno()"
                    [disabled]="!form.valid">Pesquisar</button>
                  <!--button mat-raised-button color="primary" type="button" (click)="gerarPdf()"
                    [disabled]="!form.valid">Imprimir</button-->
                </div>
              </div>
            </div>
            <div class="table-responsive" class="container-fluid" id="tabela">
              <mat-tab-group>


                <!-- TAB 2 -->
                <mat-tab label="Sintético" class="red">

                  <table mat-table [dataSource]="dataSource2" matSort class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                                    The actual rendered columns are set as a property on the row definition" -->

                    <!-- Position Column -->
                    <ng-container matColumnDef="aluno">
                      <th mat-header-cell *matHeaderCellDef> Aluno </th>
                      <td mat-cell *matCellDef="let element"> {{element?.turma}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="totalp">
                      <th mat-header-cell *matHeaderCellDef> Total Presente </th>
                      <td mat-cell *matCellDef="let element"> {{element?.totalMatriculados}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="totala">
                      <th mat-header-cell *matHeaderCellDef> Total Ausente </th>
                      <td mat-cell *matCellDef="let element"> {{element.faltas}} </td>
                    </ng-container>

                    <ng-container matColumnDef="percentual">
                      <th mat-header-cell *matHeaderCellDef> % </th>
                      <td mat-cell *matCellDef="let element"> {{element?.total | percent:'1.2'}} </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <!--ng-container matColumnDef="total">
                              <th mat-header-cell *matHeaderCellDef> Total </th>
                              <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                              <td mat-footer-cell *matFooterCellDef> {{ totalPresentes }} </td>
                            </ng-container-->

                    <tr mat-header-row *matHeaderRowDef="tab2"></tr>
                    <tr mat-row *matRowDef="let row; columns: tab2;"></tr>
                  </table>
                  <div *ngIf="isNothing === true">

                    <p>Nenhum registro encontrado.</p>
                  </div>
                </mat-tab>
                <!-- TAB 1 -->
                <mat-tab label="Analítico">
                  <mat-form-field class="filter">
                    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtro">
                  </mat-form-field>
                  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                                      The actual rendered columns are set as a property on the row definition" -->

                    <!-- Position Column -->
                    <ng-container matColumnDef="data">
                      <th mat-header-cell *matHeaderCellDef> Data </th>
                      <td mat-cell *matCellDef="let element"> {{element?.data | date}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="aluno">
                      <th mat-header-cell *matHeaderCellDef> Aluno </th>
                      <td mat-cell *matCellDef="let element"> {{element?.matricula?.aluno.nome}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="situação">
                      <th mat-header-cell *matHeaderCellDef> Situação </th>
                      <td mat-cell *matCellDef="let element">
                        <a *ngIf="element.presente == true" style="color: green;">
                          {{element?.presente == true ? 'Presente' :'Ausente'}}</a>
                        <a *ngIf="element.presente == false" style="color: orange;">
                          {{element?.presente == true ? 'Presente' :'Ausente'}}</a> </td>
                    </ng-container>

                    <ng-container matColumnDef="bimestre">
                      <th mat-header-cell *matHeaderCellDef> Bimestre </th>
                      <td mat-cell *matCellDef="let element"> {{element?.turma?.semestre}} </td>
                    </ng-container>
                    <!-- Symbol Column -->
                    <!--ng-container matColumnDef="total">
                                <th mat-header-cell *matHeaderCellDef> Total </th>
                                <td mat-cell *matCellDef="let element"> {{element.total}} </td>
                                <td mat-footer-cell *matFooterCellDef> {{ totalPresentes }} </td>
                              </ng-container-->
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
                  <div *ngIf="isNothing === true">
                    <p>Nenhum registro encontrado.</p>
                  </div>
                </mat-tab>
              </mat-tab-group>


              <!--div class="container-fluid">
                <div class="col-lg-6 col-md-6 col-sm-6" style="margin-left: 0%">
                  <mat-card class="resumo">
                    <mat-card-title style="color: green;">Total presentes:</mat-card-title>
                    <mat-card-content class="content">{{ totalPresentes }}</mat-card-content>
                  </mat-card>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6" style="margin-left: 0%">
                  <mat-card class="resumo">
                    <mat-card-title style="color: orange;">Total ausentes:</mat-card-title>
                    <mat-card-content class="content">{{ totalFaltosos }}</mat-card-content>
                  </mat-card>
                </div>
              </div-->
              <div class="container-fluid">
                <div class="row">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
