import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { User } from '../../model/user.model';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { ResponseApi } from '../../model/response-api';
import { MatDialog } from '@angular/material';
import { CarregandoComponent } from '../../shared/Avisos/carregando/carregando.component';
import { MenuRelatorioComponent } from '../../relatorio/menu-relatorio/menu-relatorio.component';

@Component({
  selector: "app-dashboard-nav",
  templateUrl: "./dashboard-nav.component.html",
  styleUrls: ["./dashboard-nav.component.css"],
})
export class DashboardNavComponent implements OnInit {
  @ViewChild("drawer") drawer: any;
  mostrarMenu = false;
  panelOpenState = false;
  opened: false;
  // public share: SharedService;
  public shared: SharedService;
  mobHeight: any;
  mobWidth: any;
  listUser = [];
  page: number = 0;
  count: number = 5;
  pages: Array<number>;
  acesso: any;

  toggleDrawer() {
    this.drawer.toggle();
    this.panelOpenState = !this.panelOpenState;
  }

  constructor(private userService: UserService, private dialog: MatDialog) {
    this.shared = SharedService.getInstance();

    this.shared.user = new User("", "", "", "", "", "", "", false, "");
    /* this.mobHeight = (window.screen.height) + 'px';
    this.mobWidth = (window.screen.width) + 'px';
    console.log(this.mobHeight);
    console.log(this.mobWidth);
    if (this.mobWidth > 600) {
      this.sidenavMode = 'over';
    } */
  }

  ngOnInit() {
    this.shared.showTemplate.subscribe((show) => (this.mostrarMenu = show));
    // this.findAll(this.page, this.count);
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(MenuRelatorioComponent, {
      width: "350px",
    });
    dialogRef.disableClose = true;
  }

  sair(): void {
    this.shared.token = null;
    this.shared.user = null;
    window.location.href = "/login";
    window.location.reload();
  }
  findAll(page: number, count: number) {
    this.acesso = this.shared.user.profile;
    this.userService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.listUser = responseApi["data"]["content"];
        this.pages = new Array(responseApi["data"]["totalPages"]);
      },
      (err) => {}
    );
  }

  getNomeSobrenome(nomeCompleto: string): string {
    if(nomeCompleto == null || nomeCompleto == "") return "";
    let partesDoNome = nomeCompleto.split(" ");
    if (partesDoNome.length > 0) {
      let nome = partesDoNome[0];
      let sobrenome = "";
      if (partesDoNome.length >= 2) {
        sobrenome = partesDoNome[1];
      }
      return nome + " " + sobrenome;
    }
  }

  // scroll de subir no topo
  scrollToElement($element): void {
    // console.log($element);
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
