<div class="container-fluid" style="padding-top: 20px;">
  <div class="row">
    <div
      class="col-lg-12 col-md-12 col-sm-12"
      style="margin-left: 0%"
      id="print-section">
      <div class="box box-danger">
        <form [formGroup]="form" class="form horizontal" #f="ngForm">
          <div class="row" style="
            padding-top: 20px;">
            <div class="form-group">
              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                  <input matInput placeholder="Data Inicial" id="dataInicial" formControlName="dataInicial"
                    [matDatepicker]="dataInicial" readonly="true" required>
                  <mat-datepicker-toggle matSuffix [for]="dataInicial"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #dataInicial></mat-datepicker>
                  <mat-error>Data é obrigatória</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                  <input matInput placeholder="Data Final" id="dataFinal" formControlName="dataFinal"
                    [matDatepicker]="dataFinal" readonly="true" required>
                  <mat-datepicker-toggle matSuffix [for]="dataFinal"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #dataFinal></mat-datepicker>
                  <mat-error>Data é obrigatória</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-3">
                <button mat-raised-button color="primary" type="button" (click)="fetchAniversariantes()"
                  [disabled]="!form.valid">Pesquisar</button>
              </div>
            </div>
          </div>
          <div class="table-responsive" class="container-fluid">
            <mat-toolbar>
              <span>Aniversariantes</span>
            </mat-toolbar>
            <section
              class="table-container"
              class="mat-elevation-z8"
              tabindex="0"
              style="padding-bottom: 20px;"
            >
              <table mat-table [dataSource]="dataSource3" matSort>
                <caption>
                  Titulo da table
                </caption>
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>📷</th>
                  <td mat-cell *matCellDef="let element">
                    <img [src]="element.imagem" />
                  </td>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Nome</th>
                  <td mat-cell *matCellDef="let element">{{ element.nome }}</td>
                </ng-container>

                <ng-container matColumnDef="birthdate">
                  <th mat-header-cell *matHeaderCellDef>Data de Aniversário</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.data | date : "dd/MM" }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="tel">
                  <th mat-header-cell *matHeaderCellDef>Telefone</th>
                  <td mat-cell *matCellDef="let element">{{ element.telefone }}</td>
                </ng-container>

                <ng-container matColumnDef="loading">
                  <mat-footer-cell *matFooterCellDef colspan="6">
                    Carregando dados...
                  </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                  <mat-footer-cell *matFooterCellDef colspan="6">
                    Nenhum aniversariante essa semana.
                  </mat-footer-cell>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedColumnsBirth; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumnsBirth"
                ></tr>
                <mat-footer-row
                  *matFooterRowDef="['loading']"
                  [ngClass]="{ hide: isLoadingBirthDate == false }"
                ></mat-footer-row>
                <mat-footer-row
                  *matFooterRowDef="['noData']"
                  [ngClass]="{
                    hide: !(dataSource3 != null && dataSource3.data.length == 0 && isLoadingBirthDate == true) }">
                </mat-footer-row>
              </table>
              <div class="table-footer" *ngIf="dataSource3 != null && dataSource3.data.length > 0">
                <button mat-raised-button color="warn" (click)="gerarPdf()">
                  <mat-icon>picture_as_pdf</mat-icon> Gerar PDF
                </button>
              </div>
              <div  style="padding-top: 20px;"></div>
            </section>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
