import { Response } from './../model/respose.model';
import { Calendario } from './../model/calendario.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(private http: HttpClient) { }

  createOrUpdate(calendario: Calendario) {
    if (calendario.id != null && calendario.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/calendario`, calendario);
    } else {
      calendario.id = null;

      return this.http.post(`${environment.apiUrl}/api/calendario`, calendario);
    }
  }

  findAll(page: number, count: number) {
    return this.http.get(`${environment.apiUrl}/api/calendario/${page}/${count}`);
  }

  findAllContas(): Observable<Calendario[]> {
    return this.http.get<Calendario[]>(`${environment.apiUrl}/api/calendario/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/calendario/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/calendario/${id}`);
  }
}
