<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-7" style="margin-left: 0%">
      <div class="box box-info">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Novo Curso</mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">
          <div class="row">
            <div class="form-group">
              <div class="col-sm-12">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="nome">Nome</mat-label>
                  <input matInput placeholder="Nome" id="nome" formControlName="nome" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Nome é obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div>
            <div class="row">
              <div class="form-group">
                <div class="col-sm-6">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="numero">Duração</mat-label>
                    <input matInput placeholder="Duração" id="duracao" formControlName="duracao" [mask]="maskTime" required>
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Duração é obrigatório</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-sm-6">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="nivel">Nivel</mat-label>
                    <mat-select placeholder="Nível" id="nivel" formControlName="nivel" required>
                      <mat-option *ngFor="let nivel of niveis | async" [value]="nivel"> {{ nivel.nome }}</mat-option>
                    </mat-select>
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Nível é obrigatório</mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>
          </div>

          <div class="buttons">
            <button mat-raised-button color="primary">Confirmar</button>
          </div>
          <!--app-form-debug [formularioDebug]="form"> </app-form-debug-->
        </form>
      </div>
    </div>
  </div>
</div>
