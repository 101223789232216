import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Turma } from '../../model/turma.model';
import { Matricula } from '../../model/matricula.model';
import { Frequencia } from '../../model/frequencia.model';
import { ResumoFrequencia } from '../resumo-frequencia/resumo-frequencia.model';
import { FrequenciaService } from '../../services/frequencia.service';
import { TurmaService } from '../../services/turma.service';
import { MatriculaService } from '../../services/matricula.service';
import Swal from 'sweetalert2';
import { BuscandoComponent } from '../../shared/Avisos/buscando/buscando.component';
import { Aluno } from '../../model/aluno.model';
import { AlunoService } from '../../services/aluno.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-relatorio-frequencia-aluno',
  templateUrl: './relatorio-frequencia-aluno.component.html',
  styleUrls: ['./relatorio-frequencia-aluno.component.css']
})
export class RelatorioFrequenciaAlunoComponent implements OnInit {

  @ViewChild('print') print: ElementRef;
  isLoading = false;
  isNothing = false;
  @ViewChild('f')
  @ViewChild(MatSort) sort: MatSort;
  f: NgForm;
  form: FormGroup;
  page = 0;
  count = 10;
  pages: Array<number>;
  turmas: Observable<Turma[]>;
  alunos: Observable<Aluno[]>;
  idAluno: string;
  idTurma: string;
  listTurma = [];
  listdoDia = [];
  totalMatriculados = 0;
  totalFaltosos = 0;
  tamanhoArray = 0;
  totalPresentes = 0;
  totalPresentes2 = 0;
  totalFaltosos2 = 0;
  totalVisitantes;
  valoresArrayFrequencia;
  dados;
  matriculas: Matricula[] = [];
  frequencias: Frequencia[] = [];
  chamadas: any[] = [];
  chamadas2: any[] = [];
  resumoList: any[] = [];
  nomeTurma;
  nomeAluno;
  dataSource: MatTableDataSource<any>;
  dataSource2: MatTableDataSource<ResumoFrequencia>;
  cor1 = 'green';
  cor2 = 'orange';
  idSelecionado;

  displayedColumns: string[] = ['data', 'aluno', 'situação', 'bimestre'];
  tab2: string[] = ['aluno', 'totalp', 'totala', 'percentual'];

  constructor(private formBuilder: FormBuilder,
    private frequenciaService: FrequenciaService,
    private turmaService: TurmaService,
    private alunoService: AlunoService,
    public dialog: MatDialog,
    private matriculaService: MatriculaService) {
    this.dataSource = new MatTableDataSource<any>();
    this.dataSource2 = new MatTableDataSource<ResumoFrequencia>();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      data: [new Date(), Validators.required],
      data2: [new Date(), Validators.required],
      aluno: [null, Validators.required]
    });

    this.alunos = this.alunoService.findAllAlunos().pipe(map(alunos => {
      // console.log(alunos);
      return alunos.data.filter(aluno => aluno.status === false);
    }));

    this.form.get('aluno').valueChanges
      .subscribe(nomeAluno => {
        if (nomeAluno && nomeAluno !== '') {
          this.alunos = this.alunoService.findByNomeContains(nomeAluno)
            .pipe(
              map(response => {
                // console.log(response.data);
                if (response.data.length > 0) {
                  this.chamadas2 = response.data;
                }
                console.log(this.chamadas2);
                return response.data ? response.data.filter(aluno => aluno.status === false) : [];
              }));
        }
      });
  }

  displayFn(aluno?: Aluno): string | undefined {
    return aluno ? aluno.nome : undefined;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyFilter2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  frequenciaPorAluno() {
    if (this.idSelecionado) {
      for (let index = 0; index < this.chamadas2.length; index++) {
        if (this.chamadas2[index].nome === this.idSelecionado) {
          this.idAluno = this.chamadas2[index].id;
          console.log(this.idAluno);
        }
      }
    }
    this.resumoList = [];
    if (this.idAluno !== '') {
      if (this.form.get('data').value <= this.form.get('data2').value) {
        this.totalFaltosos = 0;
        this.totalPresentes = 0;
        this.isNothing = false;
        this.abrirConfirmacao();
        this.matriculaService.findByAlunoId(this.idAluno).subscribe(resp => {
          if (resp && resp.data) {
            // pelo id do aluno, pego o id da sua turma que o aluno faz parte.
            console.log(resp.data);
            if (resp.data.length > 0) {
              this.idTurma = resp.data[0].turma.id;

            } else {
              this.dialog.closeAll();
            }
            this.frequenciaService.findByTurmaIdBetween(this.idTurma, this.form.get('data').value, this.form.get('data2').value)
              .subscribe(response => {
                // pelo id da turma e datas pego as frequencias.
                console.log(response);
                if (response && response.data) {
                  this.frequencias = response.data;
                  if (this.frequencias.length > 0) {
                    this.chamadas = response.data.filter(chamada => chamada.matricula.aluno.id === this.idAluno
                      && chamada.matricula.status === false);
                    console.log(this.chamadas);
                    // preciso a cada data somar mostrar os alunos que estavam presentes, depois
                    // mostrar o total matriculado, faltosos e total presentes.
                    for (let i = 0; i < this.frequencias.length; i++) {
                      if (this.frequencias[i].matricula.aluno.id === this.idAluno) {
                        if (this.frequencias[i].presente === true) {
                          this.totalPresentes++;
                        }
                        if (this.frequencias[i].presente === false) {
                          this.totalFaltosos++;
                        }
                      }
                    }
                    // this.chamadas = this.frequencias;
                    console.log(this.chamadas);

                    this.isNothing = false;
                    console.log(this.chamadas);
                    this.dialog.closeAll();
                  } else {
                    this.isNothing = true;
                    this.dialog.closeAll();
                  }
                }
                this.dataSource.data = this.chamadas;
                this.dataSource.sort = this.sort;
                // this.dialog.closeAll();
                console.log(this.dataSource.data);

                // TABELA 2
                if (this.frequencias.length > 0) {
                  this.matriculaService.findByTurmaId(this.idTurma).subscribe(resp => {
                    if (resp && resp.data) {
                      console.log(resp.data);
                      this.matriculas = resp.data.filter(matricula => matricula.aluno.id === this.idAluno && matricula.status === false);
                      console.log(this.matriculas);
                      if (this.matriculas.length > 0) {
                        for (let x = 0; x < this.matriculas.length; x++) {
                          for (let y = 0; y < this.frequencias.length; y++) {
                            this.nomeAluno = this.matriculas[x].aluno.nome;
                            if (this.matriculas[x].aluno.id === this.frequencias[y].matricula.aluno.id) {
                              if (this.frequencias[y].presente === true) {
                                this.totalPresentes2++;
                              }
                              if (this.frequencias[y].presente === false) {
                                this.totalFaltosos2++;
                              }
                            }
                          }
                          const length = this.totalFaltosos2 + this.totalPresentes2;
                          const resumo = new ResumoFrequencia(
                            this.nomeAluno, this.totalPresentes2,
                            this.totalFaltosos2, this.totalVisitantes,
                            this.totalPresentes2 / length);
                          this.resumoList.push(resumo);
                          console.log(resumo);
                          this.totalPresentes2 = 0;
                          this.totalFaltosos2 = 0;
                          this.idAluno = '';
                          this.idTurma = '';
                        }
                      }
                    }
                    this.dataSource2.data = this.resumoList;
                    this.dataSource2.sort = this.sort;
                    console.log(this.dataSource2.data);

                  });
                  this.chamadas = [];
                  this.resumoList = [];
                }
              });

            this.chamadas = [];
            this.resumoList = [];
          }
        });
        this.chamadas = [];
        this.resumoList = [];
      } else {
        this.abrirConfirmacao();
        this.infoData();
        this.dialog.closeAll();
      }
    }
  }

  /* resumo() {
    if (this.form.get('data').value <= this.form.get('data2').value) {
      this.totalFaltosos = 0;
      this.totalPresentes = 0;
      this.isNothing = false;
      this.valoresArrayFrequencia = this.listTurma.length;
      this.abrirConfirmacao();

      this.frequenciaService.findByTurmaIdBetween(
        this.idTurma, this.form.get('data').value, this.form.get('data2').value)
        .subscribe(response => {
          console.log(response);

          if (response && response.data) {
            this.frequencias = response.data;
            if (this.frequencias.length > 0) {
              // preciso a cada data somar mostrar os alunos que estavam presentes, depois
              // mostrar o total matriculado, faltosos e total presentes.
              for (let i = 0; i < this.frequencias.length; i++) {
                if (this.frequencias[i].presente === true) {
                  this.totalPresentes++;
                }
                if (this.frequencias[i].presente === false) {
                  this.totalFaltosos++;
                }
              }

              // cont aux = this.frequencias[0].matricula
              this.chamadas = this.frequencias;
              console.log(this.chamadas);



              this.isNothing = false;
              console.log(this.chamadas);
              this.dialog.closeAll();
            } else {
              this.isNothing = true;
              this.dialog.closeAll();
            }
          }
          this.dataSource.data = this.chamadas;
          this.dataSource.sort = this.sort;
          // this.dialog.closeAll();
          console.log(this.dataSource.data);

          // TABELA 2
          if (this.frequencias.length > 0) {
            this.matriculaService.findByAlunoId(this.idAluno).subscribe(resp => {
              if (resp && resp.data) {
                console.log(resp.data);
                this.matriculas = resp.data.filter(matricula => matricula.aluno && matricula.status === false);
                console.log(this.matriculas);
                if (this.matriculas.length > 0) {
                  for (let x = 0; x < this.matriculas.length; x++) {
                    for (let y = 0; y < this.frequencias.length; y++) {
                      this.nomeAluno = this.matriculas[x].aluno.nome;
                      if (this.matriculas[x].aluno.id === this.frequencias[y].matricula.aluno.id) {
                        if (this.frequencias[y].presente === true) {
                          this.totalPresentes2++;
                        }
                        if (this.frequencias[y].presente === false) {
                          this.totalFaltosos2++;
                        }
                      }
                    }
                    const length = this.totalFaltosos2 + this.totalPresentes2;
                    const resumo = new ResumoFrequencia(
                      this.nomeAluno, this.totalPresentes2,
                      this.totalFaltosos2,
                      this.totalPresentes2 / length);
                    this.resumoList.push(resumo);
                    console.log(resumo);
                    this.totalPresentes2 = 0;
                    this.totalFaltosos2 = 0;
                  }
                }
              }
              this.dataSource2.data = this.resumoList;
              this.dataSource2.sort = this.sort;
              console.log(this.dataSource2.data);

            });
          }
        });

      this.chamadas = [];
      this.resumoList = [];
      // this.dialog.closeAll();
    } else {
      this.abrirConfirmacao();
      this.infoData();
      this.dialog.closeAll();
    }

  } */

  alunoSelecionada(value: any) {
    this.idAluno = '';
    console.log(value);
    this.idAluno = value;
  }

  enter(value: any) {
    console.log(value);
    if (value.keyCode === 13) {
      //this.alunoSelecionada(this.idSelecionado);
      this.idAluno = value;
    }

  }

  mudanca(param: any) {
    console.log(param);
    this.idSelecionado = param;
    console.log(this.idSelecionado);
  }

  infoData() {
    Swal.fire({
      title: 'A data inical precisa ser inferior a data final.',
      type: 'info',
      text: 'A data informáda é inválida.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(BuscandoComponent, {
      width: '350px'
    });
    dialogRef.disableClose = true;
  }


}
