import { Response } from '../model/respose.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Licao } from '../model/licao.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LicaoService {

  constructor(private http: HttpClient) { }

  createOrUpdate(licao: Licao) {
    if (licao.id != null && licao.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/licao`, licao);
    } else {
      licao.id = null;
      return this.http.post(`${environment.apiUrl}/api/licao`, licao);
    }
  }



  findAll(page: number, count: number, experiencia: boolean, professor?: string) {
    const params = {}
      params['experiencia'] = experiencia
    if(professor) {
      params['professor'] = professor
    }

    return this.http.get(`${environment.apiUrl}/api/licao/${page}/${count}`, {params});
  }

  findAlllicao() {
    return this.http.get<Response>(`${environment.apiUrl}/api/licao/`);
  }
  findAlllicaoData(page: number, count: number) {
    return this.http.get<Response>(`${environment.apiUrl}/api/licao/${page}/${count}`).pipe(
      map(response => {
        console.log(response);
        return response.data.content.filter(licao => licao.status === false);
      })
    );
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/licao/${id}`);
  }
  findByTurmaId(idTurma: string, data: Date) {
    return this.http.get<Response>(`${environment.apiUrl}/api/licao/byTurmaId/${idTurma}/${moment(data).format('DDMMYYYY')}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/licao/${id}`);
  }
  pesquisar(tema: String) {
    return this.http.get<Response>(`${environment.apiUrl}/api/licao/byName/${tema}`);
  }

  ativarLicao(id: string) {
    return this.http.post(`${environment.apiUrl}/api/licao/ativar`, id);
  }

  desativarLicao(desativar: string) {
    return this.http.post(`${environment.apiUrl}/api/licao/desativar`, desativar);
  }

}
