import { FormGroup } from "@angular/forms";
import * as i0 from "@angular/core";
export class UtilitariosService {
    constructor() { }
    base64ToFile(fileBase65, type) {
        const png = fileBase65.split(",")[1];
        // const the_file = new Blob([window.atob(png)], {type: type});
        return new File([window.atob(png)], "icone.png", { type: type });
    }
    arrayBufferToBase64(buffer) {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    // Adiciona os dados que não são nulos do objeto1 ao objeto2
    mergeObject(objeto1, objeto2) {
        const objeto = objeto2;
        if (objeto != null) {
            Object.keys(objeto).forEach((atributo) => {
                if (objeto1 != null) {
                    if (Array.isArray(objeto[atributo]) && objeto1[atributo] != null) {
                        objeto[atributo] = objeto1[atributo];
                    }
                    else if (objeto[atributo] != null &&
                        this.isObject(objeto[atributo]) &&
                        !(objeto[atributo] instanceof Date)) {
                        this.mergeObject(objeto1[atributo], objeto[atributo]);
                    }
                    else if (objeto1[atributo] != null) {
                        objeto[atributo] = objeto1[atributo];
                    }
                }
            });
        }
        return objeto;
    }
    setDadosLocalStorage(nome, dados) {
        try {
            if (dados) {
                localStorage.setItem(nome, JSON.stringify(dados));
            }
        }
        catch (e) {
            return;
        }
    }
    getDadosLocalStorage(nome) {
        try {
            const item = localStorage.getItem(nome);
            if (item) {
                return JSON.parse(item);
            }
            return null;
        }
        catch (e) {
            return;
        }
    }
    removeUndefinedAttributes(obj) {
        const propNames = Object.getOwnPropertyNames(obj);
        propNames.forEach((propName) => {
            const value = obj[propName];
            if (value !== null && typeof value === "object") {
                this.removeUndefinedAttributes(obj[propName]);
            }
            else {
                if (!obj[propName]) {
                    delete obj[propName];
                }
            }
        });
    }
    removeDadosLocalStorage(nome) {
        localStorage.removeItem(nome);
    }
    formatCPF(cpf) {
        const aux = this.onlyNumbers(cpf);
        return `${aux.substring(0, 3)}.${aux.substring(3, 6)}.${aux.substring(6, 9)}-${aux.substring(9)}`;
    }
    formatCNPJ(cnpj) {
        const aux = this.onlyNumbers(cnpj);
        return `${aux.substring(0, 2)}.${aux.substring(2, 5)}
      .${aux.substring(5, 8)}/${aux.substring(8, 12)}-${aux.substring(12)}`;
    }
    onlyNumbers(texto) {
        let result = "";
        for (let i = 0; i < texto.length; i++) {
            if ("0123456789".indexOf(texto[i]) > -1) {
                result += texto[i];
            }
        }
        return result;
    }
    preencherFormGroup(form, object) {
        if (object) {
            Object.keys(form.value).forEach((property) => {
                if (form.get(property) instanceof FormGroup) {
                    this.preencherFormGroup(form.get(property), object[property]);
                }
                else {
                    if (form.get(property) && object) {
                        try {
                            const value = object[property];
                            form.get(property).setValue(value);
                            let valueIsEmptyString = false;
                            if (typeof value === "string") {
                                valueIsEmptyString = value === "";
                            }
                            if (value && !valueIsEmptyString) {
                                form.get(property).markAsDirty();
                            }
                        }
                        catch (err) {
                            console.error(err);
                        }
                    }
                }
            });
        }
    }
    limparFormGroup(form) {
        Object.keys(form.controls).forEach((name) => {
            if (form.get(name) instanceof FormGroup) {
                this.limparFormGroup(form.get(name));
            }
            else {
                form.get(name).reset();
            }
        });
    }
    desabilitarFormGroup(form) {
        Object.keys(form.controls).forEach((name) => {
            if (form.get(name) instanceof FormGroup) {
                this.desabilitarFormGroup(form.get(name));
            }
            else {
                form.get(name).disable();
            }
        });
    }
    habilitarFormGroup(form) {
        Object.keys(form.controls).forEach((name) => {
            if (form.get(name) instanceof FormGroup) {
                this.habilitarFormGroup(form.get(name));
            }
            else {
                if (form.get(name).disabled) {
                    form.get(name).enable();
                }
            }
        });
    }
    isObject(value) {
        return typeof value === "object";
    }
    isMobile() {
        return screen.width < 992;
    }
}
UtilitariosService.ngInjectableDef = i0.defineInjectable({ factory: function UtilitariosService_Factory() { return new UtilitariosService(); }, token: UtilitariosService, providedIn: "root" });
