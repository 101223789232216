import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { MatriculaService } from '../../services/matricula.service';
import { DialogService } from '../../../dialog.service';
import { Router } from '@angular/router';
import { ResponseApi } from '../../model/response-api';
import Swal from 'sweetalert2';
import { Aluno } from '../../model/aluno.model';
import { AlunoService } from '../../services/aluno.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BuscandoComponent } from '../../shared/Avisos/buscando/buscando.component';
import { MatriculaTableModel } from './matricula-table.model';

@Component({
  selector: 'app-matricula-list',
  templateUrl: './matricula-list.component.html',
  styleUrls: ['./matricula-list.component.css']
})
export class MatriculaListComponent implements OnInit {

  @ViewChild('f')
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  f: NgForm;
  form: FormGroup;
  page = 0;
  count = 20;
  pages: Array<number>;
  shared: SharedService;
  message: {};
  classCss: {};
  nome: string;
  listMatricula = [];
  resumoList: any[] = [];
  isLoading = false;
  nomeAluno: String;
  alunos: Observable<Aluno[]>;
  matriculas: any[] = [];
  dataSource = new MatTableDataSource<any>();
  // aluno: string;

  // displayedColumns: string[] = ['data', 'aluno', 'situação', 'bimestre'];
  displayedColumns: string[] = ['turma', 'aluno', 'data', 'status', 'acoes'];

  constructor(
    private dialogService: DialogService,
    public dialog: MatDialog,
    private matriculaService: MatriculaService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alunoService: AlunoService,
  ) {
    this.shared = SharedService.getInstance();
    this.dataSource = new MatTableDataSource<MatriculaTableModel>();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      aluno: [null, Validators.required],
      turma: [null, Validators.required],
      data: [new Date(), Validators.required],
      status: [null]
    });
    this.findAll(this.page, this.count);

    // this.alunos = this.alunoService.findAllAlunos().pipe(map(alunos => {
    //   return alunos.data.filter(aluno => aluno.status === false);
    // }));
    // this.form.get('aluno').valueChanges.subscribe(nomesAluno => {
    //   if (nomesAluno && nomesAluno !== '') {
    //     this.abrirConfirmacao();
    //     this.alunos = this.alunoService.findByNomeContains(nomesAluno)
    //       .pipe(
    //         map(response => {
    //           if (response.data.length > 0) {
    //             this.matriculas = response.data;
    //           }
    //           return response.data ? response.data.filter(aluno => aluno.status === false) : [];

    //         }));
    //     this.dialog.closeAll();
    //   }

    // });
  }
  displayFn(aluno?: Aluno): string | undefined {
    return aluno ? aluno.nome : undefined;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }

  pesquisar() {
    this.isLoading = true;
    if (this.nomeAluno) {
      this.resumoList = [];
      this.dataSource.data = [];
      this.matriculaService.pesquisarByName(this.nomeAluno).subscribe((values) => {
        console.log(values);
        this.listMatricula = values;
        this.matriculas = this.listMatricula;
        for(let index = 0; index <this.matriculas.length; index++) {
          const resumo = new MatriculaTableModel(
            this.matriculas[index].id,
            this.matriculas[index].turma.nome,
            this.matriculas[index].aluno.nome,
            this.matriculas[index].data,
            this.matriculas[index].status
          );
          this.resumoList.push(resumo);
        }
        this.isLoading = false;
        this.dataSource.data = this.resumoList;
        this.dataSource.sort = this.sort;
        this.listMatricula = []

      });
    } else {
      this.findAll(this.page, this.count);
      this.isLoading = false;
    }
  }

  findAll(page: number, count: number) {
    this.isLoading = true;
    this.resumoList = [];
    this.dataSource.data = [];
    this.matriculaService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.listMatricula = responseApi['data']['content'];
        this.pages = new Array(responseApi['data']['totalPages']);
        this.paginator = responseApi['data']['totalPages'];
        this.matriculas = this.listMatricula;
        for (let index = 0; index < this.matriculas.length; index++) {
          const resumo = new MatriculaTableModel(
            this.matriculas[index].id,
            this.matriculas[index].turma.nome,
            this.matriculas[index].aluno.nome,
            this.matriculas[index].data,
            this.matriculas[index].status
          );
          this.resumoList.push(resumo);
        }
        this.isLoading = false;
        this.dataSource.data = this.resumoList;
        this.dataSource.sort = this.sort;
        this.listMatricula = []
      },
      err => {

      }
    );
  }

  // ngAfterViewInit() {
  //   this.pageChanged({
  //     pageIndex: this.page,
  //     pageSize: this.count,
  //     length: this.paginator.length
  //   })
  //   // this.dataSource.paginator = this.paginator;
  //   // this.dataSource.sort = this.sort;
  // }


  edit(id: string) {
    this.router.navigate(['/matricula-new', id]);
  }

  ativarMatricula(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja ativar esta matrícula?',
      text: 'Você irá ativar!',
      type: 'question',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, ative!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.matriculaService.ativarMatricula(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Matrícula ativada com sucesso!',
          'Matrícula ativada.',
          'success'
        );
      }
    });
  }

  desativarMatricula(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja inativar esta matrícula?',
      text: 'Você irá inativar!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Ok!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.matriculaService.desativarMatricula(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Matrícula inativada com sucesso!',
          'Matrícula inativada.',
          'success'
        );
      }
    });
  }

  delete(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja cancelar a matrícula?',
      text: 'Você irá cancelar!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, cancele!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.matriculaService.delete(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Matrícula cancelada com sucesso!',
          'A matricula está cancelada.',
          'success'
        );
      }
    });
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(BuscandoComponent, {
      width: '350px'
    });
    dialogRef.disableClose = true;
  }

  alertaError() {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.'
    });
  }

pageChanged(event: PageEvent) {
    const list = this.resumoList;
    this.dataSource.data = list.slice((event.pageIndex - 1) * event.pageSize, event.pageSize);
  }


  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }
}
