<div *ngIf="listLicao.length === 0 && isLoading" class="spinner" text-center>
  <mat-card class="example-card">
      <mat-card-content class="card-content">
      <mat-progress-spinner class="progress-spinner" color="primary" mode="indeterminate"></mat-progress-spinner>
      <div class="loading-text">Carregando aguarde...</div>

    </mat-card-content>
  </mat-card>
</div>


<div *ngIf="sortedData.length > 0" class="table-responsive" class="container-fluid">
    <mat-toolbar>
        <span>Lista de Faltosos</span>
      </mat-toolbar>
  <mat-card>
     <mat-card-content>
          <table matSort (matSortChange)="sortData($event)">
            <tr>
              <th mat-sort-header="name">Nome</th>
              <th mat-sort-header="faltas">Faltas</th>
            </tr>
            <tr *ngFor="let aluno of sortedData">
              <td>{{aluno?.nome}}</td>
              <td style="text-align: center;">{{aluno?.contadorFaltas}}</td>
            </tr>
          </table>
         </mat-card-content>
  </mat-card>
</div>

<div class="container" *ngIf="!isLoading">
  <div class="row">
    <div class="col-lg-3 col-md-6 col-sm-12" style="margin-left: 0%"  *ngFor="let item of listLicao" >
      <mat-card class="example-card" id="topo">
        <mat-card-header>
          <img *ngIf="item.professor.imagem" mat-card-avatar class="example-header-image"
            src="{{ item?.professor?.imagem }}" alt="Photo professor" />
          <mat-card-title class="single-line-title">{{item.professor.nome}}</mat-card-title>
          <mat-card-title>{{item.data | ddMmYyyyDate }}</mat-card-title>
          <!-- <mat-card-subtitle> {{' Sala de: ' + item.turma.nome + ' - ' + ' Tema: ' + item.tema}} </mat-card-subtitle> -->
        </mat-card-header>
        <img class="img-content" *ngIf="item.imagens?.length > 0" mat-card-image src="{{item.imagens[0]?.link}}" alt="Photo lesson">

        <mat-card-content>
          <p>
            {{item.tema}}
          </p>
        </mat-card-content>
        <!-- <div>
          <mat-carousel
          timings="250ms ease-in"
          [autoplay]="false"
          interval="5000"
          color="accent"
          [maxWidth]="maxWidth"
          [slideHeight]=slideHeight
          [maintainAspectRatio]=maintainAspectRatio
          [proportion]="25"
          >
            <mat-carousel-slide
            *ngFor="let imagem of item.imagens"
            [image]="imagem.link">
            </mat-carousel-slide>
          </mat-carousel>
        </div> -->

        <!-- <img *ngIf="item.imagem" mat-card-image src="{{item?.imagem}}" alt="{{item.tema}}"> -->
        <!-- <mat-card-content>
          <div [innerHtml]="getSafeHtml(item.conteudo)"></div>
          <div *ngFor="let videoId of item.listaDeVideosId">
          <youtube-player [videoId]="videoId"></youtube-player>
          </div>
        </mat-card-content> -->

        <mat-card-actions class="actions">
          <mat-icon *ngIf="!item.experienciaPedagogica" matTooltip="Em aberto" style="color:#0013e9">
            lock_open
          </mat-icon>
          <mat-icon *ngIf="item.experienciaPedagogica" matTooltip="Aplicada em sala" style="color:#75e900">
            check_circle_outline</mat-icon>
        </mat-card-actions>

      </mat-card>
      <mat-divider></mat-divider>
    </div>
  </div>
</div>
