import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContasReceberService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(receber) {
        if (receber.id != null && receber.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/receber`, receber);
        }
        else {
            receber.id = null;
            return this.http.post(`${environment.apiUrl}/api/receber`, receber);
        }
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/receber/${page}/${count}`);
    }
    findAllReceber() {
        return this.http.get(`${environment.apiUrl}/api/receber/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/receber/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/receber/${id}`);
    }
}
ContasReceberService.ngInjectableDef = i0.defineInjectable({ factory: function ContasReceberService_Factory() { return new ContasReceberService(i0.inject(i1.HttpClient)); }, token: ContasReceberService, providedIn: "root" });
