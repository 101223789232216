<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-7" style="margin-left: 0%">
      <div class="box box-info">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Lista de Níveis de Curso</mat-card-title>
          </mat-card>
        </div>
        <div class="row">
          <div class="form-group">
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="col-sm-12">
                <mat-label for="nome">Pesquisar...</mat-label>
                <input matInput placeholder="Digite o nome" id="pesquisar" [(ngModel)]="cursoNivelFilter.nome" >
                <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                <mat-error>Nome é obrigatório</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button mat-raised-button color="primary" (click)="filter()">Pesquisar</button>
        </div>
        <div class="box-body">
          <table class="table table-bordered">
            <tr>
              <th>Nome</th>
              <th style="width: 10px">&nbsp;</th>
            </tr>
            <tr *ngFor="let nivel of listNivel">
              <td>{{nivel?.nome}}</td>
              <td>
                <button class="btn btn-primary" (click)="edit(nivel.id)">Editar</button>
              </td>
              <td>
                <button class="btn btn-danger" (click)="delete(nivel.id)">Desativar</button>
              </td>
            </tr>
          </table>
        </div>
        <div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href=""(click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</div>
