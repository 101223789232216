import { StatusPagEnum } from '../Enum/StatusPagEnum';
import { Aluno } from './aluno.model';
import { TipoContaEnum } from '../Enum/TipoContaEnum';
export class ContasPagar {
  constructor(
    public id: string,
    public historico: string,
    public vencimento: Date,
    public aluno: Aluno,
    public valor: number,
    public status: StatusPagEnum,
    public tipo: TipoContaEnum
  ) {}
}
