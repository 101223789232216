<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-7" style="margin-left: 0%">
      <div class="box box-info">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Lista de Cursos</mat-card-title>
          </mat-card>
        </div>


        <div class="box-body">
          <table class="table table-bordered">
            <tr>
              <th>Nome</th>
              <th>Duração</th>
              <th>Nível</th>
              <th style="width: 10px">&nbsp;</th>
              <th style="width: 10px">&nbsp;</th>
              <th style="width: 10px">&nbsp;</th>
            </tr>
            <tr *ngFor="let curso of listCurso">
              <td>{{curso.nome}}</td>
              <td>{{curso.duracao }}</td>
              <td>{{curso.nivel.nome}}</td>
              <td>
                <button class="btn btn-primary" (click)="edit(curso.id)">Editar</button>
              </td>
              <td>
                <button class="btn btn-danger" (click)="delete(curso.id)">Desativar</button>
              </td>
              <td></td>

            </tr>
          </table>
        </div>

        <div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href=""(click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</div>
