import { CurrentUser } from '../../../model/current-user.model';
import { UserService } from '../../../services/user.service';
import { SharedService } from '../../../services/shared.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../../model/user.model';
import { Router } from '@angular/router';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  @ViewChild('f')
  f: NgForm;

  form: FormGroup;
  user = new User('', '', '', '', '', '', '', false, '');
  shared: SharedService;
  message: string;
  hide = true;
  isLoading = false;
  color = 'warn';
  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      email: [null, Validators.required],
      password: [null, Validators.required],
      profile: [null],
      nome: [null],
      telefone: [null],
      imagem: [null],
      status: [null]
    });

  }

  login() {
    this.isLoading = true;
    // if (this.form.valid) {
    this.message = '';
    this.user = this.form.value;
    // console.log(this.login);
    this.userService.login(this.user).subscribe((userAuthentication: CurrentUser) => {
      this.shared.token = userAuthentication.token;
    this.shared.user = userAuthentication.user;
      this.shared.user.profile = this.shared.user.profile.substring(5);
      // this.shared.user.status = userAuthentication.user.status;
      if (this.shared.user.status !== true) {
        this.shared.showTemplate.emit(true);
        this.router.navigate(['/']);
      } else {
        this.usuarioDesativado();
      }
      this.isLoading = false;
    }, err => {
      if (err.status == 401) {
        this.emailSenha();
      } else {
        this.alertaFalha();
      }
      this.isLoading = false;
      this.shared.token = null;
      this.shared.user = null;
      this.shared.showTemplate.emit(false);
      this.message = 'Erro';
      this.emailSenha();
      // this.cancelLogin();
    });
    /*} else {
      console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
    } */
  }

  cancelLogin() {
    this.message = '';
    this.user = new User('', '', '', '', '', '', '', false, '');
    window.location.href = '/login';
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  ler() {
    this.router.navigate(['biblia']);
  }

  alertaFalha() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  usuarioDesativado() {
    Swal.fire({
      title: 'Atenção!',
      type: 'warning',
      text: 'Este usuário encontra-se inativado, favor entre em contado com o administrador.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  emailSenha() {
    Swal.fire({
      title: 'E-mail ou senha incorreto(s)!',
      type: 'warning',
      text: 'favor verificar as informações.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  getFromGroupClass(isInvalid: boolean, isDirty): {} {
    return {
      'form-group': true,
      'has-error': isInvalid && isDirty,
      'has-success': !isInvalid && isDirty
    };
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }
}
