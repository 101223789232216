<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-info">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Contas a Receber</mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">

          <div class="buttons">
            <button type="button" mat-stroked-button color="primary" (click)="show = !show">Incluir</button>
          </div>

          <div class="row" *ngIf="show">
            <div class="form-group">
              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="valor">Valor</mat-label>
                  <input matInput placeholder="R$ 100,00" id="valor" type="number" formControlName="valor" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Valor é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="pessoa">Aluno/Responsável </mat-label>
                  <mat-select placeholder="pessoa" id="pessoa" formControlName="pessoa" required>
                    <mat-option *ngFor="let pessoa of pessoas | async" [value]="pessoa">{{pessoa.nome}}</mat-option>
                  </mat-select>
                  <mat-error>Aluno/Responsável é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                  <input matInput placeholder="Data de Vencimento" id="vencimento" formControlName="vencimento"
                    [matDatepicker]="vencimento" required>
                  <mat-datepicker-toggle matSuffix [for]="vencimento"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #vencimento></mat-datepicker>
                  <mat-error>Data de vencimento é obrigatória</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="historico">Histórico</mat-label>
                  <input matInput placeholder="historico" id="historico" formControlName="historico" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Histórico é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="status">Status</mat-label>
                  <mat-select placeholder="status" id="status" formControlName="status" required>
                    <mat-option *ngFor="let status of tipoStatus" [value]="status.key">{{status.value}}</mat-option>
                  </mat-select>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->

                </mat-form-field>

              </div>
            </div>
            <div class="buttons2">
              <button mat-raised-button color="primary">Confirmar</button>
            </div>
          </div>


        </form>

      </div>
      <!-- outra tabela de caixa -->
      <div class="row">
        <div class="col-md-8">
          <div class="box">
            <div class="box-body">
              <table class="table table-bordered">
                <tr>
                  <th>Aluno/Responsável</th>
                  <th>Valor</th>
                  <th>Histórico </th>
                  <th>Vencimento</th>
                  <th>Status</th>
                  <th style="width: 10px">&nbsp;</th>
                  <th style="width: 10px">&nbsp;</th>
                </tr>
                <tr *ngFor="let receber of receberList">
                  <td>{{receber.pessoa}}</td>
                  <td>{{receber.valor}}</td>
                  <td>{{receber.historico}}</td>
                  <td>{{receber.vencimento}}</td>
                  <td>{{receber.status}}</td>
                  <!--td><button class="btn btn-primary" (click)="edit(aluno.id)">Editar</button></td>
                  <td><button class="btn btn-danger" (click)="delete(aluno.id)">Desativar</button></td>
                  <td></td> -->

                </tr>
              </table>
            </div>
            <!-- PAGINAÇÃO -->
            <div class="box-footer clearfix">
              <ul class="pagination pagination-sm no-margin pull-right">
                <li>
                  <a href="" (click)="setPreviousPage($event)">&laquo;</a>
                </li>
                <li *ngFor="let p of pages; let i=index">
                  <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
                </li>
                <li>
                  <a href="" (click)="setNextPage($event)">&raquo;</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--app-form-debug [formularioDebug]="form"></app-form-debug-->
</div>
