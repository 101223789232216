import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Lancamento, LancamentoResponse } from '../model/lancamento.model';

@Injectable({
  providedIn: 'root'
})
export class LancamentoService {
  private _onSave: EventEmitter<LancamentoResponse> = new EventEmitter<LancamentoResponse>()
  private base_url = environment.apiUrl;

  constructor(private http: HttpClient) { }

  onSave() {
    return this._onSave;
  }

  findById(id: string) {
    return this.findPage('', id).pipe(map(values => values && values.length > 0 ? values[0] : null))
  }

  findPage(text: string, id?: string, page = 0, pageSize = 20) {
    const params = {}
    if (text) {
      params['text'] = text
    }
    if (id) {
      params['id'] = id
    }
    if (page) {
      params['page'] = page
    }
    if (pageSize) {
      params['page-size'] = pageSize
    }
    return this.http.get<LancamentoResponse[]>(`${this.base_url}/api/lancamento/page`, { params })
  }

  findPageCount(text: string, id?: string, pageSize = 20) {
    const params = {}
    if (text) {
      params['text'] = text
    }
    if (id) {
      params['id'] = id
    }
    if (pageSize) {
      params['page-size'] = pageSize
    }
    return this.http.get<number>(`${this.base_url}/api/lancamento/page/count`, { params })
  }

  save(lancamento: Lancamento) {
    const formData = new FormData()
    formData.append('descricao', lancamento.descricao)
    if (lancamento.dataPagamento) {
      formData.append('dataPagamento', lancamento.dataPagamento.toISOString())
    }
    if (lancamento.dataVencimento) {
      formData.append('dataVencimento', lancamento.dataVencimento.toISOString())
    }

    if (lancamento.aluno) {
      formData.append('aluno', JSON.stringify(lancamento.aluno))
    }

    if (lancamento.imagens && lancamento.imagens.length > 0) {
      const imagensUrl: string[] = []
      for (const imagem of lancamento.imagens) {
        if (typeof imagem.data === 'string') {
          imagensUrl.push(imagem.data)
        }
        if (imagem.data instanceof File) {
          formData.append('imagens', imagem.data)
        }
      }

      if (imagensUrl.length > 0) {
        formData.append('imagensUrl', JSON.stringify(imagensUrl))
      }
    }

    formData.append('observacao', lancamento.observacao)
    // formData.append('', lancamento.tipoLancamento)

    if (lancamento.id) {
      return this.http.put<LancamentoResponse>(
        `${this.base_url}/api/lancamento/${lancamento.id}`,
        formData
      ).pipe(tap(ojetoSalvo => this._onSave.emit(ojetoSalvo)))
    } else {
      return this.http.post<LancamentoResponse>(
        `${this.base_url}/api/lancamento`,
        formData
      ).pipe(tap(ojetoSalvo => this._onSave.emit(ojetoSalvo)))
    }
  }

  delete(id: string) {
    return this.http.delete(`${this.base_url}/api/lancamento/${id}`)
  }

  removerImagem(empresaId: string, link: string) {
    return this.http.delete(`${this.base_url}/api/lancamento/galeria/${empresaId}`, { params: { link } })
  }


}
