<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Lista de Matrículas</mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <div class="table-responsive" class="container-fluid">
            <form (ngSubmit)="pesquisar()" autocomplete="off">
              <div class="row">
                <div class="col-sm-10">
                  <mat-form-field class="filter" class="col-sm-10">
                    <mat-label for="nomeAluno">Pesquisar...</mat-label>
                    <input matInput id="nomeAluno" name="nomeAluno" [(ngModel)]="nomeAluno"
                      placeholder="Digite o nome do aluno">
                  </mat-form-field>
                </div>
                <div class="col-sm-2" class="buttons">
                  <button mat-raised-button color="primary">Pesquisar</button>
                </div>
              </div>
            </form>
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">


              <ng-container matColumnDef="turma">
                <th mat-header-cell *matHeaderCellDef> Turma </th>
                <td mat-cell *matCellDef="let element"> {{element?.turma}} </td>
              </ng-container>

              <ng-container matColumnDef="aluno">
                <th mat-header-cell *matHeaderCellDef> Aluno </th>
                <td mat-cell *matCellDef="let element"> {{element?.aluno}} </td>
              </ng-container>

              <ng-container matColumnDef="data">
                <th mat-header-cell *matHeaderCellDef> Data </th>
                <td mat-cell *matCellDef="let element"> {{element?.data | date}} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.status === false" matTooltip="Matrícula ativa" style="color:green">
                    done_outline
                  </mat-icon>
                  <mat-icon *ngIf="element.status === true" matTooltip="Inativado" style="color:#E64A19">
                    person_add_disabled</mat-icon>
                </td>
              </ng-container>

              <ng-container matColumnDef="acoes">
                <th mat-header-cell *matHeaderCellDef> Ações </th>
                <td mat-cell *matCellDef="let row">
                  <mat-button-toggle (click)="edit(row.id)" matTooltip="Editar"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #0013e9">edit</mat-icon>
                  </mat-button-toggle>


                  <mat-button-toggle *ngIf="row.status === false" (click)="desativarMatricula(row.id)"
                    matTooltip="Inativar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">visibility</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle *ngIf="row.status === true" (click)="ativarMatricula(row.id)"
                    matTooltip="Ativar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: green">visibility_off</mat-icon>
                  </mat-button-toggle>

                  <mat-button-toggle matTooltip="Excluir" (click)="delete(row.id)"
                  matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                  <mat-icon style="color: #E64A19">delete_outline</mat-icon>
                </mat-button-toggle>
                </td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row> -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">Nada encontrado com esse filtro "{{input.value}}"</td>
              </tr>
            </table>

            <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator> -->

            <div class="box-footer clearfix">
              <ul class="pagination pagination-sm no-margin pull-right">
                <li>
                  <a href="" (click)="setPreviousPage($event)">&laquo;</a>
                </li>
                <li *ngFor="let p of pages; let i=index">
                  <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
                </li>
                <li>
                  <a href="" (click)="setNextPage($event)">&raquo;</a>
                </li>
              </ul>
            </div>

          </div>
          <!-- <form (ngSubmit)="pesquisar()" [formGroup]="form"  #f="ngForm">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-10">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="aluno">Pesquisar...</mat-label>
                    <input placeholder="Selecione o Aluno" matInput [matAutocomplete]="auto" #target id="aluno"
                      formControlName="aluno">
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let aluno of alunos | async" [value]="aluno"
                        (click)="alunoSelecionada(aluno.id)" >
                         {{aluno?.nome}}</mat-option>
                      </mat-autocomplete>
                    <mat-error>Nome é obrigatório</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="buttons">
              <button mat-raised-button color="primary">Pesquisar</button>
            </div>
          </form> -->
          <!-- <div class="table-responsive">
            <div *ngIf="!listMatricula || listMatricula.length <=0">
              <p class="box-body">Nenhum registro encontrado!</p>
            </div>
            <table class="table table-bordered" *ngIf="listMatricula && listMatricula.length > 0">
              <tr>
                <th>Turma</th>
                <th>Aluno</th>
                <th>Data da matrícula</th>
                <th>Professor(a)</th>
                <th>Status</th>
                <th style="width: 10px">Ações</th>
                <th style="width: 10px">&nbsp;</th>
                <th style="width: 10px">&nbsp;</th>
              </tr>
              <tr *ngFor="let matricula of listMatricula">
                <td>{{ matricula.turma.nome }}</td>
                <td>{{ matricula.aluno.nome }}</td>
                <td>{{ matricula.data | date }}</td>
                <td> {{ matricula.turma.professor.nome }}</td>
                <td>
                  <mat-icon *ngIf="matricula.status === false" matTooltip="Matrícula ativa" style="color:green">
                    done_outline
                  </mat-icon>
                  <mat-icon *ngIf="matricula.status === true" matTooltip="Inativado" style="color:#E64A19">
                    person_add_disabled</mat-icon>
                </td>
                <td>
                  <mat-button-toggle (click)="edit(matricula.id)" matTooltip="Editar"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #0013e9">edit</mat-icon>
                  </mat-button-toggle>
                </td>
                <td>
                  <mat-button-toggle *ngIf="matricula.status === false" (click)="desativarMatricula(matricula.id)"
                    matTooltip="Inativar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">visibility</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle *ngIf="matricula.status === true" (click)="ativarMatricula(matricula.id)"
                    matTooltip="Ativar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: green">visibility_off</mat-icon>
                  </mat-button-toggle>
                <td *ngIf="shared.user.profile == 'ADMIN'">
                  <mat-button-toggle matTooltip="Excluir" (click)="delete(matricula.id)"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">delete_outline</mat-icon>
                  </mat-button-toggle>
                </td>

              </tr>
            </table>
          </div> -->
        </div>
        <!-- <div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div> -->
        <!-- <div class="row" *ngIf="listMatricula && listMatricula.length > 0">
          <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
            <mat-card class="info">
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Ativo" style="color:green">done_outline</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ativo</td>
                  </tr>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Inativa" style="color:#E64A19">person_add_disabled</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inativo</td>
                  </tr>
                </div>
              </div>
            </mat-card>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
