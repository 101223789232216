<mat-card>
  <mat-card-title>
    <h4>Lancamento</h4>
    <p class="pt-2 mb-0">Para filtrar um lançamento clique em pesquisar.</p>

  </mat-card-title>
  <mat-card-content>
    <form #f [formGroup]="formulario" autocomplete="off">
      <div class="row">
        <div class="col-12 form-group">
          <mat-form-field class="filter" class="col-sm-12">
            <mat-label for="tema">Pesquisar...</mat-label>
            <input matInput id="tema" name="tema" formControlName="pesquisa" placeholder="Pesquisar...">
            <button mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <mat-card-actions>

        <button (click)="pesquisar()" mat-raised-button color="primary" type="button">Pesquisar</button>
        <button (click)="criar()" mat-raised-button color="primary" type="button">Cadastrar
          Novo</button>
      </mat-card-actions>
    </form>
    <div class="mat-elevation-z8">


      <table mat-table [dataSource]="dataSource">
        <caption>New York City Marathon Results 2013</caption>

        <ng-container matColumnDef="descricao">
          <th mat-header-cell *matHeaderCellDef> Descrição </th>
          <td mat-cell *matCellDef="let element"> {{element?.descricao}} </td>
        </ng-container>

        <ng-container matColumnDef="observacao">
          <th mat-header-cell *matHeaderCellDef> Observação </th>
          <td mat-cell *matCellDef="let element"> {{element?.observacao}} </td>
        </ng-container>
        <ng-container matColumnDef="acoes">
          <th mat-header-cell *matHeaderCellDef>Açoes</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="alterar(element.id)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deletar(element.id)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Nada encontrado com esse filtro </td>
        </tr>
      </table>
      <app-paginator (eventChange)="pesquisar(false)">
      </app-paginator>

      <mat-card *ngIf="carregando" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="warn" mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>

    </div>
  </mat-card-content>
</mat-card>
