
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetalhesLancamentoComponent } from './detalhes-lancamento.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatInputModule, MatIconModule, MatCardModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatDialogModule, MatProgressSpinnerModule, MatButtonToggleModule, MatTooltipModule, MatProgressBarModule, MatToolbarModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMaskModule } from '@fagnerlima/ng-mask';
import { ComponentsModule } from '../../components.module';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgMaskModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatToolbarModule,
    ComponentsModule,
    
  ],
  declarations: [DetalhesLancamentoComponent],
  exports: [DetalhesLancamentoComponent],
  entryComponents: [DetalhesLancamentoComponent]
})
export class DetalhesLancamentoModule { }
