<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger alternative" >
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title-group>
              <mat-card-title style="color: white">Novo Aluno</mat-card-title>
              <img *ngIf="imagemPefil" mat-card-avatar class="example-header-image"
              src="{{ imagemPefil }}" />
            </mat-card-title-group>
            
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">
          <div class="row">
            <div class="form-group">
              <div class="col-sm-12">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="nome">Nome</mat-label>
                  <input matInput placeholder="Nome" id="nome" formControlName="nome" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Nome é obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="telefone">Telefone</mat-label>
                  <input matInput placeholder="Telefone" id="telefone" formControlName="telefone" [mask]="maskPhone"
                    required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Telefone é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="cpfAluno">CPF</mat-label>
                  <input matInput placeholder="CPF" id="cpfAluno" #cpf maxlength="14" formControlName="cpfAluno"
                    [mask]="maskCpf">
                  <mat-hint align="end"> {{cpf.value.length}} / 14 </mat-hint>
                  <mat-hint *ngIf="form.get('cpfAluno').errors">O CPF não é válido.</mat-hint>
                </mat-form-field>
              </div>

              <div class="col-sm-4">
                <mat-form-field  appearance="outline" class="example-full-width" class="col-sm-12">
                  <input matInput placeholder="Data de nascimento" id="data" formControlName="data" [mask]="dateMask"
                    [matDatepicker]="data" required readonly="true">
                  <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                  <mat-datepicker disabled="false" [startAt]="data" touchUi #data></mat-datepicker>
                  <mat-error>Data de nascimento é obrigatória</mat-error>
                </mat-form-field>
              </div>

            </div>
          </div>
          <div>
            <div class="row">
              <div class="form-group">
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="cep">CEP</mat-label>
                    <input matInput placeholder="CEP" id="cep" formControlName="cep" #cepAluno maxlength="9"
                      (blur)="consultaCEP()" [mask]="maskCep" required>
                    <mat-hint align="end"> {{cepAluno.value.length}} / 9 </mat-hint>
                    <mat-error>CEP é obrigatório</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="numero">Número</mat-label>
                    <input matInput placeholder="Número" id="numero" formControlName="numero" required>
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Número é obrigatório</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-sm-6">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="complemento">Complemento</mat-label>
                    <input matInput placeholder="Complemento" id="complemento" formControlName="complemento">
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group">
                <div class="col-sm-12">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="logradouro">Endereço</mat-label>
                    <input matInput placeholder="Endereço" id="logradouro" formControlName="logradouro" required>
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Endereço é obrigatório</mat-error>
                  </mat-form-field>
                </div>

              </div>
            </div>

            <div class="row">
              <div class="form-group">
                <div class="col-sm-5">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="bairro">Bairro</mat-label>
                    <input matInput placeholder="Bairro" id="bairro" formControlName="bairro" required>
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Bairro é obrigatório</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-sm-4">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="cidade">Cidade</mat-label>
                    <input matInput placeholder="Cidade" id="cidade" formControlName="cidade" required>
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Cidade é obrigatório</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-select placeholder="Estado" for="estado" id="estado" formControlName="estado" required>
                      <mat-option *ngFor="let estado of estados | async" [value]="estado.sigla">{{ estado.nome }}
                      </mat-option>
                    </mat-select>
                    <mat-error>Estado é obrigatório</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row">

            <div class="form-group">

              <!--div class="col-sm-12">
                <div class="mat-checkbox">
                  <label class="checkbox-inline" for="checkBox">
                    <mat-checkbox formControlName="checkBox" id="checkBox">É dependente financeiro</mat-checkbox>
                  </label>
                </div>
              </div-->

              <div class="col-sm-12">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="responsavel">Responsável</mat-label>
                  <input matInput placeholder="Responsável" id="responsavel" formControlName="responsavel">
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Responsável é obrigatório</mat-error>
                </mat-form-field>

              </div>
              <!--button mat-raised-button (click)="abrirErroCpf()">Aviso!</button-->
            </div>
          </div>

          <div class="col-sm-12">
            <div>
              <img *ngIf="this.form.get('imagem').value" [src]="this.form.get('imagem').value" width="50%"
                height="50%">
              <input type="file" id="fileToUpload" (change)="onFileChange3($event)" style="display:none;" />
            </div>
            <div class="box-header with-border">
              <mat-card>
                <mat-card-subtitle>Mais opções</mat-card-subtitle>
                <mat-card-actions>
                  <button mat-icon-button matTooltip="Inserir foto" type="button"
                    onclick="document.getElementById('fileToUpload').click()">
                    <mat-icon>insert_photo</mat-icon>
                  </button>
                </mat-card-actions>
              </mat-card>
            </div>

          </div>

          <div class="container-fluid">
            <div class="buttons">
              <button mat-raised-button color="warn">Salvar</button>
            </div>
            <div class="listas">
              <button mat-raised-button color="primary" type="button" (click)="listaAlunos()">Lista de Alunos</button>
            </div>
            <div class="listas">
              <button mat-raised-button color="secundary" type="button" (click)="irMatricula()">Matrícula</button>
            </div>


          </div>

          <!--app-form-debug [formularioDebug]="form"> </app-form-debug irMatricula abrirConfirmacao-->

        </form>

        <div class="box box-danger"></div>
      </div>

    </div>
    <div *ngIf="isLoading" class="spinner" text-center>
      <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
    </div>
  </div>
