import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ReceberService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(contasReceber) {
        if (contasReceber.id != null && contasReceber.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/receber`, contasReceber);
        }
        else {
            contasReceber.id = null;
            return this.http.post(`${environment.apiUrl}/api/receber`, contasReceber);
        }
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/receber/${page}/${count}`);
    }
    findAllReceber() {
        return this.http.get(`${environment.apiUrl}/api/receber/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/receber/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/receber/${id}`);
    }
}
ReceberService.ngInjectableDef = i0.defineInjectable({ factory: function ReceberService_Factory() { return new ReceberService(i0.inject(i1.HttpClient)); }, token: ReceberService, providedIn: "root" });
