import { ApplicationRef, Component, OnInit } from '@angular/core';
import { LicaoService } from '../../services/licao.service';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';
import { ResponseApi } from '../../model/response-api';
import Swal from 'sweetalert2';
import { LicaoPrintComponent } from '../licao-print/licao-print.component';
import { MatDialog } from '@angular/material';
import { Licao } from '../../model/licao.model';

@Component({
  selector: 'app-licao-list',
  templateUrl: './licao-list.component.html',
  styleUrls: ['./licao-list.component.css']
})
export class LicaoListComponent implements OnInit {

  // printPage() {
  //   window.open();
  // }

  page: number = 0;
  count: number = 40;
  pages: Array<number>;
  shared: SharedService;
  tema: string;
  listLicao = [];
  isLoading = false;
  licao: Licao;

  constructor(
    private licaoService: LicaoService,
    private router: Router,
    private dialog: MatDialog,
    private appRef: ApplicationRef
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.findAll(this.page, this.count);
  }

  findAll(page: number, count: number) {
    this.isLoading = true;
    if (this.shared.user.profile === 'PROFESSOR') {
      this.licaoService.findAll(page, 10, true, this.shared.user.id).subscribe(
        (responseApi: ResponseApi) => {
          for (let index = 0; index < responseApi.data.content.length; index++) {
            if (this.shared.user.id === responseApi.data.content[index].professor.id) {
              this.listLicao.push(responseApi.data.content[index]);

            }

          }
          this.pages = new Array(responseApi['data']['totalPages']);

          this.isLoading = false;
        },
        err => {
          this.isLoading = false;
        }
      );
    } else {

      this.licaoService.findAll(page, 10, true).subscribe(
        (responseApi: ResponseApi) => {
          this.listLicao = responseApi['data']['content'];
          this.pages = Array(responseApi['data']['totalPages']);
          this.isLoading = false;
        })
    }
  }

  pesquisar() {
    if (this.tema) {
      this.licaoService.pesquisar(this.tema).subscribe((responseApi: ResponseApi) => {
        this.listLicao = responseApi.data;
      });
    } else {
      this.findAll(this.page, this.count);
    }
  }

  edit(id: string) {
    this.router.navigate(['/licao-new', id]);
  }

  print(id: string) {
    this.router.navigate(['/licao-print', id]);
  }

  onPrintModal(id: string) {
    if (id) {
      this.licaoService.findById(id).subscribe(licao2 => {
        this.licao = licao2.data;
        this.appRef.tick();
        setTimeout(() => {
          if (licao2.data) {
            const dialogRef = this.dialog.open(LicaoPrintComponent, {
              width: '600px', data: {
                data: this.licao
              }
            })
          }
        }, 200);
        // this.appRef.tick();
        // setTimeout(() => {
        //   this.router.navigate(['/licao-list']);
        // }, 300)
      });
    }


  }

  ativarLicao(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja ativar esta lição?',
      text: 'Você irá ativar!',
      type: 'question',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, ative!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.licaoService.ativarLicao(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Lição ativada com sucesso!',
          'A Lição está ativada.',
          'success'
        );
      }
    });
  }

  desativarLicao(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja inativar esta lição?',
      text: 'Você irá inativar!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Ok!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.licaoService.desativarLicao(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Lição inativada com sucesso!',
          'Está inativada.',
          'success'
        );
      }
    });
  }

  delete(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja excluir a lição?',
      text: 'Você irá Excluir!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.licaoService.delete(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Lição foi excluída com sucesso!',
          'Lição excluída.',
          'success'
        );
      }
    });
  }

  alertaError() {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.'
    });
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }

}
