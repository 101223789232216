
import { EstadosService } from '../../services/estado-br.service';
import { ConsultaCepService } from '../../services/consulta-cep.service';
import { AlunoService } from '../../services/aluno.service';
import { SharedService } from '../../services/shared.service';
import { Aluno } from '../../model/aluno.model';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseApi } from '../../model/response-api';
import { EstadoBr } from '../../model/estado-br.model';
import { Observable } from 'rxjs';
import { FormValidations } from '../../shared/form-validations';
import { MatDialog } from '@angular/material';
import { Mask } from '@fagnerlima/ng-mask';
import Swal from 'sweetalert2';
import { ValidateBrService } from 'angular-validate-br';
import { CarregandoComponent } from '../../shared/Avisos/carregando/carregando.component';



@Component({
  selector: 'app-aluno-new',
  templateUrl: './aluno-new.component.html',
  styleUrls: ['./aluno-new.component.css']
})
export class AlunoNewComponent implements OnInit {



  @ViewChild('f')
  f: NgForm;

  form: FormGroup;
  aluno = new Aluno('', '', '', '', new Date, '', '', '', '', '', '', '', '', false, '', 0);
  shared: SharedService;
  message: {};
  show = true;
  classCss: {};
  estados: Observable<EstadoBr[]>;
  cpfValido: Observable<Response>;
  mostrarResponsavel = true;
  isLoading = false;
  imagemPefil;
  readonly dateMask: Mask = new Mask('00/00/0000');
  readonly maskCpf: Mask = new Mask('000.000.000-00');
  readonly maskPhone: string = '(00) 00000-0000';
  readonly maskCep: string = 'brCep';

  constructor(
    private alunoService: AlunoService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cepService: ConsultaCepService,
    private estadosService: EstadosService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private validateBrService: ValidateBrService,
    private router: Router
  ) {
    this.shared = SharedService.getInstance();
  }


  ngOnInit() {
    this.estados = this.estadosService.getEstadosBr();

    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      cpfAluno: [null, this.validateBrService.cpf],
      telefone: [null, Validators.required],
      data: [Validators.required],
      cep: [null, [Validators.required, FormValidations.cepValidator]],
      numero: [null, Validators.required],
      complemento: [null],
      logradouro: [null, Validators.required],
      bairro: [null, Validators.required],
      cidade: [null, Validators.required],
      estado: [null, Validators.required],
      responsavel: [null],
      status: [null],
      imagem: [null]
    });


    this.route.params.subscribe(params => {
      if (params['id']) {
        this.alunoService.findById(params['id']).subscribe(aluno => {
          if (aluno && aluno.data) {
            //  console.log(aluno.data);
            /*  this.form.controls['nome'].setValue(aluno.data.nome);
              this.form.controls['cpfAluno'].setValue(aluno.data.cpfAluno);
              this.form.controls['telefone'].setValue(aluno.data.telefone);
              const data = new Date(aluno.data.data);
              this.form.controls['data'].setValue(data);
              // console.log(data);
              this.form.controls['cep'].setValue(aluno.data.cep);
              this.form.controls['numero'].setValue(aluno.data.numero);
              this.form.controls['complemento'].setValue(aluno.data.complemento);
              this.form.controls['logradouro'].setValue(aluno.data.logradouro);
              this.form.controls['bairro'].setValue(aluno.data.bairro);
              this.form.controls['cidade'].setValue(aluno.data.cidade);
              this.form.controls['estado'].setValue(aluno.data.estado);
              this.form.controls['responsavel'].setValue(aluno.data.responsavel); */
              this.imagemPefil = aluno.data.imagem;
            this.form.setValue(aluno.data);
            const data = new Date(aluno.data.data);
            this.form.controls['data'].setValue(data);
          }
        });
      }
    });


  }

  findById(id: string) {
    this.alunoService.findById(id).subscribe((responseApi: ResponseApi) => {
      this.aluno = responseApi.data;
    }, err => {
      this.alertaFalha();
    });
  }

  displayFn(aluno?: Aluno): string | undefined {
    return aluno ? aluno.nome : undefined;
  }

  register() {
    this.isLoading = true;
    this.abrirConfirmacao();
    if (this.form.valid) {
      this.message = {};
      this.aluno = this.form.value;
      this.alunoService.createOrUpdate(this.aluno).subscribe((responseApi: ResponseApi) => {
        this.aluno = new Aluno('', '', '', '', new Date, '', '', '', '', '', '', '', '', false, '', 0);
        const alunoRet: Aluno = responseApi.data;
        if (responseApi.errors && responseApi.errors.length > 0) {
        } else {
          this.resetaForm();
          this.dialog.closeAll();
          this.alertaSucesso();
          // this.abrirConfirmacao();
          this.isLoading = false;
        }
      }, err => {
        // console.log(err);
        if (this.aluno.cpfAluno !== '' && this.aluno.cpfAluno !== null) {
          if (this.alunoService.findByCpfAluno(this.aluno.cpfAluno)) {
            this.dialog.closeAll();
            this.abrirErroCpf();
            this.isLoading = false;
          }

        } else {
          this.dialog.closeAll();
          this.alertaFalha();
          this.isLoading = false;
        }
      });
    } else {
      // console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
      // this.alertaFalha();
      this.dialog.closeAll();
      this.isLoading = false;
    }
  }

  abrirConfirmacao() {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(CarregandoComponent, {
      width: '350px'
    });
    /*dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });*/
  }

  alertaSucesso() {
    Swal.fire({
      title: 'Aluno salvo com sucesso!',
      type: 'success',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  abrirErroCpf() {
    Swal.fire({
      title: 'CPF já cadastrado.',
      type: 'error',
      text: 'O CPF:' + this.aluno.cpfAluno + ' já pertence a outro aluno, favor digite outro.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }
  alertaFalha() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  carregarSalando() {
    Swal.fire({
      title: 'Salvando...',
      type: 'info',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  consultaCEP() {
    const cep = this.form.get('cep').value;

    if (cep != null && cep !== '') {
      this.cepService.consultaCEP(cep)
        .subscribe(dados => this.populaDadosForm(dados));
    }
  }

  populaDadosForm(dados) {
    // this.formulario.setValue({});

    this.form.patchValue({
      logradouro: dados.logradouro,
      // cep: dados.cep,
      complemento: dados.complemento,
      bairro: dados.bairro,
      cidade: dados.localidade,
      estado: dados.uf
    });
  }

  resetaDadosForm() {
    this.form.patchValue({
      endereco: {
        rua: null,
        complemento: null,
        bairro: null,
        cidade: null,
        estado: null
      }
    });
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      // console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  onFileChange3(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      const [imagem] = event.target.files;
      reader.readAsDataURL(imagem);

      reader.onload = () => {
        this.form.patchValue({
          imagem: reader.result as string,
        });
        this.cd.markForCheck();
      };
    }
  }

  onFileChange(event): void {
    if (event.target.files[0].size > 3000000) {
      this.alertaFalha();
    } else {
      // this.licao.imagem = '';
      const reader = new FileReader();
      reader.onloadend = (e: Event) => {
        this.aluno.imagem = reader.result as string;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  onFileChange2(event: Event) {
    const arquivoSelecionado = (event.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      this.aluno.imagem = dataUrl as string;
    };
    reader.readAsDataURL(arquivoSelecionado);
  }

  resetaForm() {
    this.f.resetForm();
  }

  listaAlunos() {
    this.router.navigate(['/aluno-list']);
  }

  irMatricula() {
    this.router.navigate(['/matricula-new']);
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-content-example-dialog',
  templateUrl: './DialogContentExampleDialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogContentExampleDialog { }

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-content-example-dialog',
  templateUrl: './ErroCpf.html',
})
// tslint:disable-next-line:component-class-suffix
export class ErroCpf { }
