import { Component, OnInit, ElementRef } from "@angular/core";
import { SharedService } from "../../services/shared.service";
import { LicaoService } from "../../services/licao.service";
import { ResponseApi } from "../../model/response-api";
import { User } from "../../model/user.model";
import { TimeAgoPipe } from "../../shared/time-ago.pipe";
import Swal from "sweetalert2";
import { Observable, forkJoin } from "rxjs";
import { Licao } from "../../model/licao.model";
import { AlunoService } from "../../services/aluno.service";
import { Aluno } from "../../model/aluno.model";
import { LicaoV1Service } from "../../services/licaov1.service";
import { map } from "rxjs/operators";
import { DomSanitizer } from "@angular/platform-browser";
import { Sort } from "@angular/material";

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

export interface FaltasAluno {
  nome: String,
  faltas: Number,
}

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  page = 0;
  count = 10;
  pages: Array<number>;
  listLicao = [];
  licoes = [];
  public shared: SharedService;
  alunosBirthday = [];
  isLoading = false;
  show = true;
  public parentHeight = "auto";
  public maintainAspectRatio = false;
  public proportion = 25;
  // public slideHeight = "540px";
  public slideHeight = "540px";
  public maxWidth = "auto";
  public videoIds: string[] = [];



  sortedData: Aluno[] = [];

  constructor(
    private licaoService: LicaoService,
    private licaoServiceV1: LicaoV1Service,
    private alunoService: AlunoService,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.pesquisar();
  }

  findBirthDay() {
    this.alunoService.findBirthDay().subscribe((response: ResponseApi) => {
      this.alunosBirthday = response["data"];
      // this.alunosBirthday = this.alunosBirthday.sort((a, b) => new Date(a.data).getTime() - new Date(b.data).getTime());
    });
  }

  pesquisar() {
    this.isLoading = true;
    this.sortedData = [];
    forkJoin([
      this.licaoServiceV1.findPageCount("", "", "", "", 10),
      this.licaoServiceV1.findPage("", "", "", "", 0, 10).pipe(
        map((values) =>
          values.map((value) => {
            return value;
          })
        )
      ),
      this.alunoService.alunosFaltosos(false)
      //1. se for professor, pegar o id da turma
          //1.1 consultar todos alunos com o id da turma que estão ativos.
          //1.2 pegar o id de cada aluno e consultar e retornar apenas os alunos que estão com faltas.
      //2. se não for professor, pegar todos os ids.
          //2.1 para cada turma, consultar os alunos matriculados
          //2.2 para cada aluno consultar a quantidade
      //3. Olhar apenas para os alunos que estão com faltas.

    ])
      .pipe()
      .subscribe(
        ([pageCount, values, listaFaltosos]) => {
          for (let index = 0; index < values.length; index++) {
            var element = values[index];
            element.listaDeVideosId = this.getVideosHtml(element.conteudo);
            this.listLicao.push(element);
          }
          this.isLoading = false;
          for (let indexFaltas = 0; indexFaltas < listaFaltosos.data.length; indexFaltas++) {
            const alunoFaltoso:Aluno = listaFaltosos.data[indexFaltas];
            this.sortedData.push(alunoFaltoso);
          }
          console.log(listaFaltosos);
          // this.sortData = listaFaltosos;
        },
        (error) => {
          this.isLoading = false;
          throw error;
        }
      );
  }

  findAll(page: number, count: number) {
    this.isLoading = true;

    this.licaoService.findAll(page, count, false).subscribe(
      (responseApi: ResponseApi) => {
        // console.log(responseApi);
        const dataAtual = Date.now();
        const tempoPostado = responseApi.data.content.data - dataAtual;
        // console.log('aqui hora0' + tempoPostado);
        for (let index = 0; index < responseApi.data.content.length; index++) {
          if (responseApi.data.content[index].status === false) {
            this.licoes = responseApi.data.content[index];
            this.listLicao.push(this.licoes);
          }
        }
        // this.listLicao = responseApi.data.content;
        // console.log(this.listLicao);
        this.pages = new Array(responseApi["data"]["totalPages"]);
        this.isLoading = false;
      },
      (err) => {
        this.alertaFalha();
        this.isLoading = false;
      }
    );
  }

  getSafeHtml(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  getVideosHtml(content: string): string[] {
    // const regexVideo =
    //   /<oembed url="https:\/\/www.youtube.com\/watch\?v=([a-zA-Z0-9_-]+)[^"]*"><\/oembed>/g;
    const regexVideo =
      /<oembed url="https:\/\/(?:www\.youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)[^"]*"><\/oembed>/g;

    this.videoIds = [];
    let match;
    while ((match = regexVideo.exec(content)) !== null) {
      this.videoIds.push(match[1]);
    }
    return this.videoIds;
  }

  processItem(item) {
    this.getVideosHtml(item.conteudo);
  }

  alertaFalha() {
    Swal.fire({
      title: "Atenção!",
      type: "warning",
      text: "Não há nenhuma postagem. Seja o primeiro!",
      footer: "Igreja Batista Nacional Ebenézer",
    });
  }

  // scroll de subir no topo
  scrollToElement($element): void {
    // console.log($element);
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  home() {
    window.scrollTo(0, 0);
  }

  sortData(sort: Sort) {
    const data = this.sortedData;
    if (!sort.active && sort.direction === "") {
      this.sortedData = data;
      return;
    }
    this.sortedData = data.sort((a,b) => {
       const isAsc = sort.direction === "asc";
       switch (sort.active) {
         case "nome":
           return this.compare(a, b, isAsc);
         case "contadorFaltas":
           return this.compare(a, b, isAsc);
       }
    })
  }
   compare(a, b, isAsc) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
}
