import { ApplicationRef, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Licao } from '../../model/licao.model';
import { MAT_DIALOG_DATA } from '@angular/material';
import { LicaoService } from '../../services/licao.service';
import { SharedService } from '../../services/shared.service';
import { TurmaService } from '../../services/turma.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-licao-print',
  templateUrl: './licao-print.component.html',
  styleUrls: ['./licao-print.component.css']
})
export class LicaoPrintComponent implements OnInit {
  shared: SharedService;
  licao: Licao;
  licaoData: Licao;
  // turmas: Observable<Turma[]>;
  printPage() {
    window.print();
  }

  printDialog() {
    let printData = document.getElementById('dataToPrint')
      .cloneNode(true);
    document.body.appendChild(printData);
    window.print();
    document.body.removeChild(printData);
  }

  constructor(
    // @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private turmaService: TurmaService,
    private licaoService: LicaoService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router,
    private appRef: ApplicationRef
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {

    // this.form = this.formBuilder.group({
    //   id: [null],
    //   data: [new Date(), Validators.required],
    //   turma: [null, Validators.required],
    //   professor: [null, Validators.required],
    //   tema: [null, Validators.required],
    //   objetivo: [null, Validators.required],
    //   conteudo: [null, Validators.required],
    //   metodologia: [null, Validators.required],
    //   experienciaPedagogica: [null],
    //   imagem: [null],
    //   status: [null]
    // });

    // if (this.data) {
    //   this.licao = this.data;
    //   // this.appRef.tick();
    //   setTimeout(() => {
    //     console.log(this.licao)
    //     this.printDialog();
    //   }, 200);
    // }

    this.route.params.subscribe(({ id }) => {
      if (id) {
        this.licaoService.findById(id).subscribe(licao => {
          this.licao = licao.data;
          console.log(this.licao);
          this.appRef.tick();
          setTimeout(() => {
            this.printPage();
          }, 200);
          this.appRef.tick();
          setTimeout(() => {
            this.router.navigate(['/licao-list']);
          }, 300)
        });
      }
    });
  }


}
