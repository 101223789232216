import { BirthdayComponent } from './birthday.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
  imports: [
    CommonModule
  ],
  exports: [BirthdayComponent],
  declarations: [BirthdayComponent]
})
export class BirthdayModule { }
