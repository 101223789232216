<form #f="ngForm" class="paginator-component">
  <button class="mr-2"  mat-icon-button color="warn" [disabled]="page === 1" (click)="prevAll()"> <mat-icon>keyboard_double_arrow_left</mat-icon></button>
  <button color="warn" class="mr-2"  mat-icon-button  [disabled]="page === 1" (click)="prev()"><mat-icon>navigate_before</mat-icon></button>
  <span class="mr-2"> Itens por página: </span>
  <input class="mr-2" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}" (input)="change()" type="number">
  <span class="mr-2"> Página: </span>
  <input class="mr-2" [(ngModel)]="page" [ngModelOptions]="{standalone: true}" (input)="change()" type="number">
  <span class="mr-2"> de {{totalPages}}</span>
  <button color="warn"  mat-icon-button [disabled]="page >= totalPages" (click)="next()"><mat-icon>navigate_next</mat-icon></button>
  <button mat-icon-button color="warn" [disabled]="page >= totalPages" (click)="nextAll()"><mat-icon>keyboard_double_arrow_right</mat-icon></button>
</form>
