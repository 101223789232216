<mat-card>
  <div *ngIf="licao" class="container-fluid" id="dataToPrint" class="onlyPrint">
    <div class="row no-gutters">
      <div class="col">
        <p><b> Sala: {{licao.turma.nome}}</b></p>
        <p><b>Data: {{licao.data | date: 'dd/MM/yyyy' }}</b></p>
        <p><b>Tema: {{ licao.tema }}</b></p>
      </div>
    </div>



    <div class="row no-gutters items-group">
      <div class="">
        <p><b>Objetivos:</b></p>
      </div>
      <ul class="disclaimer">
        <p>{{licao.objetivo}}</p>
      </ul>
    </div>

    <div class="row no-gutters items-group">
      <div class="">
        <p><b>Conteúdo:</b></p>
      </div>
      <ul class="disclaimer">
        <p>{{licao.conteudo}}</p>
      </ul>
    </div>

    <div class="row no-gutters items-group">
      <div class="col">
        <p class=""><b>Metodologia:</b></p>
        <ul class="disclaimer">
          <p>{{licao.metodologia}}</p>
          <!-- <p>{{licao.experienciaPedagogica}}</p> -->
        </ul>
      </div>
    </div>
    <div class="row no-gutters items-group">
      <div class="">
        <p class=""><b>Experiência Pedagógica:</b></p>
        <ul class="disclaimer">
          <p>{{licao.experienciaPedagogica}}</p>
        </ul>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <p class="disclaimer signature-field">Assinatura Professor</p>
      </div>

      <!-- <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Fechar</button>
        <button mat-button (click)="printDialog()" cdkFocusInitial>Imprimir</button>
      </div> -->
    </div>
  </div>
</mat-card>