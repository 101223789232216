<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Lista de Turmas</mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <form (ngSubmit)="pesquisar()">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-10">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="nomeTurma">Pesquisar...</mat-label>
                    <input matInput placeholder="Digite o nome" id="nomeTurma" name="nomeTurma" [(ngModel)]="nomeTurma">
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="buttons">
              <button mat-raised-button color="primary" type="submit">Pesquisar</button>
            </div>
          </form>
          <div class="box-body" class="table-responsive">
            <div *ngIf="!listTurma || listTurma.length <=0">
              <p class="box-body">Nenhum registro encontrado!</p>
            </div>
            <table class="table table-bordered" *ngIf="listTurma && listTurma.length > 0">
              <tr>
                <th>Turma</th>
                <th>Ano</th>
                <th>Bimestre</th>
                <th>Status</th>
                <th style="width: 10px">Ações</th>
                <th style="width: 10px">&nbsp;</th>
              </tr>
              <tr *ngFor="let turma of listTurma">
                <td>{{ turma.nome }}</td>
                <td>{{ turma.ano }}</td>
                <td>{{ turma.semestre }}</td>
                <td>
                  <mat-icon *ngIf="turma.status === false" matTooltip="Turma ativa" style="color: green">done_outline
                  </mat-icon>
                  <mat-icon *ngIf="turma.status === true" matTooltip="Turma inativada" style="color:#E64A19">
                    person_add_disabled</mat-icon>
                </td>

                <td>
                  <mat-button-toggle (click)="edit(turma.id)" matTooltip="Editar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #0013e9">edit</mat-icon>
                  </mat-button-toggle>
                </td>
                <td>
                  <mat-button-toggle *ngIf="turma.status === false" (click)="desativarTurma(turma.id)"
                    matTooltip="Inativar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">visibility</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle *ngIf="turma.status === true" (click)="ativarTurma(turma.id)" matTooltip="Ativar"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: green">visibility_off</mat-icon>
                  </mat-button-toggle>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div>
        <div class="row" *ngIf="listTurma && listTurma.length > 0">
          <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
            <mat-card class="info">
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Ativo" style="color:green">done_outline</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Turma ativa</td>
                  </tr>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Inativo" style="color:#E64A19">person_add_disabled</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Turma desativada</td>
                  </tr>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
