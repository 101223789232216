import { GrupoPagEnum } from './../../Enum/GrupoPagEnum';
import { CaixaService } from './../../services/caixa.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import 'rxjs/add/observable/of';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { ResponseApi } from '../../model/response-api';
import { DialogService } from '../../../dialog.service';

@Component({
  selector: 'app-caixa',
  templateUrl: './caixa.component.html',
  styleUrls: ['./caixa.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CaixaComponent implements OnInit {
  dataSource;
  displayedColumns: ['data', 'origem', 'valor', 'tipo'];

  tipos = [
    { key: 'CREDITO', value: 'Crédito' },
    { key: 'DEBITO', value: 'Débito' }
  ];

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  shared: SharedService;
  message: {};
  classCss: {};
  show: boolean = true;
  caixaList = [];
  page = 0;
  count = 5;
  pages: Array<number>;


  constructor(
    private caixaService: CaixaService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private dialogService: DialogService
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    /* this.caixaService.findAllCaixa().subscribe(results => {
      if (!results) {
        return;
      }
      this.dataSource = new MatTableDataSource(results);
    }); */

    this.findAll(this.page, this.count);

    this.form = this.formBuilder.group({
      id: [null],
      origem: [null, Validators.required],
      data: [new Date(), Validators.required],
      valor: [null, Validators.required],
      tipo: [null, Validators.required]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.caixaService.findById(params['id']).subscribe(caixa => {
          if (caixa && caixa.data) {
            console.log(this.form.setValue(caixa.data));
            this.form.setValue(caixa.data);
          }
        });
      }
    });
  }

  findAll(page: number, count: number) {
    this.caixaService.findAll(page, count).subscribe((responseApi: ResponseApi) => {
      console.log(responseApi);
      this.caixaList = responseApi['data']['content'];
      this.pages = new Array(responseApi['data']['totalPages']);
    }, err => {
      console.log(err);
      /* this.showMessage({
        type: 'error',
        text: err['error']['errors'][0]
      });*/
    });
  }

  register() {
    if (this.form.valid) {
      console.log(this.form.value);
      this.caixaService.createOrUpdate(this.form.value).subscribe(
        (responseApi: ResponseApi) => {
          console.log(responseApi);
          this.resetaForm();
          this.findAll(this.page, this.count);
          this.abrirConfirmacao();
        },
        err => {
          console.log(err);
        }
      );
    } else {
      console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
    }
  }

  delete(id: string) {
    this.dialogService.confirm('Você deseja excluir este Registro ?')
      .then((canDelete: boolean) => {
        if (canDelete) {
          this.message = {};
          this.caixaService.delete(id).subscribe(
            (responseApi: ResponseApi) => {
// mensagem de confirmação aqui
              this.findAll(this.page, this.count);
            },
            err => {
              console.log('deu erro');
            }
          );
        }
      });
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(Confirmacao);

    setTimeout(() => {
      this.dialog.closeAll();
    }, 3000);

    /* dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    }); */
  }
  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
      this.page = i;
      this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confimacao',
  templateUrl: './Confirmacao.html'
})
// tslint:disable-next-line:component-class-suffix
export class Confirmacao {}
