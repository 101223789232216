import { ResponseApi } from './../model/response-api';
import { Observable } from 'rxjs';
import { Caixa } from './../model/caixa.model';
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Response } from '../model/respose.model';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CaixaService {

  constructor(private http: HttpClient) { }

  createOrUpdate(caixa: Caixa) {
    if (caixa.id != null && caixa.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/caixa`, caixa);
    } else {
      caixa.id = null;
      return this.http.post(`${environment.apiUrl}/api/caixa`, caixa);
    }
  }

  findAll(page: number, count: number) {
    return this.http.get<ResponseApi>(`${environment.apiUrl}/api/caixa/${page}/${count}`);
  }

  findAllCaixa(): Observable<Caixa[]> {
    return this.http.get<Caixa[]>(`${environment.apiUrl}/api/caixa/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/caixa/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/caixa/${id}`);
  }
}
