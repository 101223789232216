<!--app-header [hidden]="!showTemplate"></app-header-->
<!--app-menu [hidden]="!showTemplate"></app-menu-->
<!--app-user-list></app-user-list-->

<app-dashboard-nav>

  <router-outlet></router-outlet>
  <app-footer [hidden]="!showTemplate"></app-footer>
</app-dashboard-nav>




