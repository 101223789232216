import { Matricula } from './matricula.model';
import { Turma } from './turma.model';
export class Frequencia {
  constructor(
    public id: string,
    public data: Date,
    public turma: Turma,
    public presente: boolean,
    public matricula: Matricula,
    public visitantes: string
  ) {}
}
