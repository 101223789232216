<h2 mat-dialog-title><span>Novo Lançamento</span></h2>
<mat-progress-bar mode="buffer" *ngIf="carregando == true"></mat-progress-bar>
<mat-dialog-content class="mat-typography">
  <form #f [formGroup]="form">
    <div class="row">
      <div class="form-group">
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
            <input matInput placeholder="Data do lançamento" id="dataVencimento" formControlName="dataVencimento"
              [matDatepicker]="dataVencimento" readonly="true" required>
            <mat-datepicker-toggle matSuffix [for]="dataVencimento"></mat-datepicker-toggle>
            <mat-datepicker touchUi #dataVencimento></mat-datepicker>
            <mat-error>Data é obrigatória</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">

        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="descricao">Descição</mat-label>
            <input matInput placeholder="Descrição" id="descricao" formControlName="descricao" required>
            <mat-error>descricao é obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="observacao">Observações</mat-label>
            <input matInput placeholder="Objetivos gerais e específicos" id="observacao" formControlName="observacao">
            <mat-error>Observação é obrigatório</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <app-images-lancamento formControlName="imagens" (onImageDeleted)="deleteImage($event)"></app-images-lancamento>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-raised-button color="warn" (click)="salvar()" cdkFocusInitial>Salvar</button>
</mat-dialog-actions>
