<h2 mat-dialog-title>
    <button type="submit" class="btn btn-success">
      <mat-icon class="white-icon">done_outline</mat-icon>
    </button>
    Registro salvo com sucesso!</h2>
  <mat-dialog-content class="mat-typography">


  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Fechar</button>
  </mat-dialog-actions>
