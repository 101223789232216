import { User } from './../../model/user.model';
import { UserService } from './../../services/user.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ResponseApi } from '../../model/response-api';
import { Turma } from '../../model/turma.model';
import { SharedService } from '../../services/shared.service';
import { CarregandoComponent } from '../../shared/Avisos/carregando/carregando.component';
import { TurmaService } from './../../services/turma.service';


@Component({
  selector: 'app-turma-new',
  templateUrl: './turma-new.component.html',
  styleUrls: ['./turma-new.component.css']
})
export class TurmaNewComponent implements OnInit {

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  turma = new Turma('', '', [], '', '', true, false);
  shared: SharedService;
  message: {};
  classCss: {};
  professores: Observable<User[]>;
  isLoading = false;
  turmaId;

  constructor(
    private turmaService: TurmaService,
    private userService: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    // this.cursos = this.cursoService.findAllCurso();

    this.professores = this.userService.findAllUser()
      .pipe(
        map(users => {
          return users.filter(user => user.profile === 'ROLE_PROFESSOR');
        })
      );

    this.form = this.formBuilder.group({
      id: [null],
      nome: [null, Validators.required],
      professoresId: [null, Validators.required],
      ano: [null, Validators.required],
      semestre: [null, Validators.required],
      status: [null],
      escolaDeMusica: [null]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.turmaService.findById(params['id']).subscribe(turma => {
          if (turma && turma.data) {
            // this.form.controls['nome'].setValue(turma.data.nome);
            // this.form.controls['professor'].setValue(turma.data.professor.nome);
            // this.form.controls['ano'].setValue(turma.data.ano);
            // this.form.controls['semestre'].setValue(turma.data.semestre);
            // console.log(turma.data);
            this.form.setValue(turma.data);
            //this.form.setValue(turma.data);
            // console.log(turma.data.professor.nome);
            // console.log(this.form.setValue(turma.data));


          }
        });
      }

    });
  }

  findById(id: string) {
    this.turmaService.findById(id).subscribe((responseApi: ResponseApi) => {
      this.turmaId = responseApi.data;
    }, err => {
      this.alertaFalha();
    });
  }

  register() {
    if (this.form.valid) {
      this.isLoading = true;
      this.abrirConfirmacao();
      this.turma = this.form.value;
      // console.log(this.form.value);
      /* if (this.turmaService.findByNomeContains(this.turma.professor.id)) {
         this.abrirErroProfessorCadastrado();
         this.isLoading = false;
       } else { */

      /* if (this.turmaService.findByNomeContains(this.turma.professor.id)) {
        //console.log(this.turma.professor.id);
        this.isLoading = false;
        this.abrirErroProfessorCadastrado();
      } */
      this.turmaService.createOrUpdate(this.turma).subscribe(
        (responseApi: ResponseApi) => {
          console.log(responseApi);
          this.resetaForm();
          this.dialog.closeAll();
          this.alertaSucesso();
          this.isLoading = false;
        }, err => {
          if (this.turmaService.findByNomeContains(this.form.get('nome').value)) {
            this.isLoading = false;
            this.dialog.closeAll();
            this.alertaTurmaCadastrada();
          }
        });
    } else {
      this.verificaValidacoesForm(this.form);
      this.dialog.closeAll();
      this.alertaFalha();
    }
  }


  alertaSucesso() {
    Swal.fire({
      title: 'Turma salva com sucesso!',
      type: 'success',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaFalha() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaTurmaCadastrada() {
    Swal.fire({
      title: 'Turma já cadastrada!',
      type: 'warning',
      text: 'turma já cadastrada, favor verificar.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  // abrirErroProfessorCadastrado() {
  //   Swal.fire({
  //     title: 'Professor(a) já pertence a outra turma!',
  //     type: 'info',
  //     text: 'O Professor ' + this.turma.professor.nome + ' já percentece a outra turma.',
  //     footer: 'Igreja Batista Nacional Ebenézer'
  //   });
  // }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(CarregandoComponent, {
      width: '350px'
    });
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      // console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }

  listaTurmas() {
    this.router.navigate(['/turma-list']);
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confimacao',
  templateUrl: './Confirmacao.html',
})
// tslint:disable-next-line:component-class-suffix
export class Confirmacao { }
