import { TipoLancamento } from '../Enum/TipoLancamento';
import { Aluno } from './aluno.model';
import { Arquivo } from './arquivo.model';
export class Lancamento {
    id: string = null
    descricao: string = null
    dataVencimento: Date = null
    dataPagamento: Date = null
    observacao: string = null
    tipoLancamento: TipoLancamento = null
    aluno: Aluno = null
    imagens: Arquivo<any>[] = []
    links: string[] = []
}
export interface LancamentoResponse {
    id: string
    descricao: string
    dataVencimento: Date
    dataPagamento: Date
    observacao: string
    tipoLancamento: TipoLancamento
    aluno: Aluno
    imagens: string[]
    links: string[]
}

