import { Turma } from './turma.model';
import { User } from './user.model';
import { Comentario } from './comentario.model';

 export class Licao {
  constructor(
    public id: string,
    public data: Date,
    public turma: Turma,
    public professor: User,
    public tema: String,
    public objetivo: String,
    public conteudo: String,
    public metodologia: String,
    public experienciaPedagogica: String,
    public imagem: string,
    public status: boolean,
    public comentarios: Comentario[]
  ) {}
 }
