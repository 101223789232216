import { Turma } from './../../model/turma.model';
import { UserService } from '../../services/user.service';
import { SharedService } from '../../services/shared.service';
import { User } from '../../model/user.model';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseApi } from '../../model/response-api';
import Swal from 'sweetalert2';
import { CarregandoComponent } from '../../shared/Avisos/carregando/carregando.component';
import { MatDialog } from '@angular/material';
import { TurmaService } from '../../services/turma.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.css']
})
export class UserNewComponent implements OnInit {

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  user = new User('', '', '', '', '', '', '', false, '');
  public shared: SharedService;
  message: {};
  classCss: {};
  perfis = [];
  hide = true;
  page: number = 0;
  count: number = 5;
  pages: Array<number>;
  readonly maskPhone: string = '(00) 00000-0000';
  isLoading = false;
  turmas: Observable<Turma[]>;
  hasTurma = false;
  isLoadingTurma = false;

  constructor(
    private userService: UserService,
    private turmaService: TurmaService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      email: [null, Validators.required],
      password: [null, Validators.required],
      profile: [null, Validators.required],
      nome: [null],
      telefone: [null],
      imagem: [null],
      status: [null],
      turmaId: [null]
    });

    this.route.params.subscribe(params => {
      const id = params['id'];
      if (id) {
        this.userService.findById(id).subscribe(user => {
          if (user && user.data) {
            this.form.setValue(user.data);
          }
        });
      }
    });

    this.form.get('profile').valueChanges.subscribe(perfil => {
      const turmaIdControl = this.form.get('turmaId');
      if (perfil === 'ROLE_PROFESSOR') {
        this.isLoadingTurma = true;

        this.turmas = this.turmaService.findAllTurma()
          .pipe(map(turma => {
            return turma.data.filter(turmaDetail => turmaDetail.status === false);
          }))
        this.turmas.subscribe(turmas => {
          if (turmas.length > 0) {
            this.hasTurma = true;
          } else {
            turmaIdControl.setValidators(Validators.required);
          }
        });
        console.log(this.turmas);
        this.isLoadingTurma = false;
      } else {
        turmaIdControl.setAsyncValidators(null);
        this.hasTurma = false
      }
      turmaIdControl.updateValueAndValidity();
    })



  }

  required(req: boolean) {
    req = false;
    if (this.form.get('profile').value === 'ROLE_PROFESSOR') {
      req = null;
    }
    console.log(req);
  }
  listaUsuarios() {
    this.router.navigate(['/user-list']);
  }
  findById(id: string) {
    this.userService.findById(id).subscribe((responseApi: ResponseApi) => {
      this.user = responseApi.data;
      this.user.password = '';
    }, err => {
      this.showMessage({
        type: 'error',
        text: err['error']['errors'][0]
      });
    });
  }

  findAll(page: number, count: number) {
    this.userService.findAll(page, count).subscribe((responseApi: ResponseApi) => {
      this.perfis = responseApi['data']['content'];
      this.pages = new Array(responseApi['data']['totalPages']);
    }, err => {
      this.showMessage({
        type: 'error',
        text: err['error']['errors'][0]
      });
    });
  }

  register() {
    this.isLoading = true;
    if (this.form.valid) {
      this.abrirConfirmacao();
      this.user = this.form.value;
      console.log(this.user);
      this.userService.createOrUpdate(this.user).subscribe((responseApi: ResponseApi) => {
        this.user = new User('', '', '', '', '', '', '', false, '');
        this.resetaForm();
        this.dialog.closeAll();
        this.alertaSucesso();
        this.isLoading = false;
      }, err => {
        if (this.userService.findByEmailContains(this.user.email)) {
          this.dialog.closeAll();
          this.isLoading = false;
          this.alertaEmailExistente();
        }
        // this.alertaFalha();
        this.dialog.closeAll();
        this.isLoading = false;
      });
    } else {
      // this.alertaFalha();
      this.dialog.closeAll();
      console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
      this.isLoading = false;
    }
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(CarregandoComponent, {
      width: '350px'
    });
  }

  alertaSucesso() {
    Swal.fire({
      title: 'Usuário salvo com sucesso!',
      type: 'success',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }
  alertaFalha() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }
  alertaFalhaProfessor() {
    Swal.fire({
      title: 'Atenção!',
      type: 'warning',
      text: 'Para professor os campos: NOME e TELEFONE precisam ser preenchidos.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaEmailExistente() {
    Swal.fire({
      title: 'E-mail de usuário já cadastrado!',
      type: 'warning',
      text: 'Caso tenha esquecido a senha, favor procurar o administrador.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }


  private showMessage(message: { type: string, text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      'alert': true
    };
    this.classCss['alert-' + type] = true;
  }

  getFromGroupClass(isInvalid: boolean, isDirty): {} {
    return {
      'form-group': true,
      'has-error': isInvalid && isDirty,
      'has-success': !isInvalid && isDirty
    };
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }

  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      const [imagem] = event.target.files;
      reader.readAsDataURL(imagem);

      reader.onload = () => {
        this.form.patchValue({
          imagem: reader.result as string,
        });
        this.cd.markForCheck();
      };
    }
  }

}
