import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface PageOption {
  page: number;
  pageSize: number;
  totalPages: number;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  @Input()
  page = 1;
  @Input()
  pageSize = 10;
  @Input()
  totalPages = 1;
  @Input()
  screen = '';

  @Output()
  eventChange: EventEmitter<PageOption> = new EventEmitter<PageOption>();

  constructor() { }

  ngOnInit() {
    const size = parseInt(localStorage.getItem(this.screen + '.althaPageSize'), 0);
    if (size > 0) {
      this.pageSize = size;
    }
  }

  next() {
    this.page++;
    this.change();
  }

  nextAll() {
    this.page = this.totalPages;
    this.change();
  }

  prev() {
    this.page--;
    this.change();
  }

  prevAll() {
    this.page = 1;
    this.change();
  }

  change() {
    if (this.page && this.totalPages && this.pageSize) {
      localStorage.setItem(this.screen + '.pageSize', this.pageSize + '');
      if (this.page > this.totalPages) {
        this.nextAll();
      }
      this.eventChange.emit(
        {
          page: this.page ? this.page : 0,
          pageSize: this.pageSize,
          totalPages: this.totalPages,
        },
      );
    }
  }

}
