import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatSort, MatTableDataSource, PageEvent } from '@angular/material';
import { forkJoin, interval } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { LicaoV1Service } from '../../../services/licaov1.service';
import { SharedService } from '../../../services/shared.service';
import { PaginatorComponent } from '../../paginator/paginator.component';
import { LicaoDetalhesComponent } from '../licao-detalhes/licao-detalhes.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-licao-listagem',
  templateUrl: './licao-listagem.component.html',
  styleUrls: ['./licao-listagem.component.scss']
})
export class LicaoListagemComponent implements OnInit {
  carregando = false;
  ativo = true;

  shared: SharedService;
  tema: string;
  @ViewChild('f') form;
  formulario: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(PaginatorComponent)
  public paginator: PaginatorComponent;
  pageSize = 10;
  length = 0;
  pageEvent: PageEvent;

  dataSource = new MatTableDataSource<any>();
  tempDataSource: any;
  displayedColumns: string[] = ['data', 'turma', 'tema', 'status', 'editar', 'ativar', 'deletar'];
  constructor(
    private dialog: MatDialog,
    private builder: FormBuilder,
    private licaoService: LicaoV1Service,
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.formulario = this.builder.group({
      pesquisar: [null]
    })

    this.licaoService.onSave()
      .pipe(takeWhile(() => this.ativo))
      .subscribe((value) => {
        if (value == null) return
        const newData = this.dataSource.data
        const index = newData.findIndex(data => data.id === value.id)
        if (index > -1) {
          newData[index] = value
        } else {
          newData.unshift(value)
        }
        this.dataSource.data = newData
      })

    const subs = interval(500)
      .subscribe((value) => {
        if (this.paginator) {
          subs.unsubscribe()
          this.pesquisar()
        }
      })
  }
  ngOnDestroy() {
    this.ativo = false
  }

  pesquisar(reset = true) {
    this.carregando = true;
    if (reset) {
      this.paginator.page = 1
    }
    const pesquisar = this.formulario.get('pesquisar').value
    const turmaId = this.shared.user.profile === 'PROFESSOR' ? this.shared.user.turmaId : null;
    forkJoin([
      this.licaoService.findPageCount(pesquisar, '', '', turmaId,  this.paginator.pageSize),
      this.licaoService.findPage(pesquisar, '', '',turmaId, this.paginator.page - 1, this.paginator.pageSize)
        .pipe(map(values => values.map(value => {
          return value;
        })))

    ])
      .pipe(
        takeWhile(() => this.ativo),
      )
      .subscribe(([pageCount, values]) => {
        this.carregando = false;
        this.dataSource.data = values;
        this.paginator.totalPages = pageCount;
      }, err => {
        this.carregando = false;
        throw err;
      });
  }


  criar() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(LicaoDetalhesComponent);
  }

  alterar(event: any) {
    this.dialog.open(LicaoDetalhesComponent, { data: event });
  }


  deletar(event) {
    Swal.fire({
      title: 'Tem certeza que deseja deletar a Lição?',
      text: 'Você irá DELETAR!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.licaoService.delete(event).subscribe(() => {
          this.onDelete(event);
        }, () => {
          this.alertaError();
        });
        Swal.fire(
          {
            title: 'Lição deletada com sucesso!',
            type: 'success'
          }
        );
      }
    });
  }


  ativarLicao(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja ativar esta lição?',
      text: 'Você irá ativar!',
      type: 'question',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, ative!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.licaoService.ativarLicao(id).subscribe(() => {
          this.pesquisar();
        }, () => {
          this.alertaError();
        });
        Swal.fire(
          'Lição ativada com sucesso!',
          'A Lição está ativada.',
          'success'
        );
      }
    });
  }

  desativarLicao(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja inativar esta lição?',
      text: 'Você irá inativar!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Ok!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.licaoService.desativarLicao(id).subscribe(() => {
          this.pesquisar();
        }, () => {
          this.alertaError();
        });
        Swal.fire(
          'Lição inativada com sucesso!',
          'Está inativada.',
          'success'
        );
      }
    });
  }


  alertaError() {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.'
    });
  }

  onDelete(licaoId: string) {
    const newData = this.dataSource.data;
    const index = newData.findIndex(data => data.id == licaoId);
    if(index >-1) {
      newData.splice(index, 1);
      this.dataSource.data = newData;
    }
  }


}
