import { Turma } from './../model/turma.model';
import { Response } from './../model/respose.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../model/user.model';
import { map } from 'rxjs/operators';
import { ResponseApi } from '../model/response-api';
import { ResponseArray } from '../model/responseArray.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TurmaService {

  constructor(private http: HttpClient) { }

  createOrUpdate(turma: Turma) {
    if (turma.id != null && turma.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/turma`, turma);
    } else {
      turma.id = null;
      return this.http.post(`${environment.apiUrl}/api/turma`, turma);
    }
  }
  findAllTurmasAtivas(page: number, count: number) {
    return this.http.get<ResponseArray>(`${environment.apiUrl}/api/turma/${page}/${count}`).pipe(
      map(response => {
        console.log(response);
        return response.data.content.filter(turma => turma.status === false);
      })
    );
  }

  findAll(page: number, count: number) {
    return this.http.get<Response>(`${environment.apiUrl}/api/turma/${page}/${count}`);
  }

  findAllTurma() {
    return this.http.get<Response>(`${environment.apiUrl}/api/turma/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/turma/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/turma/${id}`);
  }

  findByNomeContains(nomeTurma: String) {
    return this.http.get<Response>(`${environment.apiUrl}/api/turma/byName/${nomeTurma}`);
  }

  ativarTurma(id: string) {
    return this.http.post(`${environment.apiUrl}/api/turma/ativar`, id);
  }

  desativarTurma(desativar: string) {
    return this.http.post(`${environment.apiUrl}/api/turma/desativar`, desativar);
  }

}
