import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ApplicationRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Arquivo } from '../../../model/arquivo.model';
import { Lancamento } from '../../../model/lancamento.model';
import { LancamentoService } from '../../../services/lancamento.service';
import { UtilitariosService } from '../../../services/utilitario.service';
import { ImagePreview } from '../images-lancamento/images-lancamento.component';



@Component({
  selector: 'app-detalhes-lancamento',
  templateUrl: './detalhes-lancamento.component.html',
  styleUrls: ['./detalhes-lancamento.component.css']
})
export class DetalhesLancamentoComponent implements OnInit {

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  id: string = null
  ativo = true;
  editando = false;
  carregando = false;
  date: Date

  lancamento: Lancamento;

  constructor(
    private utils: UtilitariosService,
    private formBuilder: FormBuilder,
    private lancamentoService: LancamentoService,
    public dialog: MatDialog,
    private appRef: ApplicationRef,
    public datepipe: DatePipe,
    private http: HttpClient,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      dataVencimento: [new Date(), Validators.required],
      dataPagamento: [new Date()],
      observacao: [null, Validators.required],
      descricao: [null, Validators.required],
      tipoLancamento: [null],
      aluno: [null],
      imagens: [null],
      links: [null]
    });



    if (!this.data) {
      if (this.utils.getDadosLocalStorage(this.constructor.name)) {
        const dadosRecuperados = this.utils.getDadosLocalStorage(this.constructor.name)
        this.utils.preencherFormGroup(this.form, dadosRecuperados);
        this.form.get('id').setValue(null);
        this.form.get('imagens').setValue(null);
      }
    }

    if (this.data) {
      this.carregando = true;
      forkJoin([
        // this.alunoService.findAllAlunos,
        this.lancamentoService.findById(this.data)
      ])
        .pipe(takeWhile(() => this.ativo))
        .subscribe(
          ([lancamento]) => {
            // this.alunos = alunos
            this.form.get('id').setValue(lancamento.id)
            this.form.get('dataVencimento').setValue(lancamento.dataVencimento)
            this.form.get('dataPagamento').setValue(lancamento.dataPagamento)
            this.form.get('observacao').setValue(lancamento.observacao)
            this.form.get('descricao').setValue(lancamento.descricao)
            this.form.get('tipoLancamento').setValue(lancamento.tipoLancamento)
            this.form.get('aluno').setValue(lancamento.aluno)
            lancamento.imagens.forEach((imagem, index) => {
              this.urlToFile(imagem, 'image_' + (index + 1))
                .then(file => {
                  const imagens: ImagePreview[] = this.form.get('imagens').value || []
                  const urlLink = this.objectToString(imagem);
                  imagens.push({ file, preview: urlLink.toString() })
                  this.form.get('imagens').setValue(imagens)
                })
            })
            this.appRef.tick()
            const interval = setInterval(() => {
              const imagens: ImagePreview[] = this.form.get('imagens').value || []
              if (lancamento.imagens.length === imagens.length) {
                this.carregando = false;
                clearInterval(interval)
              }
            }, 300)
            this.carregando = false;
          }, err => {
            this.carregando = false;
            throw err;
          }
        );
    } else {
      this.form.valueChanges
        .pipe(
          takeWhile(() => this.ativo),
          debounceTime(2000),
        )
        .subscribe(value => {
          if (!this.id) {
            this.utils.setDadosLocalStorage(this.constructor.name, value);
          }
        });
    }
  }

  private objectToString(imagem: string) {
    return imagem.link;
  }

  async salvar() {
    if (this.form.valid) {
      this.carregando = true;
      const lancamento = this.lancamento || new Lancamento();
      lancamento.id = this.form.get('id').value
      lancamento.dataVencimento = this.stringToDate(this.form.get('dataVencimento').value)
      lancamento.dataPagamento = this.stringToDate(this.form.get('dataPagamento').value)
      lancamento.observacao = this.form.get('observacao').value
      lancamento.descricao = this.form.get('descricao').value
      lancamento.tipoLancamento = this.form.get('tipoLancamento').value
      lancamento.aluno = this.form.get('aluno').value
      const images: ImagePreview[] = this.form.get('imagens').value
      if (images && images.length > 0) {
        const arquivos: Arquivo<any>[] = []
        for (const imagem of images) {
          if (imagem && imagem.preview.includes('base64')) {
            arquivos.push(Arquivo.createFile(imagem.file))
          } else if (imagem && imagem.preview) {
            arquivos.push(Arquivo.createString(imagem.preview))
          }
        }
        lancamento.imagens = arquivos
      }

      this.lancamentoService.save(lancamento).pipe(takeWhile(() => this.ativo)).subscribe(
        () => {
          this.carregando = false;
          this.alertaSucesso();
          if (this.form.get('id').value) {
            this.voltar();
          } else {
            this.reset();
          }
        },
        (err) => {
          this.carregando = false;
          try {
            this.alertaError();
          } catch (error) {
            //erro
          }
        },
      )
    } else {
      this.form.markAsTouched();
    }
  }

  voltar() {
    this.dialog.closeAll();
  }

  adicionarImagens(imagens: { link: string }[]) {
    imagens.forEach((imagem, index) => {
      const images: ImagePreview[] = this.form.get('imagens').value || []
      images.push({ file: null, preview: imagem.link })
      this.form.get('imagens').setValue(images)
    })
  }

  deleteImage({ preview }: ImagePreview) {
    if (!preview.includes('base64')) {
      this.carregando = true
      this.lancamentoService.removerImagem(this.data, preview)
        .subscribe(() => {
          this.carregando = false
        }, e => {
          this.carregando = false
          throw e;
        })
    }
  }
  // TODO: CRIAR SERVICO PARA ACHAR AS IMAGENS OU JÃ TRAZER

  hasImageToSave() {
    const images: ImagePreview[] = this.form.get('imagens').value || []
    const newImageFiles = images
      .filter(image => image.file && image.preview.includes('base64'))
      .map(image => image.file)
    return newImageFiles.length > 0
  }

  stringToDate(value) {
    if (!value)
      return null;

    let convertDate = this.datepipe.transform(value, 'dd/MM/yyyy');
    let dataString = '';
    const itens = convertDate.split(' ');
    let data: string[] = null;
    if (itens && itens.length > 0) {
      data = itens[0].split('/');
      if (data.length < 3)
        return null;
      else
        dataString = data[2] + '-' + data[1] + '-' + data[0];
    }
    if (itens && itens.length > 1)
      dataString += ' ' + itens[1];
    if (!moment(new Date(dataString)).isValid())
      return null;
    return moment(new Date(dataString)).toDate();
  }

  async urlToFile(url: string, fileName: string) {
    const urlLink = this.objectToString(url);
    let response = await fetch(urlLink.toString());
    let data = await response.blob();
    return new File([data], fileName);
  }

  reset() {
    this.form.reset()
    this.form.markAsUntouched();
    this.form.markAsPending();
    this.form.markAsPristine();
    this.carregando = false;
    this.utils.removeDadosLocalStorage(this.constructor.name);
  }

  alertaSucesso() {
    Swal.fire({
      title: 'Lancamento salvo com sucesso!',
      type: 'success',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaError() {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      html: 'O servidor encontrou um possível erro, verifique:<br> 1. Imagem maior que 5MB.<br> 2. Problema na conexão.',
    });
  }
}
