import { Response } from './../model/respose.model';
import { HttpClient } from '@angular/common/http';
import { ContasReceber } from './../model/receber.model';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReceberService {

  constructor(private http: HttpClient) { }

  createOrUpdate(contasReceber: ContasReceber) {
    if (contasReceber.id != null && contasReceber.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/receber`, contasReceber);
    } else {
      contasReceber.id = null;
      return this.http.post(`${environment.apiUrl}/api/receber`, contasReceber);
    }
  }
  findAll(page: number, count: number) {
    return this.http.get(`${environment.apiUrl}/api/receber/${page}/${count}`);
  }

  findAllReceber(): Observable<ContasReceber[]> {
    return this.http.get<ContasReceber[]>(`${environment.apiUrl}/api/receber/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/receber/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/receber/${id}`);
  }
}
