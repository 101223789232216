<mat-card>
  <mat-card-title>
    <mat-toolbar>
      <mat-toolbar-row>
        <span>Lições</span>
      </mat-toolbar-row>
        <mat-toolbar-row>
          <h4 class="pt-2 mb-0">Para filtrar uma lição clique em pesquisar.</h4>
        </mat-toolbar-row>
    </mat-toolbar>
  </mat-card-title>
  <mat-card-content>
    <form #f [formGroup]="formulario" autocomplete="off">
      <div class="row">
        <div class="col-12 form-group">
          <mat-form-field class="filter" class="col-sm-12">
            <mat-label for="tema">Pesquisar...</mat-label>
            <input
              matInput
              id="tema"
              name="tema"
              formControlName="pesquisar"
              placeholder="Pesquisar..."
            />
            <button mat-button matSuffix mat-icon-button aria-label="Clear">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <mat-card-actions>
        <button
          (click)="pesquisar()"
          mat-raised-button

          type="button"
        >
          Pesquisar
        </button>
        <button
          (click)="criar()"
          mat-raised-button
          color="warn"
          type="button"
        >
          Cadastrar Novo
        </button>
      </mat-card-actions>
    </form>
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource">
        <caption>
          New York City Marathon Results 2013
        </caption>

        <ng-container matColumnDef="data">
          <th mat-header-cell *matHeaderCellDef>Data</th>
          <td mat-cell *matCellDef="let element">{{ element?.data | ddMmYyyyDate }}</td>
        </ng-container>

        <ng-container matColumnDef="turma">
          <th mat-header-cell *matHeaderCellDef>Turma</th>
          <td mat-cell *matCellDef="let element">{{ element?.turma?.nome }}</td>
        </ng-container>
        <ng-container matColumnDef="tema">
          <th mat-header-cell *matHeaderCellDef>Tema</th>
          <td mat-cell *matCellDef="let element">{{ element?.tema }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="!element?.experienciaPedagogica" class="icon-inline">
              <mat-icon
                class="icon-space"
                matTooltip="Pendente"
                style="color: orange"
              >
                schedule
              </mat-icon>
              <span>Pendente</span>
            </div>
            <div *ngIf="element?.experienciaPedagogica" class="icon-inline">
              <mat-icon
                class="icon-space"
                matTooltip="Aplicada em sala"
                style="color: #75e900"
              >
                check_circle_outline</mat-icon>
                <span>Aplicado em sala</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="editar">
          <th mat-header-cell *matHeaderCellDef style="width: 10px">Editar</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              (click)="alterar(element.id)"
              matTooltip="Editar"
              matTooltipClass="example-tooltip-red"
              aria-label="Button that shows a red tooltip"
            >
              <mat-icon style="color: #0013e9">edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="ativar">
          <th mat-header-cell *matHeaderCellDef style="width: 10px">Ativar</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element?.status === false">
              <button
                mat-icon-button
                (click)="desativarLicao(element.id)"
                matTooltip="Inativar"
                matTooltipClass="example-tooltip-red"
                aria-label="Button that shows a red tooltip"
              >
                <mat-icon style="color: #e64a19">visibility_off</mat-icon>
              </button>
            </div>
            <div *ngIf="element?.status === true">
              <button
                mat-icon-button
                (click)="ativarLicao(element.id)"
                matTooltip="Ativar"
                matTooltipClass="example-tooltip-red"
                aria-label="Button that shows a red tooltip"
              >
                <mat-icon style="color: green">visibility</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="deletar">
          <th mat-header-cell *matHeaderCellDef style="width: 10px">Deletar</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              (click)="deletar(element.id)"
              matTooltip="Deletar"
            >
              <mat-icon style="color: #e64a19">delete_outline</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">Nada encontrado com esse filtro</td>
        </tr>
      </table>
      <app-paginator (eventChange)="pesquisar(false)"> </app-paginator>

      <mat-card
        *ngIf="carregando"
        style="display: flex; justify-content: center; align-items: center"
      >
        <mat-progress-spinner color="warn" mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>
