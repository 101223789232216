<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-info">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Novo Nível</mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">
          <div class="row">
            <div class="form-group">
              <div class="col-sm-12">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="nome">Nome</mat-label>
                  <input matInput placeholder="Nome" id="nome" formControlName="nome" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Nome é obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button mat-raised-button color="primary">Confirmar</button>
          </div>
          <!--app-form-debug [formularioDebug]="form"> </app-form-debug-->
        </form>
      </div>
    </div>
  </div>
</div>
