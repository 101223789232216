import { Response } from './../model/respose.model';
import { Matricula } from './../model/matricula.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';
import { Aluno } from '../model/aluno.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MatriculaService {

  constructor(private http: HttpClient) { }

  createOrUpdate(matricula: Matricula) {
    if (matricula.id != null && matricula.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/matricula`, matricula);
    } else {
      matricula.id = null;
      return this.http.post(`${environment.apiUrl}/api/matricula`, matricula);
    }
  }

  findAll(page: number, count: number) {
    return this.http.get(`${environment.apiUrl}/api/matricula/${page}/${count}`);
  }

  findAllMatricula() {
    return this.http.get<Matricula[]>(`${environment.apiUrl}/api/matricula/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/matricula/${id}`);
  }
  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/matricula/${id}`);
  }

  findByTurmaId(idTurma: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/matricula/byTurmaId/${idTurma}`);
  }

  findByAlunoId(idAluno: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/matricula/byAlunoId/${idAluno}`);
  }

  pesquisar(aluno: String) {
    return this.http.get<Response>(`${environment.apiUrl}/api/matricula/byName/${aluno}`);
  }

  pesquisarByName(aluno: String) {
    return this.http.get<Matricula[]>(`${environment.apiUrl}/api/matricula/byNameMatricula/${aluno}`);
  }

  ativarMatricula(id: string) {
    return this.http.post(`${environment.apiUrl}/api/matricula/ativar`, id);
  }

  desativarMatricula(desativar: string) {
    return this.http.post(`${environment.apiUrl}/api/matricula/desativar`, desativar);
  }

  findByMatricula(aluno: Aluno) {
    return this.http.get<Response>(`${environment.apiUrl}/api/matricula/${aluno}`);
  }

}
