import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CursoService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(curso) {
        if (curso.id != null && curso.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/curso`, curso);
        }
        else {
            curso.id = null;
            return this.http.post(`${environment.apiUrl}/api/curso`, curso);
        }
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/curso/${page}/${count}`);
    }
    findAllCurso() {
        return this.http.get(`${environment.apiUrl}/api/curso/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/curso/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/curso/${id}`);
    }
}
CursoService.ngInjectableDef = i0.defineInjectable({ factory: function CursoService_Factory() { return new CursoService(i0.inject(i1.HttpClient)); }, token: CursoService, providedIn: "root" });
