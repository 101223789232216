

export class Turma {
  constructor(
    public id: string,
    public nome: string,
    public professoresId: string[],
    public ano: string,
    public semestre: string,
    public status: boolean,
    public escolaDeMusica: boolean
  ) {}
}
