import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImagesLancamentoComponent } from '../../lancamento/images-lancamento/images-lancamento.component';

export interface ImagePreview {
  file: File,
  preview: string
}
@Component({
  selector: 'app-licao-imagens',
  templateUrl: './licao-imagens.component.html',
  styleUrls: ['./licao-imagens.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LicaoImagensComponent),
    multi: true,
  }]
})
export class LicaoImagensComponent implements ControlValueAccessor {


  images: ImagePreview[] = [];

  @Input()
  label: string;

  @Input()
  limit: number;

  @Output()
  onImagesAdded: EventEmitter<ImagePreview[]> = new EventEmitter<ImagePreview[]>()

  @Output()
  onImageDeleted: EventEmitter<ImagePreview> = new EventEmitter<ImagePreview>()



  onChange: any = () => {}
  onTouch: any = () => {}


  writeValue(value: ImagePreview[]): void {
    this.images = []
    if (value && value.length > 0) {
      this.writeImagePreviewListValue(value)
    }
  }
  writeImagePreviewListValue(value: ImagePreview[]) {
    for (const image of value) {
      this.addImage(image);
    }
  }

  addImage(imagePreview: ImagePreview) {
    this.images.push(imagePreview)
    if (!imagePreview.preview) {
      this.loadPreview(imagePreview.file)
    }
  }

  loadPreview(file: File) {
    this.fileToSrc(file)
      .then(preview => {
        this.images = this.images.map(image => {
          if (image.file === file) {
            return { file, preview }
          } else {
            return image
          }
        })
        this.onChange(this.images)
      })
      .catch(() => {
        this.images = this.images.filter(image => image.file !== file)
      })
  }

  fileToSrc(file: File): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const binaryData = e.target.result;
        if (binaryData) {
          resolve(binaryData);
        } else {
          reject('no data')
        }
      };
      reader.onerror = error => reject(error);
    });
  }



  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  removeImage(image: ImagePreview) {
    this.writeValue(this.images.filter(i => i !== image))
    this.onChange(this.images.filter(i => i !== image))
    this.onImageDeleted.emit(image)
  }

  outOfLimit(newImages?: any[]) {
    const images = [...this.images, ...(newImages || [])]
    return this.limit && images.length >= this.limit
  }

  onChangeImage(event) {
    const fileList: FileList | null = event.target.files
    const newImages = []
    for (let i = 0; i < fileList.length; i++) {
      if (this.outOfLimit(newImages)) {
        break;
      }
      newImages.push({ file: fileList.item(i), preview: undefined })
    }
    this.onImagesAdded.emit(newImages)
    this.writeValue([...this.images, ...newImages])
  }

  openInput(element) {
    if (element) {
      element.click()
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

}
