import { Curso } from './../../model/curso.model';
import { Router } from '@angular/router';
import { DialogService } from './../../../dialog.service';
import { CursoService } from './../../services/curso.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ResponseApi } from '../../model/response-api';

@Component({
  selector: 'app-curso-list',
  templateUrl: './curso-list.component.html',
  styleUrls: ['./curso-list.component.css']
})
export class CursoListComponent implements OnInit {
  page = 0;
  count = 5;
  pages: Array<number>;
  shared: SharedService;
  message: {};
  classCss: {};
  listCurso = [];

  constructor(
    private dialogService: DialogService,
    private cursoService: CursoService,
    private router: Router
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.findAll(this.page, this.count);
  }

  findAll(page: number, count: number) {
    this.cursoService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.listCurso = responseApi['data']['content'];
        this.pages = new Array(responseApi['data']['totalPages']);
      },
      err => {
        this.showMessage({
          type: 'error',
          text: err['error']['errors'][0]
        });
      }
    );
  }

  edit(id: string) {
    this.router.navigate(['/curso-new', id]);
  }

  delete(id: string) {
    this.dialogService
      .confirm('Você deseja desativar este Curso ?')
      .then((canDelete: boolean) => {
        if (canDelete) {
          this.message = {};
          this.cursoService.delete(id).subscribe(
            (responseApi: ResponseApi) => {
              this.showMessage({
                type: 'success',
                text: 'Registro desativado'
              });
              this.findAll(this.page, this.count);
            },
            err => {
              this.showMessage({
                type: 'error',
                text: err['error']['errors'][0]
              });
            }
          );
        }
      });
  }

  private showMessage(message: { type: string; text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      alert: true
    };
    this.classCss['alert-' + type] = true;
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }
}
