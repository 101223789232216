/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-relatorio.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../node_modules/@angular/material/card/typings/index.ngfactory";
import * as i4 from "@angular/material/card";
import * as i5 from "@angular/material/core";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./menu-relatorio.component";
import * as i9 from "@angular/router";
var styles_MenuRelatorioComponent = [i0.styles];
var RenderType_MenuRelatorioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuRelatorioComponent, data: {} });
export { RenderType_MenuRelatorioComponent as RenderType_MenuRelatorioComponent };
export function View_MenuRelatorioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Selecione:"])), (_l()(), i1.ɵeld(3, 0, null, null, 25, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 5, "mat-card", [["class", "mat-card mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resumoFrequencia() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.fecharDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(6, 49152, null, 0, i4.MatCard, [], null, null), i1.ɵdid(7, 212992, null, 0, i5.MatRipple, [i1.ElementRef, i1.NgZone, i6.Platform, [2, i5.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i7.ANIMATION_MODULE_TYPE]], { unbounded: [0, "unbounded"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i4.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Resumo de frequ\u00EAncias"])), (_l()(), i1.ɵeld(11, 0, null, null, 5, "mat-card", [["class", "mat-card mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.porTurma() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.fecharDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(12, 49152, null, 0, i4.MatCard, [], null, null), i1.ɵdid(13, 212992, null, 0, i5.MatRipple, [i1.ElementRef, i1.NgZone, i6.Platform, [2, i5.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i7.ANIMATION_MODULE_TYPE]], { unbounded: [0, "unbounded"], centered: [1, "centered"] }, null), (_l()(), i1.ɵeld(14, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(15, 16384, null, 0, i4.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Relat\u00F3rio por turma"])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "mat-card", [["class", "mat-card mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.porAluno() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.fecharDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(18, 49152, null, 0, i4.MatCard, [], null, null), i1.ɵdid(19, 212992, null, 0, i5.MatRipple, [i1.ElementRef, i1.NgZone, i6.Platform, [2, i5.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i7.ANIMATION_MODULE_TYPE]], { unbounded: [0, "unbounded"], centered: [1, "centered"] }, null), (_l()(), i1.ɵeld(20, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(21, 16384, null, 0, i4.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Relat\u00F3rio de Frequ\u00EAncia por aluno"])), (_l()(), i1.ɵeld(23, 0, null, null, 5, "mat-card", [["class", "mat-card mat-ripple"], ["matRipple", ""]], [[2, "mat-ripple-unbounded", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.aniversariantes() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.fecharDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatCard_0, i3.RenderType_MatCard)), i1.ɵdid(24, 49152, null, 0, i4.MatCard, [], null, null), i1.ɵdid(25, 212992, null, 0, i5.MatRipple, [i1.ElementRef, i1.NgZone, i6.Platform, [2, i5.MAT_RIPPLE_GLOBAL_OPTIONS], [2, i7.ANIMATION_MODULE_TYPE]], { unbounded: [0, "unbounded"], centered: [1, "centered"] }, null), (_l()(), i1.ɵeld(26, 0, null, 0, 2, "mat-card-title", [["class", "mat-card-title"]], null, null, null, null, null)), i1.ɵdid(27, 16384, null, 0, i4.MatCardTitle, [], null, null), (_l()(), i1.ɵted(-1, null, ["Aniversariantes"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = false; _ck(_v, 7, 0, currVal_2); var currVal_4 = false; var currVal_5 = false; _ck(_v, 13, 0, currVal_4, currVal_5); var currVal_7 = false; var currVal_8 = false; _ck(_v, 19, 0, currVal_7, currVal_8); var currVal_10 = false; var currVal_11 = false; _ck(_v, 25, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).unbounded; _ck(_v, 5, 0, currVal_1); var currVal_3 = i1.ɵnov(_v, 13).unbounded; _ck(_v, 11, 0, currVal_3); var currVal_6 = i1.ɵnov(_v, 19).unbounded; _ck(_v, 17, 0, currVal_6); var currVal_9 = i1.ɵnov(_v, 25).unbounded; _ck(_v, 23, 0, currVal_9); }); }
export function View_MenuRelatorioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu-relatorio", [], null, null, null, View_MenuRelatorioComponent_0, RenderType_MenuRelatorioComponent)), i1.ɵdid(1, 114688, null, 0, i8.MenuRelatorioComponent, [i9.Router, i2.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuRelatorioComponentNgFactory = i1.ɵccf("app-menu-relatorio", i8.MenuRelatorioComponent, View_MenuRelatorioComponent_Host_0, {}, {}, []);
export { MenuRelatorioComponentNgFactory as MenuRelatorioComponentNgFactory };
