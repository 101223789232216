<div class="container-fluid">
  <div class="row">

    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">

      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Lista de Usuários</mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <form (ngSubmit)="pesquisar()">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-10">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="email">Pesquisar...</mat-label>
                    <input matInput placeholder="Digite o nome" id="email" name="email" [(ngModel)]="email">
                    <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                    <mat-error>Nome é obrigatório</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="buttons">
              <button mat-raised-button color="primary">Pesquisar</button>
            </div>
          </form>
          <div class="box-body" class="table-responsive">
            <div *ngIf="!listUser || listUser.length <=0">
              <img class="imagem" src="../../../assets/image/NaoEncontrado.png">
              <p>Nada foi encontrado!</p>
            </div>
            <table class="table table-bordered" *ngIf="listUser && listUser.length > 0">
              <tr>
                <th>E-mail</th>
                <th>Perfil</th>
                <th>Nome</th>
                <th>Status</th>
                <th style="width: 10px">Ações</th>
                <th style="width: 10px">&nbsp;</th>
                <th style="width: 10px">&nbsp;</th>
              </tr>
              <tr *ngFor="let user of listUser">
                <td>{{user.email}}</td>
                <td>
                  <p *ngIf="user.profile === 'ROLE_ADMIN'">Administrador</p>
                  <p *ngIf="user.profile == 'ROLE_PROFESSOR'">Professor(a)</p>
                  <p *ngIf="user.profile == 'ROLE_DIRETOR'">Diretor(a)</p>
                  <p *ngIf="user.profile == 'ROLE_PASTOR'">Pastor</p>
                </td>
                <td> {{ user.nome }} </td>
                <td>
                  <mat-icon *ngIf="user.status === false" matTooltip="Ativo" style="color:green">done_outline
                  </mat-icon>
                  <mat-icon *ngIf="user.status === true" matTooltip="Inativo" style="color:#E64A19">
                    person_add_disabled</mat-icon>
                </td>
                <td *ngIf="shared.user.profile !== 'PROFESSOR'">
                  <mat-button-toggle (click)="edit(user.id)" matTooltip="Editar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #0013e9">edit</mat-icon>
                  </mat-button-toggle>
                </td>
                <td>
                  <mat-button-toggle *ngIf="user.status === false" (click)="desativarUsuario(user.id)"
                    matTooltip="Inativar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">visibility</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle *ngIf="user.status === true" (click)="ativarUsuario(user.id)" matTooltip="Ativar"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: green">visibility_off</mat-icon>
                  </mat-button-toggle>
                </td>
                <td *ngIf="shared.user.profile === 'ADMIN'">
                  <mat-button-toggle matTooltip="Excluir" (click)="delete(user.id)"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">delete_outline</mat-icon>
                  </mat-button-toggle>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- PAGINAÇÃO -->
        <div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div>
        <!-- CARD DE LEGENDAS-->
        <div class="row"  *ngIf="listUser && listUser.length > 0">
          <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
            <mat-card class="info">
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                        <mat-icon matTooltip="Ativo" style="color:green">done_outline
                          </mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ativo</td>
                  </tr>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                        <mat-icon matTooltip="Inativo" style="color:#E64A19">
                            person_add_disabled</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inativo</td>
                  </tr>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--div [ngClass]="classCss" role="alert" *ngIf="message">
  <strong>{{ message.text }}</strong>
</div-->
<!--div class="row">
  <div class="col-md-8">
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Lista de Usuários</h3>
      </div>

      <div class="box-body">
        <table class="table table-bordered">
          <tr>
            <th>Email</th>
            <th>Perfil</th>
            <th style="width: 10px">&nbsp;</th>
            <th style="width: 10px">&nbsp;</th>
          </tr>
          <tr *ngFor="let user of listUser">
            <td>{{user.email}}</td>
            <td>{{user.profile}}</td>
            <td><button class="btn btn-primary" (click)="edit(user.id)">Editar</button></td>
            <td><button class="btn btn-danger" (click)="delete(user.id)">Desativar</button></td>
            <td></td>

          </tr>
        </table>
      </div>

      <div class="box-footer clearfix">
        <ul class="pagination pagination-sm no-margin pull-right">
          <li>
            <a href="" (click)="setPreviousPage($event)">&laquo;</a>
          </li>
          <li *ngFor="let p of pages; let i=index">
            <a [ngClass]="{'pagination-focus': i == page}" href=""(click)="setPage(i, $event)">{{i+1}}</a>
          </li>
          <li>
            <a href="" (click)="setNextPage($event)">&raquo;</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div-->
