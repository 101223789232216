import { CursoNivel } from './../../model/curso-nivel.model';
import { CursoNivelService } from './../../services/curso-nivel.service';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { DialogService } from '../../../dialog.service';
import { Router } from '@angular/router';
import { ResponseApi } from '../../model/response-api';

@Component({
  selector: 'app-curso-nivel-list',
  templateUrl: './curso-nivel-list.component.html',
  styleUrls: ['./curso-nivel-list.component.css']
})
export class CursoNivelListComponent implements OnInit {

  page = 0;
  count = 5;
  pages: Array<number>;
  shared: SharedService;
  listNivel = [];
  message: {};
  classCss: {};
  cursoNivelFilter = new CursoNivel('', '');

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private cursoNivelService: CursoNivelService
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.findAll(this.page, this.count);
  }

  findAll(page: number, count: number) {
    this.cursoNivelService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.listNivel = responseApi['data']['content'];
        this.pages = new Array(responseApi['data']['totalPages']);
      },
      err => {
        this.showMessage({
          type: 'error',
          text: err['error']['errors'][0]
        });
      }
    );
  }

  edit(id: string) {
    this.router.navigate(['/curso-nivel-new', id]);
  }

  delete(id: string) {
    this.dialogService
      .confirm('Você deseja desativar este Nivel de Curso ?')
      .then((canDelete: boolean) => {
        if (canDelete) {
          this.message = {};
          this.cursoNivelService.delete(id).subscribe(
            (responseApi: ResponseApi) => {
              this.showMessage({
                type: 'success',
                text: 'Registro desativado'
              });
              this.findAll(this.page, this.count);
            },
            err => {
              this.showMessage({
                type: 'error',
                text: err['error']['errors'][0]
              });
            }
          );
        }
      });
  }

  filter(): void {
    this.page = 0;
    this.count = 5;
    this.cursoNivelService.findByNome(this.cursoNivelFilter).subscribe((responseApi: ResponseApi) => {
      this.cursoNivelFilter.nome = this.cursoNivelFilter.nome === 'uninformed' ? '' : this.cursoNivelFilter.nome;
      this.listNivel = responseApi['data']['content'];
      this.pages = new Array(responseApi['data']['totalPages']);
    }, err => {
      this.showMessage({
        type: 'error',
        text: err['error']['errors'][0]
      });
    });
  }

  private showMessage(message: { type: string; text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      alert: true
    };
    this.classCss['alert-' + type] = true;
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }

}
