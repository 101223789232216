<h2 mat-dialog-title><span>Nova Lição</span></h2>
<mat-progress-bar mode="buffer" *ngIf="carregando == true"></mat-progress-bar>
<div class="overlay" *ngIf="carregando">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<mat-dialog-content class="mat-typography">
  <form #f [formGroup]="form" #f="ngForm">
    <div class="row">
      <div class="form-group">
        <div class="col-sm-3">
          <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
            <input matInput placeholder="Data da Lição" id="data" formControlName="data"
              [matDatepicker]="data" readonly="true" required>
            <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
            <mat-datepicker touchUi #data></mat-datepicker>
            <mat-error>Data é obrigatória</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-4 loading-container">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="turma">Turma</mat-label>
            <mat-select
            placeholder="Turma"
            id="turma"
            formControlName="turma"
            required
            [compareWith]="compareTurma"
            (selectionChange)="onTurmaSelected($event.value)">
              <mat-option *ngFor="let turma of turmas | async" [value]="turma"> {{ turma?.nome }}
              </mat-option>
            </mat-select>
            <mat-error>Turma é obrigatório</mat-error>
          </mat-form-field>
          <mat-progress-spinner *ngIf="carregando" class="loading-spinner" mode="indeterminate"></mat-progress-spinner>
        </div>

        <div class="col-sm-5">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="professor">Professor</mat-label>
            <mat-select
            placeholder="Professor"
            id="professor"
            formControlName="professor"
            required
            [compareWith]="compareProfessor"
            >
              <mat-option *ngFor="let professor of professores | async" [value]="professor">
                {{ professor?.nome }}
              </mat-option>
            </mat-select>
            <mat-error>Professor(a) é obrigatório</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="tema">Tema</mat-label>
            <input matInput placeholder="Tema" id="tema" formControlName="tema" required>
            <mat-error>Tema é obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="objetivo">Objetivos:</mat-label>
            <input matInput placeholder="Objetivos gerais e específicos" id="objetivo" formControlName="objetivo"
              required>
            <mat-error>Objetivo é obrigatório</mat-error>
          </mat-form-field>
        </div>
        <div class="editor">
          <div class="col-sm-12">
            <mat-label for="conteudo">Conteúdo</mat-label>
            <ckeditor [config]="editorConfig" id="conteudo" style="height: 300px;" formControlName="conteudo" formControlName="conteudo" [editor]="Editor" (ready)="onReady($event)" [(ngModel)]="editorContent"></ckeditor>
            <mat-error *ngIf="form.get('conteudo').invalid">O Conteúdo é obrigatório</mat-error>
          </div>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="col-sm-12">
            <mat-label for="metodologia">Metodologia</mat-label>
            <input matInput placeholder="Metodologia" id="metodologia" formControlName="metodologia" required>
            <mat-error>Metodologia é obrigatória</mat-error>
          </mat-form-field>
        </div>

        <div class="col-sm-12">
          <mat-form-field appearance="fill" class="col-sm-12">
            <mat-label for="experienciaPedagogica">Experiência Pedagógica</mat-label>
            <textarea matInput placeholder="Experiência pedagógica" id="experienciaPedagogica"
              formControlName="experienciaPedagogica" rows="5" cols="40"> </textarea>
          </mat-form-field>
        </div>
      </div>
    </div>

    <app-licao-imagens formControlName="imagens" (onImageDeleted)="deleteImage($event)"></app-licao-imagens>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-raised-button color="warn" (click)="salvar()" cdkFocusInitial>Salvar</button>
</mat-dialog-actions>
