import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatInputModule, MatIconModule, MatCardModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatDialogModule, MatProgressSpinnerModule, MatProgressBarModule, MatButtonToggleModule, MatTableModule, MatTabsModule, MatSortModule, MatToolbarModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { NgxPrintModule } from 'ngx-print';
import { AniversariantesComponent } from './aniversariantes.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatTableModule,
    MatTabsModule,
    MatSortModule,
    // NgxPrintModule,
    MatToolbarModule
  ],
  exports: [AniversariantesComponent],
  declarations: [AniversariantesComponent]
})
export class AniversariantesModule { }
