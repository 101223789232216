<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-info">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Contas a Pagar / Receber</mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">

          <div class="buttons">
            <button type="button" mat-stroked-button color="primary" (click)="show = !show">Incluir</button>
          </div>

          <div class="row" *ngIf="show">

            <div class="form-group">
              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="valor">Valor</mat-label>
                  <input matInput placeholder="R$" id="valor" type="number" formControlName="valor" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Valor é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                  <input matInput placeholder="Data de Vencimento" id="vencimento" formControlName="vencimento"
                    [matDatepicker]="vencimento" required>
                  <mat-datepicker-toggle matSuffix [for]="vencimento"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #vencimento></mat-datepicker>
                  <mat-error>Data de vencimento é obrigatória</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="historico">Histórico</mat-label>
                  <input matInput placeholder="historico" id="historico" formControlName="historico" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Histórico é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="status">Status</mat-label>
                  <mat-select placeholder="status" id="status" formControlName="status" required>
                    <mat-option *ngFor="let status of tipoStatus" [value]="status.key">{{status.value}}</mat-option>
                  </mat-select>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->

                </mat-form-field>

              </div>
            </div>

            <div class="form-group">

              <div class="col-sm-10">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="nome">Aluno</mat-label>
                  <mat-select placeholder="Aluno" id="aluno" formControlName="aluno" required>
                    <mat-option *ngFor="let aluno of pessoas | async" [value]="aluno">{{aluno.nome}}</mat-option>
                  </mat-select>
                  <mat-error>Aluno é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-2">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="tipo">Tipo</mat-label>
                  <mat-select placeholder="tipo" id="tipo" formControlName="tipo" required>
                    <mat-option *ngFor="let tipo of tipoConta" [value]="tipo.key">{{tipo.value}}</mat-option>
                  </mat-select>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                </mat-form-field>
              </div>

            </div>

            <div class="buttons2">
              <button mat-raised-button color="primary">Confirmar</button>
            </div>
          </div>
        </form>

      </div>
      <!-- outra tabela de caixa -->
      <div class="row">
        <div class="col-md-12">
          <div class="box">
            <div class="box-body">
              <table class="table table-bordered">
                <tr>

                  <th>Valor</th>

                  <th>Histórico </th>
                  <th>Vencimento</th>
                  <th>Status</th>
                  <th>Tipo</th>
                  <th>Aluno / Responsável</th>
                  <th style="width: 10px">&nbsp;</th>
                  <th style="width: 10px">&nbsp;</th>
                </tr>
                <tr *ngFor="let pagar of pagarList">

                  <td>{{pagar.valor | currency: 'BRL'}}</td>
                  <td>{{pagar.historico}}</td>
                  <td>{{ pagar.vencimento | date }}</td>
                  <td>{{pagar.status}}</td>
                  <td>{{pagar.tipo}}</td>
                  <td>{{pagar.aluno?.nome}}</td>
                  <td>
                    <mat-button-toggle *ngIf="pagar.status === 'EM_ABERTO'"  (click)="quitar(pagar)" value="quitar"
                    matTooltip="Quitar">
                      <mat-icon style="color: #0D47A1">border_color</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle *ngIf="pagar.status === 'PAGO'"   value="rollback"
                    matTooltip="Pago">
                      <mat-icon style="color: #4CAF50">check_circle</mat-icon>
                    </mat-button-toggle>
                  </td>
                  <td>
                    <mat-button-toggle (click)="delete(pagar.id)" value="desativar"
                    matTooltip="Excluir"
                    matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip"
                    >
                      <mat-icon style="color: #E64A19">delete</mat-icon>
                    </mat-button-toggle>
                  </td>
                  <!--td><button class="btn btn-primary" (click)="edit(aluno.id)">Editar</button></td>
                  <td><button class="btn btn-danger" (click)="delete(aluno.id)">Desativar</button></td>
                  <td></td> -->

                </tr>
              </table>
            </div>
            <!-- PAGINAÇÃO -->
            <div class="box-footer clearfix">
              <ul class="pagination pagination-sm no-margin pull-right">
                <li>
                  <a href="" (click)="setPreviousPage($event)">&laquo;</a>
                </li>
                <li *ngFor="let p of pages; let i=index">
                  <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
                </li>
                <li>
                  <a href="" (click)="setNextPage($event)">&raquo;</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--app-form-debug [formularioDebug]="form"></app-form-debug-->
</div>
