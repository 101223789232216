<div class="container-fluid" class="noPrint">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white" *ngIf="this.shared.user.profile === 'PROFESSOR'">Lista de Lições
              {{ listLicao[0]?.turma?.nome}}</mat-card-title>
            <mat-card-title style="color: white" *ngIf="this.shared.user.profile !== 'PROFESSOR'">Lista de Lições
            </mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <form (ngSubmit)="pesquisar()" autocomplete="off">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-10">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="tema">Pesquisar...</mat-label>
                    <input matInput placeholder="Digite o tema" id="tema" name="tema" [(ngModel)]="tema">
                    <mat-error>Nome é obrigatório</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="buttons">
              <button mat-raised-button color="primary">Pesquisar</button>
            </div>
          </form>
          <div class="box-body" class="table-responsive">
            <div *ngIf="!listLicao || listLicao.length <=0">
              <p class="box-body">Nenhum registro encontrado!</p>
            </div>
            <table class="table table-bordered" *ngIf="listLicao && listLicao.length > 0">
              <tr>
                <th>Data</th>
                <th>Turma</th>
                <th>Tema</th>
                <th>Status</th>
                <th style="width: 10px">Alterar</th>
                <th style="width: 10px">Ati/Des</th>
                <th style="width: 10px" *ngIf="shared.user.profile === 'ADMIN'">Excluir</th>
                <th style="width: 10px">Imprimir</th>
              </tr>
              <tr *ngFor="let licao of listLicao">

                <td>{{ licao.data | date }}</td>
                <td>{{ licao.turma.nome }}</td>
                <td> {{licao.tema }}</td>
                <td>
                  <mat-icon *ngIf="!licao.experienciaPedagogica" matTooltip="Em aberto" style="color:#0013e9">
                    lock_open
                  </mat-icon>
                  <mat-icon *ngIf="licao.experienciaPedagogica" matTooltip="Aplicada em sala" style="color:#75e900">
                    check_circle_outline</mat-icon>
                </td>
                <td>
                  <mat-button-toggle (click)="edit(licao.id)" matTooltip="Editar" matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #0013e9">edit</mat-icon>
                  </mat-button-toggle>
                </td>
                <td *ngIf="licao?.status === false">
                  <mat-button-toggle (click)="desativarLicao(licao.id)" matTooltip="Inativar"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">visibility</mat-icon>
                  </mat-button-toggle>
                </td>
                <td *ngIf="licao?.status === true">
                  <mat-button-toggle (click)="ativarLicao(licao.id)" matTooltip="Ativar"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: green">visibility_off</mat-icon>
                  </mat-button-toggle>
                </td>
                <td *ngIf="shared.user.profile === 'ADMIN'">
                  <mat-button-toggle (click)="delete(licao.id)" matTooltip="Excluir"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #E64A19">delete_outline</mat-icon>
                  </mat-button-toggle>
                </td>
                <td>
                  <mat-button-toggle (click)="print(licao.id)" matTooltip="Imprimir"
                    matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                    <mat-icon style="color: #000000">print</mat-icon>
                  </mat-button-toggle>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div>
        <div class="row" *ngIf="listLicao && listLicao.length > 0">
          <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
            <mat-card class="info">
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Em aberto" style="color:#0013e9">lock_open</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Em aberto</td>
                  </tr>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Aplicada em sala" style="color:#75e900">check_circle_outline</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aplicada em sala</td>
                  </tr>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>