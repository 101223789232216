import { Response } from './../../model/respose.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { Observable } from 'rxjs';
import { Aluno } from '../../model/aluno.model';
import { AlunoService } from '../../services/aluno.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { ResponseApi } from '../../model/response-api';
import { ContasReceberService } from '../../services/contas-receber.service';

@Component({
  selector: 'app-contas-receber',
  templateUrl: './contas-receber.component.html',
  styleUrls: ['./contas-receber.component.css']
})
export class ContasReceberComponent implements OnInit {

  tipoStatus = [
    { key: 'EM_ABERTO', value: 'Em aberto' },
    { key: 'PAGO', value: 'Pago' }
  ];

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  shared: SharedService;
  message: {};
  classCss: {};
  show: boolean = true;
  receberList = [];
  page = 0;
  count = 5;
  pages: Array<number>;
  pessoas: Observable<Response>;

  constructor(
    private contasReceberService: ContasReceberService,
    private alunoService: AlunoService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.shared = SharedService.getInstance();
   }

  ngOnInit() {
    this.pessoas = this.alunoService.findAllAlunos();

    this.findAll(this.page, this.count);

    this.form = this.formBuilder.group({
      id: [null],
      pessoa: [null, Validators.required],
      vencimento: [null, Validators.required],
      valor: [null, Validators.required],
      historico: [null, Validators.required],
      status: [null, Validators.required]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.contasReceberService.findById(params['id']).subscribe(receber => {
          if (receber && receber.data) {
            console.log(this.form.setValue(receber.data));
            this.form.setValue(receber.data);
          }
        });
      }
    });
  }
  findAll(page: number, count: number) {
    this.contasReceberService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.receberList = responseApi['data']['content'];
        this.pages = new Array(responseApi['data']['totalPages']);
      },
      err => {
        console.log('deu erro');
        /* this.showMessage({
        type: 'error',
        text: err['error']['errors'][0]
      });*/
      }
    );
  }

  register() {
    if (this.form.valid) {
      console.log(this.form.value);
      this.contasReceberService.createOrUpdate(this.form.value).subscribe(
        (responseApi: ResponseApi) => {
          this.resetaForm();
          this.findAll(this.page, this.count);
          this.abrirConfirmacao();
        },
        err => {
          console.log('Deu erro');
        }
      );
    } else {
      console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
    }
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(Confirmacao);

    setTimeout(() => {
      this.dialog.closeAll();
    }, 3000);

    /* dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    }); */
  }
  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confimacao',
  templateUrl: './Confirmacao.html'
})
// tslint:disable-next-line:component-class-suffix
export class Confirmacao {}
