<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-info">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title  style="color: white">Caixa
              <i class="fa fa-cube pull-right"></i></mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">

          <div class="buttons">
            <button type="button" mat-stroked-button color="primary" (click)="show = !show">Incluir</button>
          </div>

          <div class="row" *ngIf="show">
            <div class="form-group">
              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="valor">Valor</mat-label>
                  <input matInput placeholder="R$ 100,00" id="valor" type="number" formControlName="valor" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Valor é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="origem">Origem</mat-label>
                  <input matInput placeholder="origem" id="origem" formControlName="origem" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>origem é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                  <input matInput placeholder="Data de Lançamento" id="data" formControlName="data" [matDatepicker]="data" required>
                  <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #data></mat-datepicker>
                  <mat-error>Data é obrigatória</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="tipo">Tipo</mat-label>
                  <mat-select placeholder="tipo" id="tipo" formControlName="tipo" required>
                    <mat-option *ngFor="let tipo of tipos"  [value]="tipo.key">{{tipo.value}}</mat-option>
                  </mat-select>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>tipo é obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="buttons2">
              <button mat-raised-button color="primary">Confirmar</button>
            </div>
          </div>
        </form>

      </div>
      <!-- outra tabela de caixa -->
      <div class="row">
        <div class="col-md-12">
          <div class="box">
            <div class="box-body">
              <table class="table table-bordered">
                <tr>
                  <th>Data</th>
                  <th>Origem</th>
                  <th>Valor </th>
                  <th>Tipo</th>
                  <th style="width: 10px">&nbsp;</th>

                </tr>
                <tr *ngFor="let caixa of caixaList">
                  <td>{{ caixa.data | date }}</td>
                  <td>{{caixa.origem}}</td>
                  <td>{{ caixa.valor | currency: 'BRL' }}</td>
                  <td>{{caixa.tipo}}</td>
                  <td>
                    <mat-button-toggle (click)="delete(caixa.id)" value="desativar"
                    matTooltip="Excluir"
                    matTooltipClass="example-tooltip-red"
                    aria-label="Button that shows a red tooltip">
                      <mat-icon style="color: #E64A19">delete</mat-icon>
                    </mat-button-toggle>
                  </td>
                  <!--td><button class="btn btn-primary" (click)="edit(aluno.id)">Editar</button></td>
                  <td><button class="btn btn-danger" (click)="delete(aluno.id)">Desativar</button></td>
                  <td></td> -->

                </tr>
              </table>
            </div>
            <!-- PAGINAÇÃO -->
            <div class="box-footer clearfix">
              <ul class="pagination pagination-sm no-margin pull-right">
                <li>
                  <a href="" (click)="setPreviousPage($event)">&laquo;</a>
                </li>
                <li *ngFor="let p of pages; let i=index">
                  <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
                </li>
                <li>
                  <a href="" (click)="setNextPage($event)">&raquo;</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
