/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carregando.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./carregando.component";
var styles_CarregandoComponent = [i0.styles];
var RenderType_CarregandoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarregandoComponent, data: {} });
export { RenderType_CarregandoComponent as RenderType_CarregandoComponent };
export function View_CarregandoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Salvando... "])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Por favor aguarde."])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["color", "warn"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(9, 49152, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = "warn"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 9)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 9).diameter; var currVal_3 = i1.ɵnov(_v, 9).diameter; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_CarregandoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-carregando", [], null, null, null, View_CarregandoComponent_0, RenderType_CarregandoComponent)), i1.ɵdid(1, 114688, null, 0, i8.CarregandoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarregandoComponentNgFactory = i1.ɵccf("app-carregando", i8.CarregandoComponent, View_CarregandoComponent_Host_0, {}, {}, []);
export { CarregandoComponentNgFactory as CarregandoComponentNgFactory };
