<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <div class="row">
            <div class="form-group">
              <div class="col-sm-6">
                <mat-form-field appearance="standard" class="col-sm-12">
                  <mat-label>Livro</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let item of bibliaList" value="item.name" (click)="abbrev(item.abbrev)">
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Nome é obrigatório</mat-error>
                </mat-form-field>
              </div>
              <div class="col-sm-4">
                <mat-form-field appearance="standard" class="col-sm-12">
                  <mat-label>Capítulo</mat-label>
                  <mat-select>
                    <mat-option *ngFor="let item of capitulos" (click)="cliqueBook(item)" value="item">
                      {{item}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>


            </div>
          </div>
          <div class="buttons">
            <button mat-raised-button color="warn" (click)="pesquisar()" [disabled]="!numeroSelecionado">Buscar</button>
          </div>
          <div class="row">
            <div class="form-group">
              <div class="col-sm-4">
                <mat-form-field appearance="standard" class="col-sm-12">
                  <mat-label for="Procurar">Procurar</mat-label>
                  <input matInput placeholder="Digite um trecho que deseja encontrar" id="procurar"
                    [(ngModel)]="procurar">
                </mat-form-field>
              </div>
              <div class="buttonsProcurar">
                <button mat-raised-button color="warn" (click)="search()" [disabled]="!procurar">Procurar</button>
              </div>
            </div>
          </div>

          <div>
            <mat-nav-list *ngIf="pesquisarBoolean">
              <h1 *ngIf="book"> {{book + ' ' + chapter}}</h1>
              <mat-list-item *ngFor="let item of versiculoPadrao">
                <div>
                  <p class="palavras"> {{ item.number + '. ' + item.text }}</p>
                </div>

              </mat-list-item>
              <h5 class="centralizado">Biblia Sagrada, Nova Versão Internacional®<br>
                NVI® Copyright © 1993, 2000, 2011 by Biblica, Inc.®<br>
                Used by permission. All rights reserved worldwide.</h5>
            </mat-nav-list>
          </div>
          <div *ngIf="isLoading" class="spinner" text-center>
            <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
          </div>
          <div *ngIf="searchBoolean">
            <mat-nav-list>
              <h3 *ngIf="searchVersi.occoccurrences" class="ocorrencias">
                {{'Ocorrências: ' + searchVersi.occoccurrences }} </h3>
              <mat-list-item *ngFor="let item of searchVersi.verses">
                <div>

                  <p class="palavras"> {{ item.book }} {{  item.chapter + ': ' + item.number + '&#013;'}}
                    {{ item.text }}</p>
                </div>

              </mat-list-item>
              <h5 class="centralizado">Biblia Sagrada, Nova Versão Internacional® <br>
                NVI® Copyright © 1993, 2000, 2011 by Biblica, Inc.®<br>
                Used by permission. All rights reserved worldwide.</h5>
            </mat-nav-list>

          </div>
          <div class="buttons2" *ngIf="shared.user.id == ''">
              <button mat-stroked-button color="warn" type="button" (click)="login()">Login</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
