import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CaixaService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(caixa) {
        if (caixa.id != null && caixa.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/caixa`, caixa);
        }
        else {
            caixa.id = null;
            return this.http.post(`${environment.apiUrl}/api/caixa`, caixa);
        }
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/caixa/${page}/${count}`);
    }
    findAllCaixa() {
        return this.http.get(`${environment.apiUrl}/api/caixa/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/caixa/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/caixa/${id}`);
    }
}
CaixaService.ngInjectableDef = i0.defineInjectable({ factory: function CaixaService_Factory() { return new CaixaService(i0.inject(i1.HttpClient)); }, token: CaixaService, providedIn: "root" });
