
export class Aluno {
  constructor(
    public id: string,
    public nome: string,
    public cpfAluno: string,
    public telefone: string,
    public data: Date,
    public cep: string,
    public numero: string,
    public complemento: string,
    public logradouro: string,
    public bairro: string,
    public cidade: string,
    public estado: string,
    public responsavel: string,
    public status: boolean,
    public imagem: string,
    public contadorFaltas: Number
  ) {}
}
