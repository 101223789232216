import { User } from '../model/user.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Response } from './../model/respose.model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) { }
  login(user: User) {
    return this.http.post(`${environment.apiUrl}/api/auth`, user);
  }

  createOrUpdate(user: User) {
    const formData = new FormData()
    formData.append('email', user.email);
    formData.append('password', user.password);
    formData.append('profile', JSON.stringify(user.profile));
    if (user.nome) {
      formData.append('nome', user.nome);
    }
    if(user.telefone) {
      formData.append('telefone', user.telefone);
    }
    if(user.turmaId) {
      formData.append('turmaId', user.turmaId);
    }
    if(user.imagem) {
      if (user.imagem instanceof File) {
        formData.append('imagem', user.imagem);
      } else if (user.imagem) {
        formData.append('imagemUrl', user.imagem);
      }
    }
    if(user.status) {
      formData.append('status', user.status.toString());
    }
    if (user.id != null && user.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/user/${user.id}`, formData);
    } else {
      user.id = null;
      return this.http.post(`${environment.apiUrl}/api/user`, formData);
    }
  }

  findAll(page: number, count: number) {
    return this.http.get(`${environment.apiUrl}/api/user/${page}/${count}`);
  }

  findAllUser() {
    return this.http.get<User[]>(`${environment.apiUrl}/api/user/`);

  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/user/${id}`);
  }

  findByTurmaId(id: string) {
    return this.http.get<User[]>(`${environment.apiUrl}/api/user/turmaId/${id}`);
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/user/${id}`);
  }

  findByEmailContains(email: String) {
    return this.http.get<Response>(`${environment.apiUrl}/api/user/byName/${email}`);
  }

  ativarUsuario(id: string) {
    return this.http.post(`${environment.apiUrl}/api/user/ativar`, id);
  }

  desativarUsuario(desativar: string) {
    return this.http.post(`${environment.apiUrl}/api/user/desativar`, desativar);
  }
}
