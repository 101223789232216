import { Turma } from "./turma.model";

export class FrequenciaDetalhes {
  constructor(
    public id: string,
    public data: Date,
    public oferta: Number,
    public visitantes: Number,
    public matriculas: Number,
    public faltas: Number,
    public professores: Number,
    public turma: Turma,
    public frequencia: String
  ) {}
}
