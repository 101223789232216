
<mat-drawer-container  class="sidenav-container">
  <mat-drawer #drawer id="drawer" mode="side" class="sidenav" fixedInViewport="true" *ngIf="mostrarMenu === true" opened>
    <mat-toolbar color="primary" aria-label><span><img class="toolbar" class="logo"
          src="../../../assets/image/ebd20.png"></span>
    </mat-toolbar>
    <mat-nav-list>
      <mat-accordion>
        <!--Home-->
        <a mat-list-item class="btnHover" [routerLink]="['/']" (click)="drawer.toggle()" >
          <mat-icon>home</mat-icon> &nbsp; &nbsp;Início
        </a>
        <!--Alunos-->
        <mat-expansion-panel class="body">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>account_circle</mat-icon>
              &nbsp; &nbsp;Alunos
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item class="btnHover" [routerLink]="['/aluno-new']" (click)="drawer.toggle()">
            <mat-icon>add</mat-icon> &nbsp; &nbsp;Novo
          </a>
          <a mat-list-item class="btnHover" [routerLink]="['/aluno-list']" (click)="drawer.toggle()">
            <mat-icon>create</mat-icon> &nbsp; &nbsp;Listar
          </a>
          <mat-divider></mat-divider>
        </mat-expansion-panel>
        <!-- turmas-->
        <mat-expansion-panel class="body">
          <mat-expansion-panel-header *ngIf="shared.user.profile !== 'PROFESSOR'">
            <mat-panel-title>
              <mat-icon>class</mat-icon>
              &nbsp; &nbsp;Turmas
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item class="btnHover" [routerLink]="['/turma-new']" (click)="drawer.toggle()">
            <mat-icon>add</mat-icon> &nbsp; &nbsp;Novo
          </a>
          <a mat-list-item class="btnHover" [routerLink]="['/turma-list']" (click)="drawer.toggle()">
            <mat-icon>create</mat-icon> &nbsp; &nbsp;Listar
          </a>
          <mat-divider></mat-divider>
        </mat-expansion-panel>
        <!-- USUÁRIOS -->
        <mat-expansion-panel class="body">
          <mat-expansion-panel-header *ngIf="shared.user.profile === 'ADMIN' || shared.user.profile === 'PASTOR'">
            <mat-panel-title>
              <mat-icon>supervised_user_circle </mat-icon>
              &nbsp; &nbsp;Usuários
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item class="btnHover" [routerLink]="['/user-new']" (click)="drawer.toggle()">
            <mat-icon>add</mat-icon> &nbsp; &nbsp;Novo
          </a>
          <a mat-list-item class="btnHover" [routerLink]="['/user-list']" (click)="drawer.toggle()">
            <mat-icon>create</mat-icon> &nbsp; &nbsp;Listar
          </a>
          <mat-divider></mat-divider>
        </mat-expansion-panel>
        <!--Matricula-->
        <mat-expansion-panel class="body">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>work_outline</mat-icon>
              &nbsp; &nbsp;Matrícula
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description>
          </mat-expansion-panel-header>
          <a mat-list-item class="btnHover" [routerLink]="['/matricula-new']" (click)="drawer.toggle()">
            <mat-icon>add</mat-icon> &nbsp; &nbsp;Novo
          </a>
          <a mat-list-item class="btnHover" [routerLink]="['/matricula-list']" (click)="drawer.toggle()">
            <mat-icon>create</mat-icon> &nbsp; &nbsp;Listar
          </a>
          <mat-divider></mat-divider>
        </mat-expansion-panel>
         <!-- Lição -->
         <a mat-list-item class="btnHover" [routerLink]="['/licao']" (click)="drawer.toggle()">
          <mat-icon fontSet="material-icons-outlined">school</mat-icon> &nbsp; &nbsp;Lição
        </a>
        <!--Frequencia-->
          <a mat-list-item class="btnHover" [routerLink]="['/frequencia-new']" (click)="drawer.toggle()" >
          <mat-icon>query_builder</mat-icon> &nbsp; &nbsp;Frequência
        </a>

        <!-- Relatórios -->
        <mat-expansion-panel class="body" (click)="abrirConfirmacao()" >
          <mat-expansion-panel-header *ngIf="shared.user.profile !== 'PROFESSOR'" (click)="drawer.toggle()">
            <mat-panel-title>
              <mat-icon>insert_chart_outlined</mat-icon>
              &nbsp; &nbsp;Relatórios
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-divider></mat-divider>
        </mat-expansion-panel>
         <!--Biblia-->
         <!-- mat-expansion-panel class="body">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>book</mat-icon>
              &nbsp; &nbsp;Ler
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item class="btnHover" [routerLink]="['/biblia']" (click)="drawer.toggle()">
            <mat-icon>import_contacts</mat-icon> &nbsp; &nbsp;Bíblia
          </a>
          <mat-divider></mat-divider>
        </!-->
        <!--Logout-->
        <a mat-list-item class="btnHover" class="body" (click)="sair()" >
          <mat-icon>exit_to_app</mat-icon> &nbsp; &nbsp;Sair
        </a>


        <!-- <a mat-list-item class="btnHover" [routerLink]="['/lancamento']" (click)="drawer.toggle()"  *ngIf="shared.user.profile !== 'PROFESSOR'">
          <mat-icon>add</mat-icon> &nbsp; &nbsp;Lancamento
        </a> -->
        <!-- <a mat-list-item class="btnHover" [routerLink]="['/licao-list']" (click)="drawer.toggle()">
          <mat-icon>create</mat-icon> &nbsp; &nbsp;Lição (antigo)
        </a> -->
      </mat-accordion>

    </mat-nav-list>
  </mat-drawer>
  <mat-drawer-content>
    <span #target></span>
    <mat-toolbar color="primary" class="toolbar" aria-label *ngIf="mostrarMenu === true">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toggleDrawer()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <!-- <span *ngIf="panelOpenState"><img class="logo" src="../../../assets/image/ebd20.png"></span> -->
      <span class="space"></span>
      <img *ngIf="shared.user.imagem"  mat-card-avatar class="example-header-image" src="{{ shared.user.imagem }}" />
      <p class="space-right" > {{ getNomeSobrenome(shared.user.nome)  }} </p>
      <!-- <button mat-icon-button class="space-right" (click)="sair()" matTooltip="Sair">
        <mat-icon>exit_to_app</mat-icon>
      </button> -->
      <!-- <button mat-icon-button class="space-right" [routerLink]="['/']">
        <mat-icon>home</mat-icon>
      </button> -->
    </mat-toolbar>
    <ng-content>
    </ng-content>
  </mat-drawer-content>
</mat-drawer-container>
