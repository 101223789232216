import { DialogService } from './../../../dialog.service';
import { Calendario } from './../../model/calendario.model';
import { CalendarioService } from './../../services/calendario.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormGroup, NgForm, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { SharedService } from '../../services/shared.service';
import { ResponseApi } from '../../model/response-api';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements OnInit {
  // inicio = new FormControl(new Date());
  // serializedDate = new FormControl((new Date()).toISOString());

  @ViewChild('f')
  f: NgForm;
  show: boolean = true;
  form: FormGroup;
  calendarioList = [];
  message: {};
  classCss: {};
  page = 0;
  count = 5;
  pages: Array<number>;
  shared: SharedService;

  constructor(
    private calendarioService: CalendarioService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    public dialog: MatDialog,
  ) {
    this.shared = SharedService.getInstance();
  }
  ngOnInit() {
    this.findAll(this.page, this.count);
    this.form = this.formBuilder.group({
      id: [null],
      titulo: [null, Validators.required],
      inicio: [new Date(), Validators.required],
      fim: [new Date(), Validators.required]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.calendarioService.findById(params['id']).subscribe(calendario => {
          if (calendario && calendario.data) {
            this.form.setValue(calendario.data);
          }
        });
      }
    });
  }

  findAll(page: number, count: number) {
    this.calendarioService.findAll(page, count).subscribe((responseApi: ResponseApi) => {
      this.calendarioList = responseApi['data']['content'];
      this.pages = new Array(responseApi['data']['totalPages']);
    }, err => {
      /* this.showMessage({
        type: 'error',
        text: err['error']['errors'][0]
      });*/
    });
  }

  register() {
    if (this.form.valid) {
      this.calendarioService.createOrUpdate(this.form.value).subscribe(
        (responseApi: ResponseApi) => {
            this.resetaForm();
            this.abrirConfirmacao();
            this.findAll(this.page, this.count);
        },
        err => {

        }
      );
    } else {

      this.verificaValidacoesForm(this.form);
    }
  }

  delete(id: string) {
    this.dialogService.confirm('Você deseja desativar este Registro ?')
      .then((canDelete: boolean) => {
        if (canDelete) {
          this.message = {};
          this.calendarioService.delete(id).subscribe(
            (responseApi: ResponseApi) => {
// mensagem de confirmação aqui
              this.findAll(this.page, this.count);
            },
            err => {

            }
          );
        }
      });
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(Confirmacao);

    setTimeout(() => {
      this.dialog.closeAll();
    }, 3000);

    /* dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });*/
  }
  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
      this.page = i;
      this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }

}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confimacao',
  templateUrl: './Confirmacao.html'
})
// tslint:disable-next-line:component-class-suffix
export class Confirmacao {}
