import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Response } from './../../model/respose.model';

import { FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { LicaoService } from '../../services/licao.service';
import Swal from 'sweetalert2';

import { TurmaService } from '../../services/turma.service';
import { Observable } from 'rxjs';
import { ResponseApi } from '../../model/response-api';
import { map } from 'rxjs/operators';
import { User } from '../../model/user.model';
import { UserService } from '../../services/user.service';
import { Licao } from '../../model/licao.model';
import { Turma } from '../../model/turma.model';
import { MatDialog } from '@angular/material';
import { CarregandoComponent } from '../../shared/Avisos/carregando/carregando.component';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-licao-new',
  templateUrl: './licao-new.component.html',
  styleUrls: ['./licao-new.component.css']
})
export class LicaoNewComponent implements OnInit {

  public Editor = DecoupledEditor;
  public editorContent = '';

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  imagem2: string;
  licao = new Licao('', new Date, null, null, '', '', '', '', '', null, false, null);
  shared: SharedService;
  turmas: Observable<Turma[]>;
  professores: Observable<User[]>;
  isLoading = false;
  conteudo = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private turmaService: TurmaService,
    private licaoService: LicaoService,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.shared = SharedService.getInstance();
  }


  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
    );
    editor.model.document.on('change:data', () => {
      this.editorContent = editor.getData();
    });
  }

  public editorConfig = {
    placeholder: 'Digite aqui o conteúdo planejado...',
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'http://',
      openInNewTab: {
        mode: 'manual',
        label: 'Open in new tab',
        defaultValue: true,
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer'
        }
      }
    }
  }


  ngOnInit() {

    this.turmas = this.turmaService.findAllTurma()
      .pipe(map(turmas => {
        // console.log(turmas);
        return turmas.data.filter(turma => turma.status === false);
      }));

    this.professores = this.userService.findAllUser()
      .pipe(
        map(users => {
          return users.filter(user => user.profile === 'ROLE_PROFESSOR');
        })
      );

    this.form = this.formBuilder.group({
      id: [null],
      data: [new Date(), Validators.required],
      turma: [null, Validators.required],
      professor: [null, Validators.required],
      tema: [null, Validators.required],
      objetivo: [null, Validators.required],
      conteudo: [null, Validators.required],
      metodologia: [null, Validators.required],
      experienciaPedagogica: [null],
      imagem: [null],
      status: [null]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.licaoService.findById(params['id']).subscribe(licao => {
          if (licao && licao.data) {
            /* const data = new Date(licao.data.data);
            this.form.controls['data'].setValue(data);
            this.form.controls['turma'].setValue(licao.data);
            this.form.controls['professor'].setValue(licao.data);
            this.form.controls['tema'].setValue(licao.data.tema);
            this.form.controls['objetivo'].setValue(licao.data.objetivo);
            this.form.controls['conteudo'].setValue(licao.data.conteudo);
            this.form.controls['metodologia'].setValue(licao.data.metodologia);
            this.form.controls['experienciaPedagogica'].setValue(licao.data.experienciaPedagogica);
            this.form.controls['imagem'].setValue(licao.data.imagem); */
            console.log(licao.data);
            this.form.setValue(licao.data);
            const data = new Date(licao.data.data);
            this.form.controls['data'].setValue(data);
          }
        });
      }
    });
  }

  register() {
    this.conteudo = false;
    if (this.form.valid) {
      this.isLoading = true;
      this.abrirConfirmacao();
      console.log(this.form.value);
      this.licaoService.createOrUpdate(this.form.value).subscribe(
        (responseApi: ResponseApi) => {
          this.conteudo = false;
          this.licao = new Licao('', new Date, null, null, '', '', '', '', '', null, false, null);
          let licao: Licao = responseApi.data;
          this.resetaForm();
          this.dialog.closeAll();
          this.alertaSucesso();
          this.isLoading = false;
        },
        err => {
          this.dialog.closeAll();
          this.alertaFalha();
        }
      );
    } else {
      if(this.form.controls['conteudo'].invalid){
        this.conteudo = true;
      }
      console.log('Formulário inválido');
      this.dialog.closeAll();
      this.verificaValidacoesForm(this.form);
    }
  }

  listaLicoes() {
    this.router.navigate(['/licao-list']);
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(CarregandoComponent, {
      width: '350px'
    });
  }

  alertaSucesso() {
    Swal.fire({
      title: 'Lição salva com sucesso!',
      type: 'success',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaFalha() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaFalhaImagem() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O tamanho é maior que o máximo permitido.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }

  onFileChange3(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      const [imagem] = event.target.files;
      reader.readAsDataURL(imagem);

      reader.onload = () => {
        this.form.patchValue({
          imagem: reader.result as string,
        });
        this.cd.markForCheck();
      };
    }
  }

  onFileChange(event): void {
    if (event.target.files[0].size > 3000000) {
      this.alertaFalhaImagem();
    } else {
      // this.licao.imagem = '';
      const reader = new FileReader();
      reader.onloadend = (e: Event) => {
        this.licao.imagem = reader.result as string;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  onFileChange2(event: Event) {
    const arquivoSelecionado = (event.target as HTMLInputElement).files[0];
    console.log('arquivo selecionado' + arquivoSelecionado);
    const reader = new FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      console.log('data url' + dataUrl);
      this.licao.imagem = dataUrl as string;
    };
    reader.readAsDataURL(arquivoSelecionado);
    console.log('reader / final' + reader);
  }

}
