<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Chamada {{ tituloCard }}</mat-card-title>

          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">
          <form [formGroup]="form" class="form horizontal" #f="ngForm">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                    <input matInput placeholder="Data da chamada" id="data" formControlName="data"
                      [matDatepicker]="data" readonly="true" required>
                    <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                    <mat-datepicker touchUi #data></mat-datepicker>
                    <mat-error>Data é obrigatória</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-3">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="visitantes">Visitantes</mat-label>
                    <input matInput id="visitantes" formControlName="visitantes" type="number">
                    <mat-icon matPrefix>emoji_people&nbsp;</mat-icon>
                    <mat-hint *ngIf="totalVisitantes != null"> Visitantes: {{ totalVisitantes }}</mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-3" class="buttons2">
                  <button mat-raised-button color="primary" type="button" (click)="listarAlunos()"
                    [disabled]="!form.get('data').value">Pesquisar</button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <div *ngIf="!frequencias || frequencias.length <=0">
                <p class="box-body" *ngIf="frequencias === []">Nenhum registro encontrado!</p>
              </div>
              <table class="table table-bordered" #selecionados *ngIf="frequencias && frequencias.length > 0">
                <tr>
                  <th style="text-align: center; vertical-align: middle; width: 1%; white-space: nowrap;">Nº</th>
                  <th>Aluno</th>
                  <th>Presença</th>

                </tr>
                <tr *ngFor="let frequencia of frequencias; let i = index">
                  <td style="text-align: center; vertical-align: middle; width: 1%; white-space: nowrap;">{{i + 1}}</td>
                  <td>
                    <img *ngIf="frequencia.matricula.aluno.imagem" mat-card-avatar class="example-header-image"
                      src="{{ frequencia.matricula.aluno.imagem }}" />
                      {{frequencia.matricula?.aluno.nome}}</td>
                  <td>
                    <mat-checkbox (change)="presencaChanges(frequencia, $event)" [checked]="frequencia.presente"
                      matTooltip=""></mat-checkbox>
                  </td>
                </tr>

              </table>
            </div>


            <!-- <div class="row" *ngIf="frequencias.length !== 0">
              <div class="form-group">
                <div class="col-sm-3">
                  <mat-form-field class="col-sm-12">
                    <mat-label for="oferta">Oferta</mat-label>
                    <input matInput id="oferta" formControlName="oferta" type="number">
                    <span matPrefix>R$&nbsp;</span>
                  </mat-form-field>
                </div>
                <div class="col-sm-3">
                  <mat-form-field class="col-sm-12">
                    <mat-label for="visitantes"> Nº Visitantes</mat-label>
                    <input matInput id="visitantes" formControlName="visitantes" type="number">
                    <mat-icon matPrefix>emoji_people&nbsp;</mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div> -->
            <div class="container-fluid">
              <div class="buttons">
                <button mat-raised-button color="warn" [disabled]="frequencias.length === 0"
                  (click)="register()">Salvar</button>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
            <mat-card class="info">
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Presente" style="color: #ff4081">check_box</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Presente</td>
                  </tr>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Ausente" style="color:#ff4081">check_box_outline_blank</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ausente</td>
                  </tr>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
