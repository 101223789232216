<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Nova Turma</mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">
          <div class="row">
            <div class="form-group">
              <div class="col-sm-6">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="nome">Nome</mat-label>
                  <input matInput placeholder="Nome" id="nome" formControlName="nome" required>
                  <mat-error>O nome é obrigatório</mat-error>
                </mat-form-field>
              </div>


              <div class="col-sm-6">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="professoresId">Professores</mat-label>
                  <mat-select multiple placeholder="Professor" id="professoresId" formControlName="professoresId" required>
                    <mat-option *ngFor="let professor of professores | async" [value]="professor.id"> {{ professor?.nome }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Professor(a) é obrigatório</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group">
              <!--div class="col-sm-6">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="professor">Professor (a)</mat-label>
                  <mat-select placeholder="Professor" id="professor" formControlName="professor">
                    <mat-option *ngFor="let professor of professores | async" [value]="professor"> {{ professor?.nome }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div-->

              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="ano">Ano</mat-label>
                  <input matInput placeholder="Ano" id="ano" formControlName="ano" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Ano é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="semestre">Bimestre</mat-label>
                  <input matInput placeholder="Bimestre" id="semestre" formControlName="semestre" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Bimestre é obrigatório</mat-error>
                </mat-form-field>
              </div>

            </div>
          </div>

          <div class="row" class="check">
            <div class="form-group">
              <div class="col-sm-3">
                <mat-checkbox id="escolaDeMusica" formControlName="escolaDeMusica" [checked]="turma.escolaDeMusica">
                  Escola de Música</mat-checkbox>
              </div>
            </div>
          </div>



          <div class="container-fluid">
            <div class="buttons">
              <button mat-raised-button color="warn">Salvar</button>
            </div>
            <div class="listas">
              <button mat-raised-button color="primary" type="button" (click)="listaTurmas()">Lista de turmas</button>
            </div>
          </div>
          <!--app-form-debug [formularioDebug]="form"> </app-form-debug-->
        </form>
      </div>
    </div>
    <div *ngIf="isLoading" class="spinner" text-center>
      <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
    </div>
  </div>
</div>
