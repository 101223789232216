import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { pesquisaBiblica } from '../../model/pesquisaBiblica';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { MatDialog } from '@angular/material';
import { BuscandoComponent } from '../../shared/Avisos/buscando/buscando.component';



declare var bibleapi;
@Component({
  selector: 'app-biblia',
  templateUrl: './biblia.component.html',
  styleUrls: ['./biblia.component.css']
})
export class BibliaComponent implements OnInit {

  livros = new XMLHttpRequest();
  url = 'https://cors-anywhere.herokuapp.com/https://bibleapi.co/api/books/';
  bibleApiPadrao = 'https://cors-anywhere.herokuapp.com/https://bibleapi.co/api/verses/nvi/sl/23';
  bibleApiLivroCapitulo = 'https://bibleapi.co/api/verses/nvi/';
  isLoading = false;
  public versiculoPadrao: any;
  public bibliaList: any;
  book: string;
  chapter: string;
  capituloSelect: string;
  quantidadeCapitulos: number;
  capitulos = [];
  numeroSelecionado: number;
  pesquisa;
  searchVersi: any = [];
  procurar: any;
  searchBoolean = false;
  pesquisarBoolean = true;
  shared: SharedService;


  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.todosLivros();
    this.versiculoInicial();
  }


  cliqueBook(value: any) {
     console.log(value);
    this.numeroSelecionado = value;
  }

  abbrev(valor: any) {
    this.abrirConfirmacao();
    const abbrev = valor;
    // this.isLoading = true;
    let abbrevs: Observable<any>;
    abbrevs = this.http.get(`https://cors-anywhere.herokuapp.com/https://bibleapi.co/api/books/${abbrev}`);
    abbrevs.subscribe(abbrevsData => {
      this.capituloSelect = abbrevsData.abbrev;
      this.quantidadeCapitulos = abbrevsData.chapters;
      let i = 1;
      while (this.capitulos.push(i++) < this.quantidadeCapitulos) { }
      // console.log(this.capituloSelect);
      // console.log(this.quantidadeCapitulos);
      // console.log(this.capitulos);
      this.dialog.closeAll();
    }, err => {
      this.dialog.closeAll();
    });
  }

  pesquisar(abbrev: any, chapters: any) {
    this.abrirConfirmacao();
    this.searchBoolean = false;
    this.pesquisarBoolean = true;
    abbrev = this.capituloSelect;
    chapters = this.numeroSelecionado;
    //this.isLoading = true;
    let livros: Observable<any>;
    livros = this.http.get(`https://cors-anywhere.herokuapp.com/https://bibleapi.co/api/verses/nvi/${abbrev}/${chapters}`);
    livros.subscribe(livroData => {
      this.book = livroData.book.name;
      // console.log('book here' + this.book);
      this.chapter = livroData.chapter.number;
      // console.log('chapter here' + this.chapter);
      this.versiculoPadrao = livroData.verses;
      // console.log(this.versiculoPadrao);
      // this.isLoading = false;
      this.dialog.closeAll();
    }, err => {
      this.dialog.closeAll();
    });
    this.capitulos = [];
  }

  todosLivros() {
    this.isLoading = true;
    let livros: Observable<any>;
    livros = this.http.get(this.url);
    livros.subscribe(livroData => {
      this.bibliaList = livroData;
      // console.log(this.bibliaList);
      this.isLoading = false;
    }, err => {
      this.isLoading = false;
      this.alertaNetErro();
      this.router.navigate(['/']);
    });
  }
  versiculoInicial() {
    this.isLoading = true;
    let livros: Observable<any>;
    livros = this.http.get(this.bibleApiPadrao);
    livros.subscribe(livroData => {
      this.versiculoPadrao = livroData.verses;
      // console.log(this.versiculoPadrao);
      this.isLoading = false;
    });
  }

  search() {
    this.abrirConfirmacao();
    this.pesquisarBoolean = false;
    this.searchBoolean = true;
    this.isLoading = true;
    this.pesquisa = new pesquisaBiblica('nvi', this.procurar);
    this.http.post('https://cors-anywhere.herokuapp.com/https://bibleapi.co/api/search/',
      this.pesquisa).subscribe(pes => {
        this.searchVersi = pes;
        // console.log(this.searchVersi);
        this.isLoading = false;
        this.dialog.closeAll();
      }, err => {
        this.dialog.closeAll();
        this.isLoading = false;
        this.alertaInfo();
      });
    this.procurar = '';
  }

  login() {
    this.router.navigate(['/login']);
  }

  alertaInfo() {
    Swal.fire({
      type: 'info',
      title: 'Não encontrado.'
    });
  }
  alertaNetErro() {
    Swal.fire({
      type: 'error',
      title: 'Sem Internet',
      html: 'Tente: ' + '<br>- verificar os cabos de rede, modem e roteador' + '<br> - Conectar à rede Wi-Fi novamente'
    });
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(BuscandoComponent, {
      width: '350px'
    });
    dialogRef.disableClose = true;
  }

}
