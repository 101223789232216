import { Aluno } from "./../../model/aluno.model";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FrequenciaService } from "../../services/frequencia.service";
import { ResponseApi } from "../../model/response-api";
import { last, map } from "rxjs/operators";
import { Frequencia } from "../../model/frequencia.model";
import { ResumoFrequencia } from "./resumo-frequencia.model";
import { TurmaService } from "../../services/turma.service";
import { MatTableDataSource, MatDialog, MatSort } from "@angular/material";
import { BuscandoComponent } from "../../shared/Avisos/buscando/buscando.component";
import { AlunoService } from "../../services/aluno.service";
import { add, subtract } from "add-subtract-date";
import Swal from "sweetalert2";

export class BirthDay {
  constructor(
    public nome: string,
    public data: Date,
    public imagem: string,
    public telefone: String
  ) {}
}

@Component({
  selector: "app-resumo-frequencia",
  templateUrl: "./resumo-frequencia.component.html",
  styleUrls: ["./resumo-frequencia.component.css"],
})
export class ResumoFrequenciaComponent implements OnInit {
  isLoading = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("f")
  f: NgForm;
  form: FormGroup;
  form2: FormGroup;
  page = 0;
  count = 10;
  pages: Array<number>;
  listTurma = [];
  listdoDia = [];
  totalMatriculados = 0;
  totalFaltosos = 0;
  tamanhoArray = 0;
  totalPresentes = 0;
  totalPresentes2 = 0;
  pPresentes = 0;
  pAusentes = 0;
  valoresArrayFrequencia;
  dados;
  frequencias: Frequencia[] = [];
  alunosList: Aluno[] = [];
  birthList: BirthDay[] = [];
  qtdFrequencias;
  isNothing = false;
  isLoadingBirthDate = false;
  acabou = false;
  resumoList: any[] = [];
  resumoList2: any[] = [];
  nomeTurma;
  totalVisitantes;
  todayData: number = Date.now();
  dataSource: MatTableDataSource<ResumoFrequencia>;
  dataSource2: MatTableDataSource<ResumoFrequencia>;
  dataSource3: MatTableDataSource<BirthDay>;

  displayedColumns: string[] = [
    "turma",
    "matriculados",
    "faltas",
    "visitantes",
    "total",
  ];
  displayedColumns2: string[] = [
    "turma",
    "matriculados",
    "faltas",
    "visitantes",
    "total",
  ];
  displayedColumnsBirth: string[] = ["image", "name", "birthdate"];

  constructor(
    private formBuilder: FormBuilder,
    private frequenciaService: FrequenciaService,
    private turmaService: TurmaService,
    private alunoService: AlunoService,
    public dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource<ResumoFrequencia>();
    this.dataSource2 = new MatTableDataSource<ResumoFrequencia>();
    this.dataSource3 = new MatTableDataSource<BirthDay>();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      data: [new Date(), Validators.required],
    });
    this.form2 = this.formBuilder.group({
      data: [new Date(), Validators.required],
      data2: [new Date(), Validators.required],
    });

    this.findAllTurma(this.page, this.count);
    this.findBirthDay();
  }

  async resumo() {
    this.abrirConfirmacao();
    this.resumoList = [];
    this.dataSource.data = [];
    try {
      const promises = this.listTurma.map((id) =>
        this.frequenciaService
          .resumoFrequencia(this.form.get("data").value, id)
          .toPromise()
      );

      const responses = await Promise.all(promises);
      responses.forEach((resp) => {
        if (resp && resp.data) {
          const element = resp.data;
          const resumoFrequencia = new ResumoFrequencia(
            element.turma,
            element.totalMatriculados,
            element.faltas,
            element.visitantes,
            element.total
          );
          this.resumoList.push(resumoFrequencia);
        }
      });
      this.dataSource.data = this.resumoList;
      this.dataSource.sort = this.sort;
      this.getTotalCost();
      this.getTotalFaltas();
      this.getTotalMatriculados();
      this.getTotalVisitantes();
      this.dialog.closeAll();
    } catch (error) {
      this.dialog.closeAll();
      this.alertaError;
    }
  }

  resumoPorData() {
    this.findAllTurma(this.page, this.count);
    this.valoresArrayFrequencia = this.listTurma.length;
    this.isNothing = false;
    this.acabou = false;
    for (let i = 0; i < this.valoresArrayFrequencia; i++) {
      this.abrirConfirmacao();
      this.frequenciaService
        .findByTurmaIdBetween(
          this.listTurma[i].id,
          this.form2.get("data").value,
          this.form2.get("data2").value
        )
        .subscribe((response) => {
          if (response && response.data) {
            console.log(response);
            this.frequencias = response.data;
            this.qtdFrequencias = response.data.length;
            console.log(this.frequencias);
            if (this.frequencias.length > 0) {
              // o problema é que na posição "i" nem sem pre tem valor. Quando só tem um aluno, o valor está
              // na posição "0" (zero)
              this.nomeTurma = this.frequencias[0].turma.nome;
              console.log(this.nomeTurma);
              for (let o = 0; o < this.frequencias.length; o++) {
                if (this.frequencias[o].presente === false) {
                  this.totalFaltosos++;
                } else {
                  this.totalPresentes++;
                }
              }
              const length = this.totalFaltosos + this.totalPresentes;
              const resumo = new ResumoFrequencia(
                this.nomeTurma,
                this.totalPresentes,
                this.totalFaltosos,
                this.totalVisitantes,
                this.totalPresentes / length
              );
              console.log(resumo);
              this.totalPresentes2 += this.totalPresentes - this.totalFaltosos;
              console.log(this.totalPresentes);
              this.resumoList2.push(resumo);
              this.isNothing = false;
            } else {
              this.isNothing = true;
              this.dialog.closeAll();
            }
            // this.dataSource = this.resumoList;
            console.log(this.resumoList2);
            this.totalFaltosos = 0;
          }

          this.totalPresentes2 = 0;
          this.totalPresentes = 0;
          this.totalFaltosos = 0;
          console.log(this.dataSource2.data);
        });
      if (this.valoresArrayFrequencia === this.listTurma.length) {
        this.dataSource2.data = this.resumoList2;
        this.dataSource2.sort = this.sort;
        this.dialog.closeAll();
      }
    }
    console.log(this.totalPresentes2);
    this.getTotalCost();
    const total = this.resumoList2
      .map((t) => t.total)
      .reduce((a, value) => a + value, 0);
    console.log(total);
    this.resumoList2 = [];
    this.totalPresentes2 = 0;
    this.totalPresentes = 0;
    this.dialog.closeAll();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(BuscandoComponent, {
      width: "350px",
    });
    dialogRef.disableClose = true;
  }

  alertaError() {
    Swal.fire({
      type: "error",
      title: "Oops...",
      html: "Ocorreu um erro ao buscar dados.",
    });
  }

  getTotalCost() {
    return this.resumoList
      .map((t) => t.total)
      .reduce((a, value) => a + value, 0);
  }
  getTotalVisitantes() {
    return this.resumoList
      .map((t) => t.visitantes)
      .reduce((a, value) => a + value, 0);
  }
  getTotalFaltas() {
    return this.resumoList
      .map((t) => t.faltas)
      .reduce((a, value) => a + value, 0);
  }
  getTotalMatriculados() {
    return this.resumoList
      .map((t) => t.totalMatriculados)
      .reduce((a, value) => a + value, 0);
  }

  findAllTurma(page: number, count: number) {
    this.isLoading = true;
    this.turmaService.findAllTurma().subscribe(
      (responseApi: ResponseApi) => {
        const values = responseApi["data"];
        for (let index = 0; index < values.length; index++) {
          const element = values[index];
          if (!element.escolaDeMusica) {
            this.listTurma.push(element.id);
          }
        }
        // this.listTurma = this.listTurma.sort((a, b) =>
        //   a.nome.localeCompare(b.nome)
        // );
        this.isLoading = false;
      },
      (err) => {}
    );
  }

  findAll(page: number, count: number) {
    this.isLoading = true;
    this.frequenciaService
      .findAllData(page, count)
      .pipe(
        map((response) => {
          console.log(response);
          // return response.data.content.filter(turma => turma.data === this.form.get('data').value);
          // this.frequenciaService.findByTurmaId()
          this.valoresArrayFrequencia = response.data.size;
          this.dados = response.data.content;
          // const data: Date = this.form.get('data').value;
          for (let i = 0; i < this.valoresArrayFrequencia; i++) {
            this.frequenciaService
              .findByTurmaId(
                this.dados[0].turma.id,
                this.form.get("data").value
              )
              .subscribe((response2) => {
                this.frequencias = response2.data;
                console.log(this.frequencias);
              });
          }
          console.log(this.listdoDia);
          this.isLoading = false;
        })
      )
      .subscribe(
        () => {
          this.isLoading = false;
        },
        (err) => {}
      );
  }

  findBirthDay() {
    let endWeek = new Date(this.todayData);
    let endmonth = endWeek.getMonth();
    let endday = endWeek.getDate();
    let dayOfEndWeek = endWeek.getDay();
    endWeek = new Date(2020, endmonth, endday);
    endWeek.setHours(0, 0, 0, 0);
    if (dayOfEndWeek != 0) {
      let dif = 7 - dayOfEndWeek;
      endWeek = add(endWeek, dif, "days");
    }

    let newWeek = new Date(this.todayData);
    newWeek = subtract(newWeek, 6, "days");
    let month = newWeek.getMonth();
    let day = newWeek.getDate();
    let lastWeek = new Date(2020, month, day);
    lastWeek.setHours(0, 0, 0, 0);

    this.isLoadingBirthDate = true;
    this.birthList = [];
    this.alunosList = [];
    this.dataSource3.data = [];
    this.alunoService.findBirthDay().subscribe((response: ResponseApi) => {
      this.alunosList = response["data"];
      this.alunosList.forEach((aluno) => {
        const birth = new BirthDay(aluno.nome, aluno.data, aluno.imagem, "");
        let newData = new Date(birth.data);
        let compareDate = new Date(2020, newData.getMonth(), newData.getDate());
        compareDate.setHours(0, 0, 0, 0);
        if (
          compareDate.getTime() >= lastWeek.getTime() &&
          compareDate.getTime() <= endWeek.getTime()
        ) {
          this.birthList.push(birth);
        }
      });
      this.dataSource3.data = this.birthList;
      this.isLoadingBirthDate = false;
    });
  }
}
