/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./images-lancamento.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/grid-list/typings/index.ngfactory";
import * as i3 from "@angular/material/grid-list";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i10 from "@angular/material/icon";
import * as i11 from "@angular/common";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "@angular/forms";
import * as i14 from "./images-lancamento.component";
var styles_ImagesLancamentoComponent = [i0.styles];
var RenderType_ImagesLancamentoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagesLancamentoComponent, data: {} });
export { RenderType_ImagesLancamentoComponent as RenderType_ImagesLancamentoComponent };
function View_ImagesLancamentoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [["class", "label"], ["for", "input"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
function View_ImagesLancamentoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "image"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.preview; _ck(_v, 0, 0, currVal_0); }); }
function View_ImagesLancamentoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "mat-grid-tile", [["class", "mat-grid-tile"]], null, null, null, i2.View_MatGridTile_0, i2.RenderType_MatGridTile)), i1.ɵdid(1, 49152, [[1, 4]], 0, i3.MatGridTile, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 0, "div", [["class", "gradient-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "button", [["class", "remove-button"], ["color", "primary"], ["mat-mini-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeImage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(4, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "mat-icon", [["class", "mat-18 mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(6, 638976, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["delete"])), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "div", [["class", "image-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagesLancamentoComponent_3)), i1.ɵdid(10, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = "primary"; _ck(_v, 4, 0, currVal_2); _ck(_v, 6, 0); var currVal_4 = _v.context.$implicit.preview; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 4).disabled || null); var currVal_1 = (i1.ɵnov(_v, 4)._animationMode === "NoopAnimations"); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 6).inline; _ck(_v, 5, 0, currVal_3); }); }
export function View_ImagesLancamentoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImagesLancamentoComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "input-image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "mat-grid-list", [["class", "item mat-grid-list"], ["cols", "4"], ["rowHeight", "1:1"]], null, null, null, i2.View_MatGridList_0, i2.RenderType_MatGridList)), i1.ɵdid(5, 2211840, null, 1, i3.MatGridList, [i1.ElementRef, [2, i12.Directionality]], { cols: [0, "cols"], rowHeight: [1, "rowHeight"] }, null), i1.ɵqud(603979776, 1, { _tiles: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImagesLancamentoComponent_2)), i1.ɵdid(8, 278528, null, 0, i11.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["class", "add-button"], ["color", "warn"], ["mat-fab", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openInput(i1.ɵnov(_v, 15)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i9.View_MatIcon_0, i9.RenderType_MatIcon)), i1.ɵdid(13, 638976, null, 0, i10.MatIcon, [i1.ElementRef, i10.MatIconRegistry, [8, null]], null, null), (_l()(), i1.ɵted(-1, 0, ["add_a_photo"])), (_l()(), i1.ɵeld(15, 0, [["input", 1]], null, 0, "input", [["accept", "image/*"], ["id", "input"], ["multiple", ""], ["name", "input"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChangeImage($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = "4"; var currVal_2 = "1:1"; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.images; _ck(_v, 8, 0, currVal_3); var currVal_6 = "warn"; _ck(_v, 11, 0, currVal_6); _ck(_v, 13, 0); }, function (_ck, _v) { var currVal_4 = (i1.ɵnov(_v, 11).disabled || null); var currVal_5 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_7 = i1.ɵnov(_v, 13).inline; _ck(_v, 12, 0, currVal_7); }); }
export function View_ImagesLancamentoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-images-lancamento", [], null, null, null, View_ImagesLancamentoComponent_0, RenderType_ImagesLancamentoComponent)), i1.ɵprd(5120, null, i13.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i14.ImagesLancamentoComponent]), i1.ɵdid(2, 49152, null, 0, i14.ImagesLancamentoComponent, [], null, null)], null, null); }
var ImagesLancamentoComponentNgFactory = i1.ɵccf("app-images-lancamento", i14.ImagesLancamentoComponent, View_ImagesLancamentoComponent_Host_0, { label: "label", limit: "limit" }, { onImagesAdded: "onImagesAdded", onImageDeleted: "onImageDeleted" }, []);
export { ImagesLancamentoComponentNgFactory as ImagesLancamentoComponentNgFactory };
