import { Ticket } from '../model/ticket.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';



@Injectable()
export class TicketService {

  constructor(private http: HttpClient) { }

  createOrUpdate(ticket: Ticket) {
    if (ticket.id != null && ticket.id != '') {
      return this.http.put(`${environment.apiUrl}/api/ticket`, ticket);
    } else {
      ticket.id = null;
      ticket.status = 'New';
      return this.http.post(`${environment.apiUrl}/api/ticket`, ticket);
    }
  }
  findAll(page: number, count:number){
    return this.http.get(`${environment.apiUrl}/api/ticket/${page}/${count}`);
  }

  findById(id:string){
    return this.http.get(`${environment.apiUrl}/api/ticket/${id}`);
  }
  delete(id:string){
    return this.http.delete(`${environment.apiUrl}/api/ticket/${id}`);
  }

  findByParams(page:number, count: number, assignedToMe:boolean, t:Ticket){
    t.number = t.number == null ? 0 : t.number;

    t.title = t.title == '' ? 'uninformed' : t.title;
    t.status = t.status == '' ? 'uninformed' : t.status;
    t.priority = t.priority == '' ? 'uninformed' : t.priority;

    return this.http.get(`${environment.apiUrl}/api/ticket/${page}/${count}/${t.number}/${t.title}/${t.status}/${t.priority}/${assignedToMe}/`);
  }

  changeStatus(status:string, ticket:Ticket){
    return this.http.put(`${environment.apiUrl}/api/ticket/${ticket.id}/${status}`, ticket);
  }

  summary(){
    return this.http.get(`${environment.apiUrl}/api/ticket/summary`);
  }
}
