<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Nova Matrícula</mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">
          <!-- ALUNO -->
          <mat-horizontal-stepper [linear]="isLinear" #stepper class="col-sm-12">
            <mat-step [stepControl]="form">
              <ng-template matStepLabel>Aluno</ng-template>
              <mat-form-field appearance="outline" class="col-sm-12">
                <mat-label for="aluno">Aluno</mat-label>
                <!--mat-select placeholder="Nome do Aluno" id="aluno" formControlName="aluno" required>
                  <mat-option *ngFor="let aluno of alunos | async" [value]="aluno"> {{ aluno?.nome }}</mat-option>
                </mat-select-->
                <input placeholder="Selecione o Aluno" matInput [matAutocomplete]="auto" id="aluno" formControlName="aluno"
                  required>
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <mat-option *ngFor="let aluno of alunos | async" [value]="aluno">
                    {{aluno?.nome}}
                  </mat-option>
                </mat-autocomplete>
                <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                <mat-error>Aluno(a) é obrigatório</mat-error>
              </mat-form-field>
              <div>
                <button mat-raised-button color="warn" matStepperNext type="button">Próximo</button>
              </div>
            </mat-step>
            <!-- TURMA -->
            <mat-step [stepControl]="form">
              <ng-template matStepLabel>Turma</ng-template>
              <mat-form-field appearance="outline" class="col-sm-12">
                <mat-label for="turma">Turma</mat-label>
                <mat-select placeholder="Selecione a Turma" id="turma" formControlName="turma" required>
                  <mat-option *ngFor="let turma of turmas | async" [value]="turma"> Turma:
                    {{turma?.nome | uppercase}}</mat-option>
                </mat-select>
                <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                <mat-error>Selecionar turma é obrigatório</mat-error>
              </mat-form-field>
              <div>
                <button mat-raised-button matStepperPrevious type="button">Anterior</button>
                <button mat-raised-button color="warn" matStepperNext type="button">Próximo</button>
              </div>
            </mat-step>

            <!-- VALOR -->
            <!--mat-step [stepControl]="form">
              <ng-template matStepLabel>Selecione o Valor</ng-template>
              <mat-form-field appearance="outline" class="col-sm-12">
                <mat-label for="valor">Valor</mat-label>
                <input matInput placeholder="R$ 550,00" id="valor" type="number" formControlName="valor" [mask]="maskMoney"
                  required>
                <mat-error>Valor é obrigatório</mat-error>
              </mat-form-field>
              <div>
                <button mat-raised-button matStepperPrevious type="button">Anterior</button>
                <button mat-raised-button color="primary" matStepperNext type="button">Próximo</button>
              </div>
            </mat-step-->

            <!-- DATA -->
            <mat-step [stepControl]="form">
              <ng-template matStepLabel>Data da matrícula</ng-template>
              <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                <input matInput placeholder="Selecione a data" id="data" formControlName="data" [matDatepicker]="data"
                readonly="true" required>
                <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                <mat-datepicker touchUi #data></mat-datepicker>
                <mat-error>Data é obrigatória</mat-error>
              </mat-form-field>
              <div>
                <button mat-raised-button matStepperPrevious type="button">Anterior</button>
              </div>
            </mat-step>

            <!--PAGAMENTO -->
            <!--mat-step [stepControl]="form">
              <form [formGroup]="form">
                <ng-template matStepLabel>Forma de pagamento</ng-template>

                <mat-radio-group id="pagamento" formControlName="pagamento">
                  <label class="mat-button">
                    <mat-radio-button value="AVISTA">A Vista</mat-radio-button>
                  </label>
                  <label class="mat-button">
                    <mat-radio-button value="APRAZO">A Prazo</mat-radio-button>
                  </label>
                </mat-radio-group>

                <div class="row">
                  <div class="form-group" *ngIf="form.get('pagamento').value == 'APRAZO'">
                    <div class="col-sm-2">
                      <mat-form-field class="col-sm-12">
                        <input matInput placeholder="Nº Parcelas" formControlName="parcela" id="parcela" type="number"
                          required>
                        <mat-error>Nº é obrigatório</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-sm-2">
                      <button mat-stroked-button color="accent"  [disabled]="form.get('parcela').value < 0" (click)="valorParcela()">Gerar Parcelas</button>
                    </div>
                  </div>

                </div>
                <div class="row">
                  <div class="form-group" *ngIf="form.get('pagamento').value == 'APRAZO'">

                  </div>
                </div>

                <div class="row">
                  <div class="form-group" *ngIf="form.get('pagamento').value == 'APRAZO'">
                    <div class="col-sm-8">
                      <div class="box">
                        <div class="box-body">
                          <table class="table table-bordered">
                            <tr>
                              <th>Valor</th>
                              <th>Vencimento</th>
                              <th>Aluno</th>
                            </tr>
                            <tr *ngFor="let parcela of parcelaList">
                              <td>{{parcela?.valor | currency: 'BRL'}}</td>
                              <td>{{parcela?.vencimento | date }}</td>
                              <td>{{parcela.aluno?.nome}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button mat-raised-button matStepperPrevious type="button">Anterior</button>
                  <button mat-raised-button color="primary" matStepperNext type="button">Próximo</button>
                </div>
              </form>
            </mat-step-->

            <!--mat-step>
              <ng-template matStepLabel>Finalizar</ng-template>

              <div>
                <button mat-raised-button matStepperPrevious type="button">Anterior</button>

              </div>
            </mat-step-->
          </mat-horizontal-stepper>
          <div class="container-fluid">
            <div class="buttons">
              <button mat-raised-button color="warn">Salvar</button>
            </div>
            <div class="listas">
                <button mat-raised-button color="primary" type="button" (click)="listarMatriculas()">Lista de matriculas</button>
              </div>
          </div>
          <!--app-form-debug [formularioDebug]="form"></app-form-debug-->
        </form>
      </div>
    </div>
    <div *ngIf="isLoading" class="spinner" text-center>
      <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
    </div>
  </div>
</div>
