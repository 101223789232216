<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Nova Lição</mat-card-title>
          </mat-card>
        </div>
        <form [formGroup]="form" class="form horizontal" (ngSubmit)="register()" #f="ngForm">
          <div class="row">
            <div class="form-group">
              <div class="col-sm-3">
                <mat-form-field appearance="outline" class="example-full-width" class="col-sm-12">
                  <input matInput placeholder="Data da chamada" id="data" formControlName="data" [matDatepicker]="data"
                    readonly="true" required>
                  <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
                  <mat-datepicker touchUi #data></mat-datepicker>
                  <mat-error>Data é obrigatória</mat-error>
                </mat-form-field>
              </div>


              <div class="col-sm-4">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="turma">Turma</mat-label>
                  <mat-select placeholder="Turma" id="turma" formControlName="turma" required>
                    <mat-option *ngFor="let turma of turmas | async" [value]="turma"> {{ turma?.nome }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Turma é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-5">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="professor">Professor</mat-label>
                  <mat-select placeholder="Professor" id="professor" formControlName="professor" required>
                    <mat-option *ngFor="let professor of professores | async" [value]="professor">
                      {{ professor?.nome }}
                    </mat-option>
                  </mat-select>
                  <mat-error>Professor(a) é obrigatório</mat-error>
                </mat-form-field>
              </div>

            </div>
          </div>


          <div class="row">
            <div class="form-group">

              <div class="col-sm-12">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="tema">Tema</mat-label>
                  <input matInput placeholder="Tema" id="tema" formControlName="tema" required>
                  <mat-error>Tema é obrigatório</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-12">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="objetivo">Objetivos:</mat-label>
                  <input matInput placeholder="Objetivos gerais e específicos" id="objetivo" formControlName="objetivo"
                    required>
                  <mat-error>Objetivo é obrigatório</mat-error>
                </mat-form-field>
              </div>
              <div class="editor">
                <div class="col-sm-12">

                  <mat-label for="conteudo">Conteúdo</mat-label>
                  <ckeditor [config]="editorConfig" id="conteudo" style="height: 300px;" formControlName="conteudo" [editor]="Editor" (ready)="onReady($event)" [(ngModel)]="editorContent"></ckeditor>
                  <mat-error *ngIf="conteudo">O Conteúdo é obrigatório</mat-error>
                </div>
              </div>
              <div class="col-sm-12">
                <mat-form-field appearance="outline" class="col-sm-12">
                  <mat-label for="metodologia">Metodologia</mat-label>
                  <input matInput placeholder="Metodologia" id="metodologia" formControlName="metodologia" required>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                  <mat-error>Metodologia é obrigatória</mat-error>
                </mat-form-field>
              </div>

              <div class="col-sm-12">
                <mat-form-field appearance="fill" class="col-sm-12">
                  <mat-label for="experienciaPedagogica">Experiência Pedagógica</mat-label>
                  <textarea matInput placeholder="Experiência pedagógica" id="experienciaPedagogica"
                    formControlName="experienciaPedagogica" rows="5" cols="40"> </textarea>
                  <!--mat-icon matSuffix>sentiment_very_satisfied</mat-icon-->
                </mat-form-field>
              </div>

              <div class="col-sm-12">
                <div>
                  <img *ngIf="this.form.get('imagem').value" [src]="this.form.get('imagem').value" width="50%"
                    height="50%">
                  <input type="file" id="fileToUpload" (change)="onFileChange3($event)" style="display:none;" />
                </div>
                <div class="box-header with-border">
                  <mat-card>
                    <mat-card-subtitle>Mais opções</mat-card-subtitle>
                    <mat-card-actions>
                      <button mat-icon-button matTooltip="Inserir foto" type="button"
                        onclick="document.getElementById('fileToUpload').click()">
                        <mat-icon>insert_photo</mat-icon>
                      </button>
                    </mat-card-actions>
                  </mat-card>
                </div>

              </div>

            </div>
          </div>

          <div class="container-fluid">
            <div class="buttons">
              <button mat-raised-button color="warn">Salvar</button>
            </div>
            <div class="listas">
              <button mat-raised-button color="primary" type="button" (click)="listaLicoes()">Lista de lições</button>
            </div>
          </div>
          <!--app-form-debug [formularioDebug]="form"> listaLicoes </app-form-debug-->
        </form>
      </div>
    </div>

    <div *ngIf="isLoading" class="spinner" text-center>
      <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
    </div>
  </div>
</div>
