import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-menu-relatorio',
  templateUrl: './menu-relatorio.component.html',
  styleUrls: ['./menu-relatorio.component.css']
})
export class MenuRelatorioComponent implements OnInit {

  constructor(private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
  }

  resumoFrequencia() {
    this.router.navigate(['/resumo']);
  }

  porTurma() {
    this.router.navigate(['/relatorio-frequencia-turma']);
  }

  porAluno() {
    this.router.navigate(['/relatorio-frequencia-aluno']);
  }

  aniversariantes() {
    this.router.navigate(['/aniversariantes']);
  }

  fecharDialog() {
    this.dialog.closeAll();
  }

}
