<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Lista de Alunos</mat-card-title>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">


          <form (ngSubmit)="pesquisar()">
            <div class="row">
              <div class="form-group">
                <div class="col-sm-10">
                  <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label for="nomeAluno">Pesquisar...</mat-label>
                    <input matInput placeholder="Digite o nome" id="nomeAluno" name="nomeAluno" [(ngModel)]="nomeAluno">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="buttons">
              <button mat-raised-button color="primary" type="submit">Pesquisar</button>
            </div>
          </form>

          <div class="table-responsive">
            <div *ngIf="!listAluno || listAluno.length <=0">
              <p class="box-body">Nenhum registro encontrado!</p>
            </div>
            <table class="table" *ngIf="listAluno && listAluno.length > 0">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">Data de nascimento</th>
                  <th scope="col">Status</th>
                  <th scope="col" style="width: 10px">Ações</th>
                  <th scope="col" style="width: 10px">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let aluno of listAluno">
                  <td>
                    <img *ngIf="aluno.imagem" mat-card-avatar class="example-header-image"
                    src="{{ aluno?.imagem }}" />
                    {{aluno?.nome}}
                  </td>
                  <td>{{aluno?.telefone}}</td>
                  <td>{{aluno?.data | date}}</td>
                  <td>
                    <mat-icon *ngIf="aluno?.status === false" matTooltip="Ativo" style="color:green">done_outline
                    </mat-icon>
                    <mat-icon *ngIf="aluno?.status === true" matTooltip="Inativo" style="color:#E64A19">
                      person_add_disabled</mat-icon>
                  </td>
                  <td>
                    <!--td *ngIf="shared.user.profile !== 'PROFESSOR'"></td-->
                    <mat-button-toggle (click)="edit(aluno.id)" matTooltip="Editar"
                      matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                      <mat-icon style="color: #0013e9">edit</mat-icon>
                    </mat-button-toggle>
                  </td>
                  <td>
                    <mat-button-toggle *ngIf="aluno?.status === false" (click)="desativarAluno(aluno.id)"
                      matTooltip="Inativar" matTooltipClass="example-tooltip-red"
                      aria-label="Button that shows a red tooltip">
                      <mat-icon style="color: #E64A19">visibility</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle *ngIf="aluno?.status === true" (click)="ativarAluno(aluno.id)" matTooltip="Ativar"
                      matTooltipClass="example-tooltip-red" aria-label="Button that shows a red tooltip">
                      <mat-icon style="color: green">visibility_off</mat-icon>
                    </mat-button-toggle>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- PAGINAÇÃO -->
        <div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div>

        <div class="row" *ngIf="listAluno && listAluno.length > 0">
          <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
            <mat-card class="info">
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Ativo" style="color:green">done_outline
                      </mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ativo</td>
                  </tr>
                </div>
              </div>
              <div class="row">
                <div class="form-group">
                  <tr>
                    <td>
                      <mat-icon matTooltip="Inativo" style="color:#E64A19">
                        person_add_disabled</mat-icon>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inativo</td>
                  </tr>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
