import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GROWMIND_API } from './helpdesk.api';
import { EstadoBr } from '../model/estado-br.model';

@Injectable()
export class EstadosService {

  constructor(private http: HttpClient) { }

  getEstadosBr() {
    return this.http.get<EstadoBr[]>('assets/dados/estadosbr.json');
  }

 /* findAll(page: number, count:number){
    return this.http.get(`${GROWMIND_API}/api/aluno/${page}/${count}`);
  }*/
}
