import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatIconModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMaskModule } from '@fagnerlima/ng-mask';
import { ComponentsModule } from '../../components.module';
import { LicaoDetalhesModule } from '../licao-detalhes/licao-detalhes.module';
import { LicaoListagemComponent } from './licao-listagem.component';

@NgModule({
  imports: [
    CommonModule,
    LicaoDetalhesModule,
    ComponentsModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    NgMaskModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatPaginatorModule,
    ComponentsModule,
  ],
  declarations: [LicaoListagemComponent],
  exports: [LicaoListagemComponent],
})
export class LicaoListagemModule {}
