import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FrequenciaDetalhesService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(detalhes) {
        if (detalhes.id != null && detalhes.id !== '') {
            console.log(detalhes.id);
            return this.http.put(`${environment.apiUrl}/api/frequenciaDetalhes`, detalhes);
        }
        else {
            detalhes.id = null;
            console.log(detalhes.id);
            return this.http.post(`${environment.apiUrl}/api/frequenciaDetalhes`, detalhes);
        }
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/frequenciaDetalhes/${id}`);
    }
    findByFrequencia(frequencia) {
        return this.http.get(`${environment.apiUrl}/api/frequenciaDetalhes/byFrequencia/${frequencia}`);
    }
}
FrequenciaDetalhesService.ngInjectableDef = i0.defineInjectable({ factory: function FrequenciaDetalhesService_Factory() { return new FrequenciaDetalhesService(i0.inject(i1.HttpClient)); }, token: FrequenciaDetalhesService, providedIn: "root" });
