import { TurmaService } from './../../services/turma.service';
import { Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { DialogService } from '../../../dialog.service';
import { CursoService } from '../../services/curso.service';
import { ResponseApi } from '../../model/response-api';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-turma-list',
  templateUrl: './turma-list.component.html',
  styleUrls: ['./turma-list.component.css']
})
export class TurmaListComponent implements OnInit {
  page = 0;
  count = 20;
  pages: Array<number>;
  shared: SharedService;
  message: {};
  classCss: {};
  listTurma = [];
  isLoading = false;
  nomeTurma: string;

  constructor(
    private dialogService: DialogService,
    private turmaService: TurmaService,
    private router: Router
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.findAll(this.page, this.count);
  }

  pesquisar() {
    this.isLoading = true;
    if (this.nomeTurma) {

      this.turmaService.findByNomeContains(this.nomeTurma).subscribe((responseApi: ResponseApi) => {
        console.log(responseApi);
        this.listTurma = responseApi.data;
        this.isLoading = false;
      });
    } else {
      this.findAll(this.page, this.count);
      this.isLoading = false;
    }
  }

  findAll(page: number, count: number) {
    this.isLoading = true;
    this.turmaService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
      // console.log(responseApi);
        this.listTurma = responseApi['data']['content'];
        this.pages = new Array(responseApi['data']['totalPages']);
        this.isLoading = false;
      },
      err => {
        this.showMessage({
          type: 'error',
          text: err['error']['errors'][0]
        });
      }
    );
  }

  edit(id: string) {
    this.router.navigate(['/turma-new', id]);
  }

  ativarTurma(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja ativar esta turma?',
      text: 'Você irá ativar!',
      type: 'question',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, ative!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.turmaService.ativarTurma(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Turma ativada com sucesso!',
          'Turma ativada.',
          'success'
        );
      }
    });
  }

  desativarTurma(id: string) {
    Swal.fire({
      title: 'Tem certeza que deseja inativar esta turma?',
      text: 'Você irá inativar!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, Ok!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.turmaService.desativarTurma(id).subscribe(() => {
          this.findAll(this.page, this.count);
        }, err => {
          this.alertaError();
        });
        Swal.fire(
          'Turma inativada com sucesso!',
          'Turma inativada.',
          'success'
        );
      }
    });
  }

  delete(id: string) {
    this.dialogService
      .confirm('Você deseja desativar esta Turma ?')
      .then((canDelete: boolean)  => {
        if (canDelete) {
          this.message = {};
          this.turmaService.delete(id).subscribe(
            (responseApi: ResponseApi) => {
              this.showMessage({
                type: 'success',
                text: 'Registro desativado'
              });
              this.findAll(this.page, this.count);
            },
            err => {
              this.showMessage({
                type: 'error',
                text: err['error']['errors'][0]
              });
            }
          );
        }
      });
  }

  alertaError() {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'Algo deu errado!'
    });
  }

  private showMessage(message: { type: string; text: string }): void {
    this.message = message;
    this.buildClasses(message.type);
    setTimeout(() => {
      this.message = undefined;
    }, 3000);
  }

  private buildClasses(type: string): void {
    this.classCss = {
      alert: true
    };
    this.classCss['alert-' + type] = true;
  }

  setPreviousPage(event: any) {
    event.preventDefault();
    if (this.page > 0) {
      this.page = this.page - 1;
      this.findAll(this.page, this.count);
    }
  }

  setPage(i, event: any) {
    event.preventDefault();
    this.page = i;
    this.findAll(this.page, this.count);
  }

  setNextPage(event: any) {
    event.preventDefault();
    if (this.page + 1 < this.pages.length) {
      this.page = this.page + 1;
      this.findAll(this.page, this.count);
    }
  }
}
