import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TurmaService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(turma) {
        if (turma.id != null && turma.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/turma`, turma);
        }
        else {
            turma.id = null;
            return this.http.post(`${environment.apiUrl}/api/turma`, turma);
        }
    }
    findAllTurmasAtivas(page, count) {
        return this.http.get(`${environment.apiUrl}/api/turma/${page}/${count}`).pipe(map(response => {
            console.log(response);
            return response.data.content.filter(turma => turma.status === false);
        }));
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/turma/${page}/${count}`);
    }
    findAllTurma() {
        return this.http.get(`${environment.apiUrl}/api/turma/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/turma/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/turma/${id}`);
    }
    findByNomeContains(nomeTurma) {
        return this.http.get(`${environment.apiUrl}/api/turma/byName/${nomeTurma}`);
    }
    ativarTurma(id) {
        return this.http.post(`${environment.apiUrl}/api/turma/ativar`, id);
    }
    desativarTurma(desativar) {
        return this.http.post(`${environment.apiUrl}/api/turma/desativar`, desativar);
    }
}
TurmaService.ngInjectableDef = i0.defineInjectable({ factory: function TurmaService_Factory() { return new TurmaService(i0.inject(i1.HttpClient)); }, token: TurmaService, providedIn: "root" });
