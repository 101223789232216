import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatPaginator, MatTableDataSource, MatSort, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { LancamentoService } from '../../../services/lancamento.service';
import { SharedService } from '../../../services/shared.service';
import { map, takeWhile } from 'rxjs/operators';
import { forkJoin, interval } from 'rxjs';
import { DetalhesLancamentoComponent } from '../detalhes-lancamento/detalhes-lancamento.component';
import { PaginatorComponent } from '../../paginator/paginator.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-listagem-lancamento',
  templateUrl: './listagem-lancamento.component.html',
  styleUrls: ['./listagem-lancamento.component.css']
})
export class ListagemLancamentoComponent implements OnInit {

  carregando = false;
  ativo = true;

  shared: SharedService;
  tema: string;
  @ViewChild('f') form;
  formulario: FormGroup;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(PaginatorComponent)
  public paginator: PaginatorComponent;
  pageSize = 10;
  length = 0;
  pageEvent: PageEvent;

  dataSource = new MatTableDataSource<any>();
  tempDataSource: any;
  // aluno: string;

  // displayedColumns: string[] = ['data', 'aluno', 'situação', 'bimestre'];
  displayedColumns: string[] = ['descricao', 'observacao', 'acoes'];

  constructor(
    private dialog: MatDialog,
    private builder: FormBuilder,
    private router: Router,
    private lancamentoService: LancamentoService
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.formulario = this.builder.group({
      pesquisa: [null]
    })
    // this.paginator.pageSize = this.pageSize;

    this.lancamentoService.onSave()
      .pipe(takeWhile(() => this.ativo))
      .subscribe((value) => {
        if(value == null) return;
        const newData = [...this.dataSource.data]
        const indexPaciente = newData.findIndex(data => data.id === value.id)
        if (indexPaciente > -1) {
          newData[indexPaciente] = value
        } else {
          newData.push(value)
        }
        this.dataSource.data = newData
      })

    const subs = interval(500)
      .subscribe(() => {
        if (this.paginator) {
          subs.unsubscribe()
          this.pesquisar()
        }
      })
  }

  ngOnDestroy() {
    this.ativo = false;
  }

  pesquisar(reset = true) {
    this.carregando = true;
    if (reset) {
      // this.dataSource.data = [];
      this.paginator.page = 1
    }

    const pesquisa = this.formulario.get('pesquisa').value
    forkJoin([
      this.lancamentoService.findPageCount(pesquisa, null, this.paginator.pageSize),
      this.lancamentoService.findPage(
        pesquisa,
        null,
        this.paginator.page - 1,
        this.paginator.pageSize
      ).pipe(map(values => values.map(value => {
        return value;
      })))
    ])
      .pipe(
        takeWhile(() => this.ativo),
      )
      .subscribe(([pageCount, values]) => {
        this.carregando = false;
        this.dataSource.data = values;
        this.paginator.totalPages = pageCount;
      }, err => {
        this.carregando = false;
        throw err;
      });
  }

  criar() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '80%';
    dialogConfig.height = '80%';
    this.dialog.open(DetalhesLancamentoComponent);
  }

  alterar(event: any) {
    this.dialog.open(DetalhesLancamentoComponent, { data: event});
  }

  deletar(event) {
    Swal.fire({
      title: 'Tem certeza que deseja deletar o Lancamento?',
      text: 'Você irá DELETAR!',
      type: 'warning',
      showCancelButton: true,

      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, delete!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.lancamentoService.delete(event).subscribe(() => {
          this.pesquisar();
        }, () => {
          this.alertaError();
        });
        Swal.fire(
          'Lancamento deletado com sucesso!',
          'O Lancamento está deletado.',
          'success'
        );
      }
    });
  }

  alertaError() {
    Swal.fire({
      type: 'error',
      title: 'Oops...',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.'
    });
  }


}
