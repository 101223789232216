import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MatriculaService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(matricula) {
        if (matricula.id != null && matricula.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/matricula`, matricula);
        }
        else {
            matricula.id = null;
            return this.http.post(`${environment.apiUrl}/api/matricula`, matricula);
        }
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/matricula/${page}/${count}`);
    }
    findAllMatricula() {
        return this.http.get(`${environment.apiUrl}/api/matricula/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/matricula/${id}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/matricula/${id}`);
    }
    findByTurmaId(idTurma) {
        return this.http.get(`${environment.apiUrl}/api/matricula/byTurmaId/${idTurma}`);
    }
    findByAlunoId(idAluno) {
        return this.http.get(`${environment.apiUrl}/api/matricula/byAlunoId/${idAluno}`);
    }
    pesquisar(aluno) {
        return this.http.get(`${environment.apiUrl}/api/matricula/byName/${aluno}`);
    }
    pesquisarByName(aluno) {
        return this.http.get(`${environment.apiUrl}/api/matricula/byNameMatricula/${aluno}`);
    }
    ativarMatricula(id) {
        return this.http.post(`${environment.apiUrl}/api/matricula/ativar`, id);
    }
    desativarMatricula(desativar) {
        return this.http.post(`${environment.apiUrl}/api/matricula/desativar`, desativar);
    }
    findByMatricula(aluno) {
        return this.http.get(`${environment.apiUrl}/api/matricula/${aluno}`);
    }
}
MatriculaService.ngInjectableDef = i0.defineInjectable({ factory: function MatriculaService_Factory() { return new MatriculaService(i0.inject(i1.HttpClient)); }, token: MatriculaService, providedIn: "root" });
