var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LicaoV1Service } from "./../../../services/licaov1.service";
import { ApplicationRef, ChangeDetectorRef, OnInit, } from "@angular/core";
import { FormBuilder, NgForm, Validators } from "@angular/forms";
import { SharedService } from "../../../services/shared.service";
import { forkJoin, of } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { TurmaService } from "../../../services/turma.service";
import { UserService } from "../../../services/user.service";
import * as DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { concatMap, finalize, first, map, takeWhile, } from "rxjs/operators";
import { UtilitariosService } from "../../../services/utilitario.service";
import { LicaoEntity, } from "../../../model/licao_entity.model";
import { DatePipe } from "@angular/common";
import { Arquivo } from "../../../model/arquivo.model";
import * as moment from "moment";
import Swal from "sweetalert2";
import { CarregandoComponent } from "../../../shared/Avisos/carregando/carregando.component";
import { environment } from "../../../../environments/environment";
import { HttpClient } from "@angular/common/http";
export class LicaoDetalhesComponent {
    constructor(route, formBuilder, turmaService, licaoService, userService, cd, dialog, router, utils, appRef, datepipe, http, data) {
        this.route = route;
        this.formBuilder = formBuilder;
        this.turmaService = turmaService;
        this.licaoService = licaoService;
        this.userService = userService;
        this.cd = cd;
        this.dialog = dialog;
        this.router = router;
        this.utils = utils;
        this.appRef = appRef;
        this.datepipe = datepipe;
        this.http = http;
        this.data = data;
        this.Editor = DecoupledEditor;
        this.editorContent = "";
        this.professores = null;
        this.carregando = false;
        this.ativo = true;
        this.id = null;
        this.hasProfessor = false;
        this.base_url = environment.apiUrl;
        this.customUploadAdapterPlugin = (editor) => {
            editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
                return {
                    upload: () => {
                        return loader.file.then((file) => {
                            return new Promise((resolve, reject) => {
                                const formData = new FormData();
                                formData.append("file", file);
                                return this.http
                                    .post(`${this.base_url}/api/upload/upload-image`, formData, { responseType: "text" })
                                    .subscribe((link) => {
                                    resolve({ default: link });
                                }, (error) => {
                                    console.error("Erro no upload:", error);
                                    reject("Falha no upload");
                                });
                            }).catch((error) => {
                                console.error("Erro na promessa:", error);
                            });
                        });
                    },
                };
            };
        };
        this.shared = SharedService.getInstance();
    }
    onReady(editor) {
        editor.ui
            .getEditableElement()
            .parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
        editor.model.document.on("change:data", () => {
            this.editorContent = editor.getData();
        });
    }
    ngOnInit() {
        this.editorConfig = {
            placeholder: "Digite aqui o conteúdo planejado...",
            toolbar: {
                items: [
                    "undo",
                    "redo",
                    "heading",
                    "|",
                    "fontSize",
                    "fontFamily",
                    "|",
                    "fontColor",
                    "fontBackgroundColor",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "|",
                    "link",
                    "blockQuote",
                    "insertTable",
                    "imageUpload",
                    "mediaEmbed",
                    "|",
                    "alignment",
                    "|",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                ],
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: "http://",
                openInNewTab: {
                    mode: "manual",
                    label: "Open in new tab",
                    defaultValue: true,
                    attributes: {
                        target: "_blank",
                        rel: "noopener noreferrer",
                    },
                },
            },
        };
        this.carregando = true;
        this.form = this.formBuilder.group({
            id: [null],
            data: [new Date(), Validators.required],
            turma: [null, Validators.required],
            professor: [null, Validators.required],
            tema: [null, Validators.required],
            objetivo: [null, Validators.required],
            conteudo: [null, Validators.required],
            metodologia: [null, Validators.required],
            experienciaPedagogica: [null],
            imagens: [null],
            status: [null],
        });
        if (this.shared.user.profile == "PROFESSOR") {
            const professorSelecionado = this.shared.user;
            this.turmas = this.turmaService
                .findById(professorSelecionado.turmaId)
                .pipe(map((turmas) => {
                if (turmas.data && turmas.data.status === false) {
                    return [turmas.data];
                }
                else {
                    return [];
                }
            }));
            this.turmas
                .pipe(map((turmas) => {
                if (!professorSelecionado || !professorSelecionado.turmaId) {
                    return null;
                }
                return turmas.find((turma) => turma.id == professorSelecionado.turmaId);
            }), first())
                .subscribe((turmaSelecionada) => {
                if (turmaSelecionada) {
                    this.selectedTurma = turmaSelecionada;
                    this.form.get("turma").setValue(this.selectedTurma);
                    if (!this.data) {
                        if (this.professores == null) {
                            this.professores = this.addProfessor(professorSelecionado);
                        }
                        this.setProfessor(professorSelecionado);
                    }
                }
                this.carregando = false;
            });
        }
        else {
            this.turmas = this.turmaService.findAllTurma().pipe(map((turmas) => {
                return turmas.data.filter((turma) => turma.status === false);
            }), finalize(() => {
                this.carregando = false;
            }));
        }
        // if (!this.data) {
        //   this.carregando = true;
        //   if (this.utils.getDadosLocalStorage(this.constructor.name)) {
        //     const dadosRecuperados = this.utils.getDadosLocalStorage(
        //       this.constructor.name
        //     );
        //     this.utils.preencherFormGroup(this.form, dadosRecuperados);
        //     this.form.get("id").setValue(null);
        //     if (dadosRecuperados.data == null) {
        //       this.form.get("data").setValue(new Date());
        //     }
        //     if (dadosRecuperados.turma) {
        //       this.onTurmaSelected(dadosRecuperados.turma);
        //     } else {
        //       this.carregando = false;
        //     }
        //   } else {
        //     this.carregando = false;
        //   }
        // }
        if (this.data) {
            this.carregando = true;
            forkJoin([this.licaoService.findById(this.data, "")])
                .pipe(takeWhile(() => this.ativo))
                .subscribe(([licao]) => {
                // this.alunos = alunos
                this.form.get("id").setValue(licao.id);
                this.form.get("data").setValue(licao.data);
                this.form.get("turma").setValue(licao.turma);
                // this.form.get("professor").setValue(licao.professor);
                this.form.get("tema").setValue(licao.tema);
                this.form.get("objetivo").setValue(licao.objetivo);
                this.form.get("conteudo").setValue(licao.conteudo);
                this.form.get("metodologia").setValue(licao.metodologia);
                this.form
                    .get("experienciaPedagogica")
                    .setValue(licao.experienciaPedagogica);
                this.form.get("status").setValue(licao.status);
                licao.imagens.forEach((imagem, index) => {
                    this.urlToFile(imagem, "image_" + (index + 1)).then((file) => {
                        const imagens = this.form.get("imagens").value || [];
                        const urlLink = this.objectToString(imagem);
                        imagens.push({ file, preview: urlLink.toString() });
                        this.form.get("imagens").setValue(imagens);
                    });
                });
                if (this.professores == null) {
                    this.professores = this.addProfessor(licao.professor);
                }
                this.setProfessor(licao.professor);
                this.appRef.tick();
                const interval = setInterval(() => {
                    const imagens = this.form.get("imagens").value || [];
                    if (licao.imagens.length === imagens.length) {
                        this.carregando = false;
                        clearInterval(interval);
                    }
                }, 300);
                this.carregando = false;
            }, (err) => {
                this.carregando = false;
                throw err;
            });
        }
        //  else {
        //   this.form.valueChanges
        //     .pipe(
        //       takeWhile(() => this.ativo),
        //       debounceTime(2000)
        //     )
        //     .subscribe((value) => {
        //       if (!this.id) {
        //         this.utils.setDadosLocalStorage(this.constructor.name, value);
        //       }
        //     });
        // }
    }
    setProfessor(professorSelecionado) {
        this.selectedProfessor = {
            id: professorSelecionado.id,
            email: professorSelecionado.email,
            password: professorSelecionado.password,
            profile: professorSelecionado.profile,
            nome: professorSelecionado.nome,
            telefone: professorSelecionado.telefone,
            imagem: professorSelecionado.imagem,
            status: professorSelecionado.status,
            turmaId: professorSelecionado.turmaId,
        };
        this.form.get("professor").setValue(this.selectedProfessor);
    }
    salvar() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.form.valid) {
                this.abrirConfirmacao();
                this.carregando = true;
                const licao = this.licao || new LicaoEntity();
                licao.id = this.form.get("id").value;
                licao.data = this.stringToDate(this.form.get("data").value);
                licao.turma = this.form.get("turma").value;
                licao.professor = this.form.get("professor").value;
                licao.tema = this.form.get("tema").value;
                licao.objetivo = this.form.get("objetivo").value;
                licao.conteudo = this.form.get("conteudo").value;
                licao.metodologia = this.form.get("metodologia").value;
                licao.experienciaPedagogica = this.form.get("experienciaPedagogica").value;
                licao.status = false;
                const images = this.form.get("imagens").value;
                if (images && images.length > 0) {
                    const arquivos = [];
                    for (const imagem of images) {
                        if (imagem && imagem.preview.includes("base64")) {
                            arquivos.push(Arquivo.createFile(imagem.file));
                        }
                        else if (imagem && imagem.preview) {
                            arquivos.push(Arquivo.createString(imagem.preview));
                        }
                    }
                    licao.imagens = arquivos;
                }
                this.licaoService
                    .save(licao)
                    .pipe(takeWhile(() => this.ativo))
                    .subscribe((response) => {
                    this.dialog.closeAll();
                    this.carregando = false;
                    this.alertaSucesso();
                    if (this.form.get("id").value) {
                        this.voltar();
                    }
                    else {
                        this.reset();
                    }
                    return response;
                }, (err) => {
                    this.carregando = false;
                    try {
                        this.alertaError();
                    }
                    catch (error) {
                        //erro
                    }
                });
            }
            else {
                this.form.markAsTouched();
            }
        });
    }
    voltar() {
        this.dialog.closeAll();
    }
    adicionarImagens(imagens) {
        imagens.forEach((imagem, index) => {
            const images = this.form.get("imagens").value || [];
            images.push({ file: null, preview: imagem.link });
            this.form.get("imagens").setValue(images);
        });
    }
    compareProfessor(professor1, professor2) {
        return professor1 && professor2
            ? professor1.id === professor2.id
            : professor1 === professor2;
    }
    compareTurma(turma1, turma2) {
        return turma1 && turma2 ? turma1.id === turma2.id : turma1 === turma2;
    }
    onTurmaSelected(turma) {
        const isProfessor = this.shared.user.profile == "PROFESSOR";
        let turmaId = isProfessor ? this.shared.user.turmaId : turma.id;
        this.professores = this.userService.findByTurmaId(turmaId).pipe(map((users) => {
            if (users && users.length > 0)
                this.hasProfessor = true;
            if (isProfessor) {
                let filteredUser = users.filter((user) => user.id === this.shared.user.id);
                this.setProfessor(filteredUser[0]);
            }
            else {
                this.setProfessor(users[0]);
            }
            this.carregando = false;
            return users;
        }));
    }
    deleteImage({ preview }) {
        if (!preview.includes("base64")) {
            this.carregando = true;
            this.licaoService.removerImagem(this.data, preview).subscribe(() => {
                this.carregando = false;
            }, (e) => {
                this.carregando = false;
                throw e;
            });
        }
    }
    hasImageToSave() {
        const images = this.form.get("imagens").value || [];
        const newImageFiles = images
            .filter((image) => image.file && image.preview.includes("base64"))
            .map((image) => image.file);
        return newImageFiles.length > 0;
    }
    urlToFile(url, fileName) {
        return __awaiter(this, void 0, void 0, function* () {
            const urlLink = this.objectToString(url);
            let response = yield fetch(urlLink.toString());
            let data = yield response.blob();
            return new File([data], fileName);
        });
    }
    objectToString(imagem) {
        return imagem.link;
    }
    stringToDate(value) {
        if (!value)
            return null;
        let convertDate = this.datepipe.transform(value, "dd/MM/yyyy");
        let dataString = "";
        const itens = convertDate.split(" ");
        let data = null;
        if (itens && itens.length > 0) {
            data = itens[0].split("/");
            if (data.length < 3)
                return null;
            else
                dataString = data[2] + "-" + data[1] + "-" + data[0];
        }
        if (itens && itens.length > 1)
            dataString += " " + itens[1];
        if (!moment(new Date(dataString)).isValid())
            return null;
        return moment(new Date(dataString)).toDate();
    }
    reset() {
        this.form.reset();
        this.form.markAsUntouched();
        this.form.markAsPending();
        this.form.markAsPristine();
        this.carregando = false;
        this.utils.removeDadosLocalStorage(this.constructor.name);
    }
    abrirConfirmacao() {
        this.dialog.open(CarregandoComponent, {
            width: "350px",
        });
    }
    alertaSucesso() {
        Swal.fire({
            title: "Lição salva com sucesso!",
            type: "success",
            footer: "Igreja Batista Nacional Ebenézer",
        });
    }
    alertaError() {
        Swal.fire({
            type: "error",
            title: "Oops...",
            html: "O servidor encontrou um possível erro, verifique:<br> 1. Imagem maior que 5MB.<br> 2. Problema na conexão.",
        });
    }
    addProfessor(licaoProfessor) {
        return this.ensureProfessoresObservable().pipe(concatMap((users) => {
            const updatedUsers = [...users, licaoProfessor];
            return of(updatedUsers);
        }));
    }
    ensureProfessoresObservable() {
        if (!this.professores) {
            this.professores = of([]);
        }
        return this.professores;
    }
}
