import { Matricula } from './../../model/matricula.model';
import { MatriculaService } from './../../services/matricula.service';
import { OnInit, Component, ViewChild } from '@angular/core';
import { FrequenciaService } from '../../services/frequencia.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TurmaService } from '../../services/turma.service';
import { DialogService } from '../../../dialog.service';
import { SharedService } from '../../services/shared.service';
import { Validators, NgForm, FormGroup, FormBuilder } from '@angular/forms';
import { ResponseApi } from '../../model/response-api';
import { Frequencia } from '../../model/frequencia.model';
import { MatDialog, MatCheckboxChange } from '@angular/material';
import Swal from 'sweetalert2';
import { FrequenciaDetalhesService } from '../../services/frequenciaDetalhes.service';
import { FrequenciaDetalhes } from '../../model/frequenciaDetalhes.model';
import { CarregandoComponent } from '../../shared/Avisos/carregando/carregando.component';
import { BuscandoComponent } from '../../shared/Avisos/buscando/buscando.component';

@Component({
  selector: 'app-frequencia-list',
  templateUrl: './frequencia-list.component.html',
  styleUrls: ['./frequencia-list.component.css']
})
export class FrequenciaListComponent implements OnInit {

  fmyFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 5;
  }

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  page = 0;
  count = 10;
  pages: Array<number>;
  shared: SharedService;
  message: {};
  classCss: {};
  listAlunoTurma = [];
  frequencias: Frequencia[] = [];
  matriculas: Matricula[] = [];
  isLoading = false;
  listTurma = [];
  totalMatriculados;
  totalVisitantes;
  totalFaltosos;
  totalPresentes;
  valoresFrequencia;
  detalhes = new FrequenciaDetalhes('', new Date, 0, 0, 0, 0, 0, null, '');
  detalhesAntes = new FrequenciaDetalhes('', new Date, 0, 0, 0, 0, 0, null, '');
  id;
  primeiro: boolean;
  liberado: boolean;
  responseApiF: ResponseApi;
  tituloCard = null;
  totalGeral = 0;


  constructor(
    private dialogService: DialogService,
    private turmaService: TurmaService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private frequenciaService: FrequenciaService,
    private frequenciaDetalhesService: FrequenciaDetalhesService,
    private matriculaService: MatriculaService,
    public dialog: MatDialog
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    // this.alunos = this.matriculaService.findAllMatricula();

    this.form = this.formBuilder.group({
      data: [new Date(), Validators.required],
      oferta: [null],
      visitantes: [null],
      matriculas: [null],
      faltas: [null],
      professores: [null],
      turma: [null],
      frequencia: [null],
      id: [null]
    });

  }

  listarAlunos() {
    this.isLoading = true;
    this.route.params.subscribe(params => {

      if (params['id']) {
        this.abrirPesquisa();
        this.matriculaService.findByTurmaId(params['id']).subscribe(response => {
          this.dialog.closeAll();
          if (response && response.data) {
            this.tituloCard = response.data[0].turma.nome;
            this.matriculas = response.data.filter(matricula => matricula.aluno && matricula.status === false);
            this.matriculas.sort((a, b) => {
              return (a.aluno.nome > b.aluno.nome) ? 1
                : ((b.aluno.nome > a.aluno.nome) ? -1 : 0);
            });
            this.frequenciaService.findByTurmaId(params['id'], this.form.get('data').value).subscribe(response2 => {
              if (response2 && response2.data) {
                this.frequencias = response2.data;
                let visit = this.form.controls.visitantes.value;
                if (response2.data.length > 0) {
                  this.totalVisitantes = response2.data[0].visitantes;
                }
                if (visit === null) {
                  visit = 0;
                }
                this.matriculas.forEach(matricula => {
                  const i = this.frequencias.findIndex(frequencia => frequencia.matricula && frequencia.matricula.id === matricula.id);
                  if (i === -1) {
                    this.frequencias.push(
                      new Frequencia(null, this.form.get('data').value, matricula.turma, true, matricula, visit));
                  }
                });
                this.isLoading = false;
              }
            });
          }
        }, err => {
          this.dialog.closeAll();
        });

      } else {
        this.dialog.closeAll();
      }
    });
  }

  presencaChanges(frequencia: Frequencia, target: MatCheckboxChange) {
    this.frequencias.find(f => frequencia === f).presente = target.checked;
  }

  register() {
    if (this.form.valid) {
      this.totalVisitantes = null;
      this.totalPresentes = 0;
      this.abrirConfirmacao();
      if (this.form.controls.visitantes.value !== null) {
        for (let i = 0; i < this.frequencias.length; i++) {
          this.frequencias[i].visitantes = this.form.controls.visitantes.value;
        }
      }
      this.frequenciaService.createOrUpdateAll(this.frequencias).subscribe(
        (responseApi: ResponseApi) => {
          this.responseApiF = responseApi;
          this.id = this.responseApiF.data[0].id;
          this.totalMatriculados = responseApi.data.length;
          this.valoresFrequencia = responseApi.data;
          this.totalVisitantes = responseApi.data[0].visitantes;
          if (this.totalVisitantes === null) { this.totalVisitantes = 0 }
          this.totalFaltosos = 0;
          for (let i = 0; i < this.totalMatriculados; i++) {
            if (this.valoresFrequencia[i].presente === false) {
              this.totalFaltosos++;
            }
          }
          this.totalPresentes = this.totalMatriculados - this.totalFaltosos;
          var y: number = +this.totalVisitantes;
          this.totalGeral = this.totalPresentes + y;
          this.resetaForm();
          setTimeout(() => {
            this.dialog.closeAll();
          }, 2000);
          this.alertaSucesso();
          this.frequencias = [];
          this.matriculas = [];
          this.voltar();
          // this.registrarDetalhes();
        },
        err => {
          this.dialog.closeAll();
          this.alertaFalha();
        }
      );
    } else {
      this.dialog.closeAll();
      this.verificaValidacoesForm(this.form);
    }
  }


  registrarDetalhes() {
    this.form.controls['turma'].setValue(this.responseApiF.data[0].turma);
    this.form.controls['matriculas'].setValue(this.totalMatriculados);
    this.form.controls['faltas'].setValue(this.totalFaltosos);
    this.form.controls['professores'].setValue(2);
    this.form.controls['frequencia'].setValue(this.responseApiF.data[0].id);
    this.primeiro = true;
    this.liberado = false;
    this.detalhes = this.form.value;
    this.detalhesAntes = this.form.value;
    this.detalhes = this.detalhesAntes;
    let criar = 0;
    this.frequenciaDetalhesService.findByFrequencia(this.id).subscribe(
      (response: ResponseApi) => {
        this.form.controls['id'].setValue(response.data.id);
        this.detalhes = this.form.value;
        if (response) {
          this.frequenciaDetalhesService.createOrUpdate(this.detalhes).subscribe(
            (responseApi: ResponseApi) => {
              this.liberado = true;
              this.resetaForm();
            }, err => {
              criar = 1;
            }
          );
        } else {
          this.frequenciaDetalhesService.createOrUpdate(this.detalhes).subscribe(
            (responseApi: ResponseApi) => {
              this.liberado = true;
              this.resetaForm();
            });
        }
      }, err => {
        console.log(err.error.errors[0]);
        criar = 1;
        if (criar === 1) {
          this.frequenciaDetalhesService.createOrUpdate(this.detalhes).subscribe(
            (responseApi: ResponseApi) => {
              console.log('Detalhes salvo!');
              console.log(responseApi);
              this.liberado = true;
              this.resetaForm();
            });
        }
      });
    if (criar === 1) {
      this.frequenciaDetalhesService.createOrUpdate(this.detalhes).subscribe(
        (responseApi: ResponseApi) => {
          console.log('Detalhes salvo!');
          console.log(responseApi);
          this.liberado = true;
          this.resetaForm();
        });
    }

    this.detalhesAntes = this.form.value;
    this.frequencias = [];
    this.matriculas = [];
    this.voltar();
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(CarregandoComponent, {
      width: '350px'
    });
    dialogRef.disableClose = true;
  }

  abrirPesquisa() {
    const dialogRef = this.dialog.open(BuscandoComponent, {
      width: '350px'
    });
    dialogRef.disableClose = true;
  }

  alertaSucesso() {
    Swal.fire({
      title: 'Frequência realizada com sucesso!',
      html: 'Total Matriculados: ' + this.totalMatriculados +
        ' <br>Total faltosos: ' + this.totalFaltosos +
        ' <br>Total visitantes: ' + this.totalVisitantes +
        '<h3><br> <b>Total presentes:</b> ' + this.totalGeral + '</h3>',
      type: 'success',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaFalha() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  turmaSemMtricula() {
    Swal.fire({
      title: 'Nenhum Aluno(a) foi matriculado nessa turma!',
      type: 'info',
      text: 'Cadastre alunos para essa turma.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }

  voltar() {
    this.router.navigate(['/frequencia-new']);
  }

  findAll(page: number, count: number) {
    this.matriculaService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        this.listTurma = responseApi['data']['content'];
        this.pages = new Array(responseApi['data']['totalPages']);
      },
      err => {
        console.log('deu erro');
      }
    );
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confimacao',
  templateUrl: './Confirmacao.html'
})
// tslint:disable-next-line:component-class-suffix
export class Confirmacao { }
