import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FrequenciaService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(frequencia) {
        if (frequencia.id != null && frequencia.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/frequencia`, frequencia);
        }
        else {
            frequencia.id = null;
            return this.http.post(`${environment.apiUrl}/api/frequencia`, frequencia);
        }
    }
    findAllData(page, count) {
        return this.http.get(`${environment.apiUrl}/api/frequencia/${page}/${count}`);
    }
    findAll(page, count) {
        return this.http.get(`${environment.apiUrl}/api/frequencia/${page}/${count}`);
    }
    findAllFrequencia() {
        return this.http.get(`${environment.apiUrl}/api/frequencia/`);
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/frequencia/${id}`);
    }
    findByTurmaId(idTurma, data) {
        return this.http.get(`${environment.apiUrl}/api/frequencia/byTurmaId/${idTurma}/${moment(data).format('DDMMYYYY')}`);
    }
    resumoFrequencia(data, turmaId) {
        return this.http.get(`${environment.apiUrl}/api/frequencia/resumo-frequencia/${moment(data).format('DDMMYYYY')}/${turmaId}`);
    }
    findByTurmaIdBetween(idTurma, data, data2) {
        return this.http.get(`${environment.apiUrl}/api/frequencia/relatorioPorTurma/${idTurma}/${moment(data).format('DDMMYYYY')}/${moment(data2).format('DDMMYYYY')}`);
    }
    resumo(data) {
        return this.http.get(`${environment.apiUrl}/api/frequencia/resumo/${moment(data).format('DDMMYYYY')}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/frequencia/${id}`);
    }
    createOrUpdateAll(frequencias) {
        return this.http.post(`${environment.apiUrl}/api/frequencia/createOrUpdateAll`, frequencias);
    }
}
FrequenciaService.ngInjectableDef = i0.defineInjectable({ factory: function FrequenciaService_Factory() { return new FrequenciaService(i0.inject(i1.HttpClient)); }, token: FrequenciaService, providedIn: "root" });
