import { Response } from './../model/respose.model';
import { Frequencia } from './../model/frequencia.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { ResponseArray } from '../model/responseArray.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FrequenciaService {

  constructor(private http: HttpClient) { }

  createOrUpdate(frequencia: Frequencia) {
    if (frequencia.id != null && frequencia.id !== '') {
      return this.http.put(`${environment.apiUrl}/api/frequencia`, frequencia);
    } else {
      frequencia.id = null;
      return this.http.post(`${environment.apiUrl}/api/frequencia`, frequencia);
    }
  }

  findAllData(page: number, count: number) {
    return this.http.get<ResponseArray>(`${environment.apiUrl}/api/frequencia/${page}/${count}`);
  }

  findAll(page: number, count: number) {
    return this.http.get(`${environment.apiUrl}/api/frequencia/${page}/${count}`);
  }

  findAllFrequencia() {
    return this.http.get<Response>(`${environment.apiUrl}/api/frequencia/`);
  }

  findById(id: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/frequencia/${id}`);
  }
  findByTurmaId(idTurma: string, data: Date) {
    return this.http.get<Response>(`${environment.apiUrl}/api/frequencia/byTurmaId/${idTurma}/${moment(data).format('DDMMYYYY')}`);
  }
  resumoFrequencia(data: Date, turmaId: string) {
    return this.http.get<Response>(`${environment.apiUrl}/api/frequencia/resumo-frequencia/${moment(data).format('DDMMYYYY')}/${turmaId}`);
  }

  findByTurmaIdBetween(idTurma: string, data: Date, data2: Date) {
    return this.http.get<Response>(
      `${environment.apiUrl}/api/frequencia/relatorioPorTurma/${idTurma}/${moment(data).format('DDMMYYYY')}/${moment(data2).format('DDMMYYYY')}`
    );
  }

  resumo(data: Date) {
    return this.http.get<Response>(`${environment.apiUrl}/api/frequencia/resumo/${moment(data).format('DDMMYYYY')}`);
  }

  delete(id: string) {
    return this.http.delete(`${environment.apiUrl}/api/frequencia/${id}`);
  }

  createOrUpdateAll(frequencias: Frequencia[]) {
    return this.http.post(`${environment.apiUrl}/api/frequencia/createOrUpdateAll`, frequencias);
  }

}
