import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LancamentoService {
    constructor(http) {
        this.http = http;
        this._onSave = new EventEmitter();
        this.base_url = environment.apiUrl;
    }
    onSave() {
        return this._onSave;
    }
    findById(id) {
        return this.findPage('', id).pipe(map(values => values && values.length > 0 ? values[0] : null));
    }
    findPage(text, id, page = 0, pageSize = 20) {
        const params = {};
        if (text) {
            params['text'] = text;
        }
        if (id) {
            params['id'] = id;
        }
        if (page) {
            params['page'] = page;
        }
        if (pageSize) {
            params['page-size'] = pageSize;
        }
        return this.http.get(`${this.base_url}/api/lancamento/page`, { params });
    }
    findPageCount(text, id, pageSize = 20) {
        const params = {};
        if (text) {
            params['text'] = text;
        }
        if (id) {
            params['id'] = id;
        }
        if (pageSize) {
            params['page-size'] = pageSize;
        }
        return this.http.get(`${this.base_url}/api/lancamento/page/count`, { params });
    }
    save(lancamento) {
        const formData = new FormData();
        formData.append('descricao', lancamento.descricao);
        if (lancamento.dataPagamento) {
            formData.append('dataPagamento', lancamento.dataPagamento.toISOString());
        }
        if (lancamento.dataVencimento) {
            formData.append('dataVencimento', lancamento.dataVencimento.toISOString());
        }
        if (lancamento.aluno) {
            formData.append('aluno', JSON.stringify(lancamento.aluno));
        }
        if (lancamento.imagens && lancamento.imagens.length > 0) {
            const imagensUrl = [];
            for (const imagem of lancamento.imagens) {
                if (typeof imagem.data === 'string') {
                    imagensUrl.push(imagem.data);
                }
                if (imagem.data instanceof File) {
                    formData.append('imagens', imagem.data);
                }
            }
            if (imagensUrl.length > 0) {
                formData.append('imagensUrl', JSON.stringify(imagensUrl));
            }
        }
        formData.append('observacao', lancamento.observacao);
        // formData.append('', lancamento.tipoLancamento)
        if (lancamento.id) {
            return this.http.put(`${this.base_url}/api/lancamento/${lancamento.id}`, formData).pipe(tap(ojetoSalvo => this._onSave.emit(ojetoSalvo)));
        }
        else {
            return this.http.post(`${this.base_url}/api/lancamento`, formData).pipe(tap(ojetoSalvo => this._onSave.emit(ojetoSalvo)));
        }
    }
    delete(id) {
        return this.http.delete(`${this.base_url}/api/lancamento/${id}`);
    }
    removerImagem(empresaId, link) {
        return this.http.delete(`${this.base_url}/api/lancamento/galeria/${empresaId}`, { params: { link } });
    }
}
LancamentoService.ngInjectableDef = i0.defineInjectable({ factory: function LancamentoService_Factory() { return new LancamentoService(i0.inject(i1.HttpClient)); }, token: LancamentoService, providedIn: "root" });
