export type ArquivoType = string | File

export class Arquivo<T extends ArquivoType> {
  data: T

  constructor(data: T) {
    this.data = data
  }

  static createString(data: string) {
    return new Arquivo<string>(data)
  }

  static createFile(data: File) {
    return new Arquivo<File>(data)
  }
}
