<div *ngIf="isLoading" class="spinner" text-center>
  <mat-progress-bar mode="indeterminate" color=warn>Carregando ...</mat-progress-bar>
</div>
<div class="login-box" *ngIf="!isLoading">
  <div class="login-box-body">

    <form [formGroup]="form" class="form horizontal" #f="ngForm" (ngSubmit)="login()">
      <mat-card class="example-card alternative">
        <mat-card-header>
          <div>
            <img src="../../../../assets/image/ebd20.png" class="logo" />
          </div>
        </mat-card-header>

        <div class="title">
          <mat-card-title>
            <content>Login</content>
          </mat-card-title>
          <mat-card-subtitle>Ir para o sistema</mat-card-subtitle>
        </div>

        <mat-card-content>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label for="email">E-mail</mat-label>
                  <input matInput placeholder="E-mail" id="email" formControlName="email" email autofocus required/>
                  <mat-icon matSuffix>email</mat-icon>
                  <mat-error>E-mail inválido!</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <mat-form-field appearance="outline">
                  <mat-label for="password">Senha</mat-label>
                  <input matInput placeholder="Senha" id="password" formControlName="password"
                    [type]="hide ? 'password' : 'text'" required />
                  <mat-icon matSuffix (click)="hide = !hide">{{
                        hide ? "visibility_off" : "visibility"
                      }}</mat-icon>
                  <mat-error>Senha inválida!</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button mat-raised-button color="warn">Próximo</button>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
</div>
