import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatGridListModule, MatIconModule, MatInputModule, MatNativeDateModule, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatSelectModule, MatSortModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImagesLancamentoComponent } from './lancamento/images-lancamento/images-lancamento.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { LicaoImagensComponent } from './licao/licao-imagens/licao-imagens.component';
import { InputImageComponent } from './input-image/input-image.component';
import { DdMmYyyyDatePipe } from '../shared/dd-mm-yyyy-date.pipe';




const COMPONENTS = [
  PaginatorComponent,
  InputImageComponent,
  ImagesLancamentoComponent,
  LicaoImagensComponent,
  DdMmYyyyDatePipe,
]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatTableModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatGridListModule,
  ],
  exports: [...COMPONENTS],

})
export class ComponentsModule { }
