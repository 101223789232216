<h1 mat-dialog-title>Selecione:</h1>
<div mat-dialog-content>
  <mat-card matRipple [matRippleUnbounded]="false" (click)="resumoFrequencia()" (click)="fecharDialog()">
    <mat-card-title>Resumo de frequências</mat-card-title>
  </mat-card>

  <mat-card matRipple [matRippleCentered]="false" [matRippleUnbounded]="false" (click)="porTurma()"
    (click)="fecharDialog()">
    <mat-card-title>Relatório por turma</mat-card-title>
  </mat-card>

  <mat-card matRipple [matRippleCentered]="false" [matRippleUnbounded]="false" (click)="porAluno()"
    (click)="fecharDialog()">
    <mat-card-title>Relatório de Frequência por aluno</mat-card-title>
  </mat-card>
  <mat-card matRipple [matRippleCentered]="false" [matRippleUnbounded]="false" (click)="aniversariantes()"
    (click)="fecharDialog()">
    <mat-card-title>Aniversariantes</mat-card-title>
  </mat-card>
</div>
