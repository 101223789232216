<div class="col-md-6" style="margin-left: 0%">
  <div class="box box-info">
    <div class="box-header with-border">
      <h3 class="box-title">Novo Ticket</h3>
    </div>
    <form class="form horizontal" #form="ngForm" (ngSubmit)="register()" novalidate>
      <div [ngClass]="classCss" role="alert" *ngIf="message">
        <strong>{{ message.text }}</strong>
      </div>
      <div class="box-body">
        <div [ngClass]="getFromGroupClass(title.invalid, title.dirty)">
          <label for="inputTitle" class="col-sm-2 control-label">Título</label>

          <div class="col-sm-10">
            <input
              type="text"
              [(ngModel)]="ticket.title"
              class="form-control"
              name="title"
              id="inputTitle"
              #title="ngModel"
              placeholder="Title"
              required>
            <span class="help-block " *ngIf="title.invalid && title.dirty">Título é obrigatório</span>
          </div>
        </div>

        <div [ngClass]="getFromGroupClass(priority.invalid, priority.dirty)">
          <label for="inputPriority" class="col-sm-2 control-label">Prioridade</label>

          <div class="col-sm-10">
            <select
              [(ngModel)]="ticket.priority"
              class="form-control"
              id="inputPriority"
              name="priority"
              #priority="ngModel"
              style="width: 100%"
              required>
              <option>High</option>
              <option>Normal</option>
              <option>Low</option>
            </select>
            <span class="help-block" *ngIf="priority.invalid && priority.dirty">Prioridade é obrigatória</span>
          </div>
        </div>

        <!--div [ngClass]="getFromGroupClass(description.invalid, description.dirty)">
          <label for="inputDescription" class="col-sm-2 control-label">Descrição</label>

          <div class="col-sm-10">
            <textarea
              [(ngModel)]="ticket.description"
              class="form-control"
              rows="3"
              id="inputDescription"
              name="description"
              #description="ngModel"
              placeholder="description..."
              required>
            </textarea>
              <span class="help-block" *ngIf="description.invalid && description.dirty">Descrição é obrigatória</span>
          </div>
        </div-->

        <div class="form-group">
            <label for="inputImage" class="col-sm-2 control-label">Imagem</label>
            <div class="col-sm-10">
              <img [src]="ticket.image" width="50%" height="50%">
              <input type="file" id="image" (change)="onFileChange($event)">
            </div>
        </div>

      </div>
      <div class="box-footer">
        <button type="submit" [disabled]="!form.valid" class="btn btn-info pull-right">Confirmar</button>
      </div>
    </form>
  </div>
</div>

