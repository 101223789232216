import { ContasPagar } from './../../model/pagar.model';
import { Response } from './../../model/respose.model';
import { AlunoService } from './../../services/aluno.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TurmaService } from './../../services/turma.service';
import { MatriculaService } from './../../services/matricula.service';
import { Turma } from './../../model/turma.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { Observable } from 'rxjs';
import { Aluno } from '../../model/aluno.model';
import { ResponseApi } from '../../model/response-api';
import { MatDialog } from '@angular/material';
import { Mask } from '@fagnerlima/ng-mask';
import { StatusPagEnum } from '../../Enum/StatusPagEnum';
import { Matricula } from '../../model/matricula.model';
import { map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { trigger } from '@angular/animations';
import { CarregandoComponent } from '../../shared/Avisos/carregando/carregando.component';

@Component({
  selector: 'app-matricula-new',
  templateUrl: './matricula-new.component.html',
  styleUrls: ['./matricula-new.component.css']
})
export class MatriculaNewComponent implements OnInit {
  isLinear = false;

  pagamentos = [
    { key: 'AVISTA', value: 'A vista' },
    { key: 'APRAZO', value: 'A prazo' }
  ];

  @ViewChild('f')
  f: NgForm;
  form: FormGroup;
  shared: SharedService;
  message: {};
  classCss: {};
  alunos: Observable<Aluno[]>;
  turmas: Observable<Turma[]>;
  // pagamentos = ['A vista', 'A prazo'];
  readonly dateMask: Mask = new Mask('00/00/0000');
  readonly maskMoney: string = '#0.00?reverse=true';
  FormControl: any;
  parcelaList: ContasPagar[];
  nomeAluno;
  isLoading = false;

  constructor(
    private matriculaService: MatriculaService,
    private alunoService: AlunoService,
    private turmaService: TurmaService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.shared = SharedService.getInstance();
  }

  ngOnInit() {
    this.alunos = this.alunoService.findAllAlunos().pipe(map(alunos => {
      // console.log(alunos);
      return alunos.data.filter(aluno => aluno.status === false);
    }));
    this.turmas = this.turmaService.findAllTurma()
      .pipe(map(turmas => {
        return turmas.data.filter(turma => turma.status === false);
      }));

    this.form = this.formBuilder.group({
      id: [null],
      aluno: [null, Validators.required],
      turma: [null, Validators.required],
      data: [new Date(), Validators.required]
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.matriculaService.findById(params['id']).subscribe(matricula => {
          if (matricula && matricula.data) {
            // console.log(this.form.setValue(matricula.data));
            this.form.setValue(matricula.data);
            const data = new Date(matricula.data.data);
            this.form.controls['data'].setValue(data);
          }
        });
      }
    });
    this.form.get('aluno').valueChanges
      .subscribe(nomeAluno => {
        if (nomeAluno && nomeAluno !== '') {
          this.alunos = this.alunoService.findByNomeContains(nomeAluno)
            .pipe(
              map(response => {
                //  console.log(response.data);
                return response.data ? response.data.filter(aluno => aluno.status === false) : [];
              }));
        }
      });
  }
  displayFn(aluno?: Aluno): string | undefined {
    return aluno ? aluno.nome : undefined;
  }
  /* buildPagamentos() {
    const values = this.pagamentos.map(v => new FormControl(false));
    return this.formBuilder.array(values);
  } */

  register() {
    if (this.form.valid) {
      console.log(this.form.value);
      this.isLoading = true;
      this.abrirConfirmacao();
      const matricula: Matricula = this.form.value;
      this.nomeAluno = matricula.aluno.nome;
      // matricula.parcelas = this.parcelaList;
      // console.log(matricula.parcelas);
      this.matriculaService.createOrUpdate(this.form.value).subscribe(
        (responseApi: ResponseApi) => {
          this.nomeAluno = responseApi.data.aluno.nome;
          this.resetaForm();
          this.dialog.closeAll();
          this.alertaSucesso();
          this.isLoading = false;
        },
        err => {

          if (this.matriculaService.findByMatricula(this.form.get('aluno').value)) {
            this.alertaMatricula();
            this.dialog.closeAll();
            this.isLoading = false;
          }
          this.dialog.closeAll();
          // this.alertaFalha();
          this.isLoading = false;
        }
      );
    } else {
      console.log('Formulário inválido');
      this.verificaValidacoesForm(this.form);
      this.dialog.closeAll();
      this.alertaFalha();
    }
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(CarregandoComponent, {
      width: '350px'
    });
  }

  alertaSucesso() {
    Swal.fire({
      title: this.nomeAluno + ', foi matriculado com sucesso!',
      type: 'success',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaMatricula() {
    Swal.fire({
      title: this.nomeAluno + ', já está matriculado em outra turma!',
      type: 'info',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  alertaFalha() {
    Swal.fire({
      title: 'Ops!',
      type: 'warning',
      text: 'O servidor encontrou um erro. Entre em contato com o suporte.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  verificaValidacoesForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(campo => {
      console.log(campo);
      const controle = formGroup.get(campo);
      if (controle instanceof FormGroup) {
        this.verificaValidacoesForm(controle);
      }
    });
  }

  resetaForm() {
    this.f.resetForm();
  }
  listarMatriculas() {
    this.router.navigate(['/matricula-list']);
  }



  valorParcela() {
    const valor = this.form.get('valor').value;
    const quantidade = this.form.get('parcela').value;
    const data: Date = this.form.get('data').value;
    const aluno: Aluno = this.form.get('aluno').value;
    const dataVencimento = new Date();
    let valorRestante = valor;
    const valorParcela = Math.round(valor / quantidade * 100) / 100;
    this.parcelaList = [];
    for (let i = 0; i < quantidade; i++) {
      const dataVenc = new Date();
      dataVenc.setDate(data.getDate() + (30 * (i + 1)));
      const parcela = new ContasPagar(null, null, dataVenc,
        aluno, i === (quantidade - 1) ? valorRestante : valorParcela,
        StatusPagEnum.EM_ABERTO, null);
      this.parcelaList.push(parcela);
      valorRestante -= valorParcela;
    }
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'confimacao',
  templateUrl: './Confirmacao.html'
})
// tslint:disable-next-line:component-class-suffix
export class Confirmacao { }
