<div class="col-md-10" style="margin-left: 0%">
  <div class="box box-info">
    <div class="box box-header with-border">
      <h3>Detalhes do Ticket</h3>
    </div>
    <form class="form-horizontal">
      <div [ngClass]="classCss" role="alert" *ngIf="message">
          <strong>{{message.text}}</strong>
      </div>
      <div class="box-body">
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
                <b>Número: </b> <span>{{ticket.number}}</span>
                <span class="pull-right"><b>Título: </b>{{ticket.title}}</span>
            </li>
            <li class="list-group-item">
                <b>Prioridade: </b> <span>{{ticket.priority}}</span>
                <span class="pull-right"><b>Creado por: </b>{{ticket?.user?.email}}</span>
            </li>
            <li class="list-group-item">
                <b>Status: </b> <span>{{ticket.status}}</span>
                <span class="pull-right"><b>Atribuido: </b>{{ticket?.assignedUser?.email}}</span>
            </li>
            <li class="list-group-item">
                <b>Data: </b> <span>{{ticket.date | date:'dd/MM/yyyy'}}</span>
                <!--span class="pull-right"><b>Descrição: </b>{{ticket.description}}</span-->
            </li>
            <li class="list-group-item">
              <span><img [src]="ticket.image" width="650px" height="370px"></span>
              <span class="pull-right">
                  <table class="table table-bordered">
                      <tr>
                        <th>Alterado pelo Usuário</th>
                        <th>Data da Alteração do Status</th>
                        <th>Status</th>
                      </tr>
                      <tr *ngFor="let change of ticket.changes">
                          <td>{{change?.userChange.email}}</td>
                          <td>{{change?.dateChangeStatus | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                          <td>{{change?.status}}</td>
                      </tr>
                  </table>
              </span>
            </li>
          </ul>
      </div>
      <div class="box-footer">
            <button *ngIf="shared.user.profile == 'TECHNICIAN' && ticket.status == 'New'"
                (click)="changeStatus('Assigned')"
                class="btn btn-primary">Aceito</button>

            <button *ngIf="shared.user.profile == 'TECHNICIAN' && (ticket.status == 'Assigned' || ticket.status == 'Disapproved' )"
                (click)="changeStatus('Resolved')"
                class="btn btn-primary">Resolver</button>

            <button *ngIf="shared.user.profile == 'CUSTOMER' &&  ticket.status == 'Resolved'"
                (click)="changeStatus('Approved')"
                class="btn btn-primary">Aprovado</button>

            <button *ngIf="shared.user.profile == 'CUSTOMER' &&  ticket.status == 'Resolved'"
                (click)="changeStatus('Disapproved')"
                class="btn btn-primary">Reprovado</button>

            <button *ngIf="shared.user.profile == 'TECHNICIAN' &&  ticket.status == 'Approved'"
                (click)="changeStatus('Closed')"
                class="btn btn-primary">Fechar</button>
      </div>
    </form>
  </div>
</div>
