import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource, MatDialog } from '@angular/material';
import { MatSort } from '@angular/material/sort';
import { Frequencia } from '../../model/frequencia.model';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ResumoFrequencia } from '../resumo-frequencia/resumo-frequencia.model';
import { FrequenciaService } from '../../services/frequencia.service';
import { TurmaService } from '../../services/turma.service';
import { map } from 'rxjs/operators';
import { ResponseApi } from '../../model/response-api';
import { Observable } from 'rxjs';
import { Turma } from '../../model/turma.model';
import { BuscandoComponent } from '../../shared/Avisos/buscando/buscando.component';
import Swal from 'sweetalert2';
import { MatriculaService } from '../../services/matricula.service';
import { Matricula } from '../../model/matricula.model';
import * as jsPDF from 'jspdf';
// import { patch } from 'webdriver-js-extender';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-relatorio-turma',
  templateUrl: './relatorio-turma.component.html',
  styleUrls: ['./relatorio-turma.component.css']
})
export class RelatorioTurmaComponent implements OnInit {

  @ViewChild('print') print: ElementRef;
  isLoading = false;
  isNothing = false;
  @ViewChild('f')
  @ViewChild(MatSort) sort: MatSort;
  f: NgForm;
  form: FormGroup;
  page = 0;
  count = 10;
  pages: Array<number>;
  turmas: Observable<Turma[]>;
  idTurma: string;
  listTurma = [];
  listdoDia = [];
  totalMatriculados = 0;
  totalFaltosos = 0;
  tamanhoArray = 0;
  totalPresentes = 0;
  totalPresentes2 = 0;
  totalFaltosos2 = 0;
  totalVisitantes = 0;
  dis = 0;
  valoresArrayFrequencia;
  dados;
  matriculas: Matricula[] = [];
  frequencias: Frequencia[] = [];
  chamadas: any[] = [];
  chamadas2: any[] = [];
  resumoList: any[] = [];
  nomeTurma;
  nomeAluno;
  dataSource: MatTableDataSource<any>;
  dataSource2: MatTableDataSource<ResumoFrequencia>;
  cor1 = 'green';
  cor2 = 'orange';


  displayedColumns: any[] = [];
  tab2: string[] = ['aluno', 'totalp', 'totala', 'percentual'];


  constructor(private formBuilder: FormBuilder,
    private frequenciaService: FrequenciaService,
    private turmaService: TurmaService,
    private matriculaService: MatriculaService,
    public dialog: MatDialog,
    private datePipe: DatePipe) {
    this.dataSource = new MatTableDataSource<any>();
    this.dataSource2 = new MatTableDataSource<ResumoFrequencia>();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      data: [new Date(), Validators.required],
      data2: [new Date(), Validators.required],
      turma: [null, Validators.required]
    });

    this.turmas = this.turmaService.findAllTurma()
      .pipe(map(turmas => {
        return turmas.data.filter(turma => turma.status === false);
        console.log(turmas.data);
      }));

    this.findAllTurma(this.page, this.count);

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  applyFilter2(filterValue: string) {
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'dd/MM/yyyy');
  }


  resumo() {
    if (this.form.get('data').value <= this.form.get('data2').value) {
      this.totalFaltosos = 0;
      this.totalPresentes = 0;
      this.isNothing = false;
      this.valoresArrayFrequencia = this.listTurma.length;
      this.abrirConfirmacao();
      this.frequenciaService.findByTurmaIdBetween(
        this.idTurma, this.form.get('data').value, this.form.get('data2').value)
        .subscribe(response => {
          console.log(response);
          if (response && response.data) {
            this.frequencias = response.data;
            if (this.frequencias.length > 0) {
              // preciso a cada data somar mostrar os alunos que estavam presentes, depois
              // mostrar o total matriculado, faltosos e total presentes.
              for (let i = 0; i < this.frequencias.length; i++) {
                if (this.dis == 0) {
                  this.displayedColumns.push(this.frequencias[i].data);
                  this.dis++;
                } else {
                  if (this.frequencias[i].data != this.displayedColumns[this.dis - 1]) {
                    this.displayedColumns.push(this.frequencias[i].data);
                    this.dis++;
                  }
                }
                if (this.frequencias[i].presente === true) {
                  this.totalPresentes++;
                }
                if (this.frequencias[i].presente === false) {
                  this.totalFaltosos++;
                }
              }

              // cont aux = this.frequencias[0].matricula
              this.chamadas = this.frequencias;
              console.log(this.chamadas);



              this.isNothing = false;
              console.log(this.chamadas);
              this.dialog.closeAll();
            } else {
              this.isNothing = true;
              this.dialog.closeAll();
            }
          }
          this.dataSource.data = this.chamadas;
          this.dataSource.sort = this.sort;
          // this.dialog.closeAll();
          console.log(this.dataSource.data);

          // TABELA 2
          if (this.frequencias.length > 0) {
            this.matriculaService.findByTurmaId(this.idTurma).subscribe(resp => {
              if (resp && resp.data) {
                console.log(resp.data);
                this.matriculas = resp.data.filter(matricula => matricula.aluno && matricula.status === false);
                console.log(this.matriculas);
                if (this.matriculas.length > 0) {
                  for (let x = 0; x < this.matriculas.length; x++) {
                    for (let y = 0; y < this.frequencias.length; y++) {
                      if(this.frequencias[y].matricula != null) {
                        this.nomeAluno = this.matriculas[x].aluno.nome;
                        if (this.matriculas[x].aluno.id === this.frequencias[y].matricula.aluno.id) {
                          if (this.frequencias[y].presente === true) {
                            this.totalPresentes2++;
                          }
                          if (this.frequencias[y].presente === false) {
                            this.totalFaltosos2++;
                          }
                        }
                      }
                    }
                    const length = this.totalFaltosos2 + this.totalPresentes2;
                    const resumo = new ResumoFrequencia(
                      this.nomeAluno, this.totalPresentes2,
                      this.totalFaltosos2, this.totalVisitantes,
                      this.totalPresentes2 / length);
                    this.resumoList.push(resumo);
                    console.log(resumo);
                    this.totalPresentes2 = 0;
                    this.totalFaltosos2 = 0;
                  }
                }
              }
              this.dataSource2.data = this.resumoList;
              this.dataSource2.sort = this.sort;
              console.log(this.dataSource2.data);

            });
          }
        });

      this.chamadas = [];
      this.resumoList = [];
      // this.dialog.closeAll();
    } else {
      this.abrirConfirmacao();
      this.infoData();
      this.dialog.closeAll();
    }

  }

  turmaSelecionada(value: any) {
    console.log(value);
    this.idTurma = value;
  }

  gerarPdf() {
    const logoEDB = new Image();
    logoEDB.src = '../../../assets/image/LogoEDB.png';
    const pdf = new jsPDF();

    /* const el = {
      '#editor': function(element, renderer) {
        return true;
      }
    };

    const print = this.print.nativeElement;
    pdf.fromHTML(print.innerHTML, 15, 15, {
      'width': 190,
      'elementHandlers': el
    }); */
    // logo
    pdf.addImage(logoEDB, 'JPEG', 10, 2, 50, 20);
    pdf.line(10, 25, 200, 25);
    pdf.setFontType('bold');
    pdf.text(70, 32, 'RESUMO DE FREQUÊNCIA');
    pdf.line(10, 35, 200, 35);

    // pegar dados do form
    const data = this.form.get('data').value.toISOString;

    pdf.text(70, 32, data);
    // pdf.save('impressao.pdf');
    pdf.setFontSize(20);
    const el = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    const print = this.print.nativeElement;
    pdf.fromHTML('#tabela', 10, 80, {
      'width': 700,
      'elementHandlers': el
    });
    pdf.output('dataurlnewwindow');
  }

  infoData() {
    Swal.fire({
      title: 'A data inical precisa ser inferior a data final.',
      type: 'info',
      text: 'A data informáda é inválida.',
      footer: 'Igreja Batista Nacional Ebenézer'
    });
  }

  findAllTurma(page: number, count: number) {
    this.isLoading = true;
    this.turmaService.findAll(page, count).subscribe(
      (responseApi: ResponseApi) => {
        // console.log(responseApi);
        this.listTurma = responseApi['data']['content'];
        // console.log(this.listTurma);
        this.pages = new Array(responseApi['data']['totalPages']);
        this.isLoading = false;
      },
      err => { }
    );
  }

  abrirConfirmacao() {
    const dialogRef = this.dialog.open(BuscandoComponent, {
      width: '350px'
    });
    dialogRef.disableClose = true;
  }

  findAll(page: number, count: number) {
    this.isLoading = true;
    this.frequenciaService.findAllData(page, count)
      .pipe(
        map(response => {
          console.log(response);
          // return response.data.content.filter(turma => turma.data === this.form.get('data').value);
          // this.frequenciaService.findByTurmaId()
          this.valoresArrayFrequencia = response.data.size;
          this.dados = response.data.content;
          // const data: Date = this.form.get('data').value;
          for (let i = 0; i < this.valoresArrayFrequencia; i++) {
            this.frequenciaService.findByTurmaId(this.dados[0].turma.id, this.form.get('data').value).subscribe(response2 => {
              this.frequencias = response2.data;
              console.log(this.frequencias);
            });
            /* if (this.dados[i].data === data.toISOString) {
               this.listdoDia = this.dados[i];
               console.log(this.listdoDia);
             } */
          }
          console.log(this.listdoDia);
          this.isLoading = false;

        })
      )
      .subscribe(() => {

        //   this.listAluno = responseApi['data']['content'];
        // this.pages = new Array(responseApi['data']['totalPages']);
        this.isLoading = false;
      }, err => {

      });
  }

}
