import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LicaoService {
    constructor(http) {
        this.http = http;
    }
    createOrUpdate(licao) {
        if (licao.id != null && licao.id !== '') {
            return this.http.put(`${environment.apiUrl}/api/licao`, licao);
        }
        else {
            licao.id = null;
            return this.http.post(`${environment.apiUrl}/api/licao`, licao);
        }
    }
    findAll(page, count, experiencia, professor) {
        const params = {};
        params['experiencia'] = experiencia;
        if (professor) {
            params['professor'] = professor;
        }
        return this.http.get(`${environment.apiUrl}/api/licao/${page}/${count}`, { params });
    }
    findAlllicao() {
        return this.http.get(`${environment.apiUrl}/api/licao/`);
    }
    findAlllicaoData(page, count) {
        return this.http.get(`${environment.apiUrl}/api/licao/${page}/${count}`).pipe(map(response => {
            console.log(response);
            return response.data.content.filter(licao => licao.status === false);
        }));
    }
    findById(id) {
        return this.http.get(`${environment.apiUrl}/api/licao/${id}`);
    }
    findByTurmaId(idTurma, data) {
        return this.http.get(`${environment.apiUrl}/api/licao/byTurmaId/${idTurma}/${moment(data).format('DDMMYYYY')}`);
    }
    delete(id) {
        return this.http.delete(`${environment.apiUrl}/api/licao/${id}`);
    }
    pesquisar(tema) {
        return this.http.get(`${environment.apiUrl}/api/licao/byName/${tema}`);
    }
    ativarLicao(id) {
        return this.http.post(`${environment.apiUrl}/api/licao/ativar`, id);
    }
    desativarLicao(desativar) {
        return this.http.post(`${environment.apiUrl}/api/licao/desativar`, desativar);
    }
}
LicaoService.ngInjectableDef = i0.defineInjectable({ factory: function LicaoService_Factory() { return new LicaoService(i0.inject(i1.HttpClient)); }, token: LicaoService, providedIn: "root" });
