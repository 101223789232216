<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left: 0%">
      <div class="box box-danger">
        <div class="box-header with-border">
          <mat-card [ngClass]="{'cardAluno':true}">
            <mat-card-title style="color: white">Frequência</mat-card-title>
            <mat-card-subtitle>Selecione a turma e clique em Realizar chamada.</mat-card-subtitle>
          </mat-card>
        </div>
        <div *ngIf="isLoading" class="spinner" text-center>
          <mat-progress-bar mode="indeterminate" color=warn>Carregando...</mat-progress-bar>
        </div>
        <div *ngIf="!isLoading">

          <div class="box-body" class="table-responsive">
            <table class="table table-bordered">
              <tr>
                <th>Turma</th>
                <th style="width: 20px">Ação</th>
              </tr>
              <tr *ngFor="let turma of listTurma">
                <td>{{ turma.nome}}</td>
                <td>
                  <mat-button-toggle value="chamada" (click)="edit(turma.id)" matTooltip="Realizar chamada">
                    <mat-icon style="color: #0D47A1">query_builder</mat-icon>
                  </mat-button-toggle>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <!-- PAGINACAO DESATIVADA-->
        <!--div class="box-footer clearfix">
          <ul class="pagination pagination-sm no-margin pull-right">
            <li>
              <a href="" (click)="setPreviousPage($event)">&laquo;</a>
            </li>
            <li *ngFor="let p of pages; let i=index">
              <a [ngClass]="{'pagination-focus': i == page}" href="" (click)="setPage(i, $event)">{{i+1}}</a>
            </li>
            <li>
              <a href="" (click)="setNextPage($event)">&raquo;</a>
            </li>
          </ul>
        </div-->

      </div>
    </div>
  </div>
</div>
